import PageWrapper from "../../wrappers/PageWrapper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RoutesEnum } from "../../core/enums";
import CompanyInfoCompiled from "../../components/Company/CompanyInfoCompiled";

export default function CompanyInformationPage() {
  const { companyDomain } = useParams<{ companyDomain: string }>();

  const navigate = useNavigate();
  const location = useLocation();
  const backToGroups: boolean = location.state?.from === "Company";

  const onClickBack = () => {
    backToGroups ? navigate(-1) : navigate(RoutesEnum.Scoops);
  };

  return (
    <PageWrapper title={backToGroups ? "Company" : "Back to Scoops"} onClickBack={onClickBack}>
      <CompanyInfoCompiled companyDomain={companyDomain!}></CompanyInfoCompiled>
    </PageWrapper>
  );
}
