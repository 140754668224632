import { ChangeEventHandler, DragEventHandler, useRef } from 'react';
import { ReactComponent as Upload } from '../icons/upload.svg';

interface Props {
  onSelect: (file: File) => void;
  error: string | undefined;
  name?: string;
}

export default function FileUploader({ onSelect, error, name }: Props) {
  const ref = useRef<HTMLInputElement>(null);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    if (files && files[0] && ref.current) {
      onSelect(files[0]);
      ref.current.value = '';
    }
  };

  const preventDefaultDragEvents: DragEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop: DragEventHandler<HTMLLabelElement> = (e) => {
    preventDefaultDragEvents(e);
    const files = e.dataTransfer.files;
    if (files && files[0] && ref.current) {
      onSelect(files[0]);
      ref.current.value = '';
    }
  };

  return (
    <div>
      <label
        className='border-[0.7px] border-solid border-gray pt-5 pb-9 flex flex-col items-center justify-center cursor-pointer rounded-[5px]'
        onDrop={onDrop}
        onDragEnter={preventDefaultDragEvents}
        onDragLeave={preventDefaultDragEvents}
        onDragOver={preventDefaultDragEvents}
      >
        <Upload />
        <div className='text-center text-base font-semibold mt-[6px]'>
          Select a .csv file to upload
        </div>
        <div className='text-center text-[10px] leading-6'>
          or drag and drop{' '}
        </div>
        <input
          type='file'
          onChange={onChange}
          className='hidden'
          multiple={false}
          accept='.csv'
          ref={ref}
          data-testid={name || undefined}
        />
      </label>
      {error && (
        <div className='text-[11px] leading-[13px] text-red-500 pt-1'>
          {error}
        </div>
      )}
    </div>
  );
}
