import "./ContactDetails.scss";
import ProfileCard from "./ProfileCard";
import { Article, Contact, User } from "../../core/types";
import EmailBuilderComponent from "../EmailBuilder/EmailBuilderComponent";
import { CommentComponent } from "../Comment/CommentComponent";
import OwnedByComponent from "./OwnedByComponent";
import { setContactOwner } from "../../core/api";

interface Props {
  contactData?: Contact;
  articles?: Article[];
  user?: User;
  orgMembers: User[]; //for ownership component
  onSaveContact?: () => void;
}

export default function ContactDetails({ contactData, articles, onSaveContact, user, orgMembers }: Props) {
  return (
    <EmailBuilderComponent
      contactDetails=<ProfileCard data={contactData} onClickSave={onSaveContact} />
      ownedBy={
        contactData ? (
          <OwnedByComponent
            setNewOwnerApiCall={setContactOwner}
            ownerEmail={contactData?.owner?.email}
            targetUuid={contactData?.uuid}
            orgMembers={orgMembers}></OwnedByComponent>
        ) : (
          <></>
        )
      }
      commentSection={
        user &&
        contactData && (
          <CommentComponent maxDisplay={2} type="contact" targetUuid={contactData?.uuid} user={user} comments={contactData.comments || []} />
        )
      }
      draft={contactData?.email_draft}
      contactUuid={contactData?.uuid}
      user={user}
      relatedArticles={articles || []}></EmailBuilderComponent>
  );
}
