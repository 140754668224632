import React, { ClipboardEvent, useState } from "react";
import { DropdownItem } from "./Dropdown";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import { useDropdown } from "./useDropdown";
import Checkbox from "../Checkbox";

interface DropdownProps {
  items: DropdownItem[];
  name: string;
  placeholder: string;
  trigger?: JSX.Element;
  className?: string;
  setExternalState: (i: string[]) => React.Dispatch<React.SetStateAction<string[]>>;
  selectedInputs?: string[];
  selectedDropdownItems?: DropdownItem[];
  isMulti?: boolean;
}

const DropdownInput: React.FC<DropdownProps> = ({
  items,
  name,
  placeholder,
  trigger,
  selectedInputs = [],
  selectedDropdownItems = [],
  className,
  setExternalState
}) => {
  const [selectedItem, setSelectedItem] = useState<string[]>([...selectedInputs.map((s) => s), ...selectedDropdownItems.map((i) => i.label)] || []);
  const [inputValue, setInputValue] = useState<string>("");
  const { dropdownRef, menuRef, onClickTrigger, isOpened } = useDropdown();

  const handleCheckboxOnChange = (item: DropdownItem, e: React.ChangeEvent<HTMLInputElement> | null) => {
    item.checkboxOnChange!(e);
    if (selectedItem.includes(item.label)) {
      setSelectedItem(selectedItem.filter((i) => i !== item.label));
    } else {
      setSelectedItem([...selectedItem, item.label]);
    }
  };

  const handleChange = (evt: any) => {
    setInputValue(evt.target.value);
  };

  const handleKeyDown: (evt: any) => void = (evt: KeyboardEvent) => {
    if ("Backspace" === evt.key && !inputValue && selectedItem.length) {
      evt.preventDefault();
      const toRemove = selectedItem.pop();
      if (toRemove) {
        handleUnselect(toRemove);
      }
    }
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      if (inputValue && !selectedItem.includes(inputValue)) {
        const parsedValue = inputValue.trim().replace(",", "");
        setSelectedItem([...selectedItem, parsedValue]);
        setExternalState([...selectedItem, parsedValue]);
      }
      setInputValue("");
    }
  };

  const handleUnselect = (label: string) => {
    const isRemoveItem = items.filter((i) => i.label === label);
    if (isRemoveItem.length === 1) {
      handleCheckboxOnChange(isRemoveItem[0], null);
    }
    const reducedSelection = selectedItem.filter((i) => i !== label);
    setSelectedItem(reducedSelection);
    setExternalState(reducedSelection);
  };

  const handlePaste = (evt: ClipboardEvent) => {
    evt.preventDefault();
    const paste = evt.clipboardData.getData("text");
    if (paste.length) {
      const toBeAdded = paste
        .split(",")
        .map((pasted) => pasted.trim())
        .filter((tba) => !selectedItem.includes(tba));
      const newSelection = [...selectedItem, ...toBeAdded];
      setSelectedItem(newSelection);
      setExternalState(newSelection);
    }
  };

  return (
    <div ref={dropdownRef} key={`${name}-dropdown`} className={` relative flex h-fit grow  border-[0.2px] border-gray-100 rounded-lg ${className}`}>
      <div className="flex-col w-full">
        <div className="flex items-center ml-2">
          <div className={`dropdown-toggle  my-[2px] `}>
            <div className="text-gray flex flex-wrap">
              {selectedItem.map((item) => {
                return (
                  <div
                    key={"selected-" + item}
                    className="mr-1 w-fit flex rounded-md border-[0.1px] hover:cursor-pointer border-gray-100 px-1 pb-[1px]  text-center my-[1px] align-middle bg-violet"
                    onClick={() => handleUnselect(item)}>
                    <p className="w-fit mx-1">{item}</p>
                    <button type="button" className="button hover:text-purple mr-[1px] " key={`${item}-delete`}>
                      &times;
                    </button>
                  </div>
                );
              })}
              <input
                className="shrink grow  bg-transparent pl-1 outline-none placeholder-gray "
                onSelect={(e) => {
                  e.preventDefault;
                }}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                onPaste={handlePaste}
                value={inputValue}
                placeholder={selectedItem.length ? "" : placeholder}></input>
            </div>
          </div>
        </div>
        {isOpened && (
          <div ref={menuRef} className="dropdown-menu w-full">
            {items.map((item, index) => (
              <div
                key={item.label + index}
                className={`dropdown-item flex items-center pl-2 ${item.className}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                <Checkbox checked={selectedItem.includes(item.label)} onChange={(e) => handleCheckboxOnChange(item, e)} />
                <p className="ml-2">{item.label}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div id="trigger_div" className="absolute right-2  flex items-center justify-center h-full w-2" onClick={onClickTrigger}>
        <div className=" flex items-center justify-center">{trigger ? trigger : <Polygon></Polygon>}</div>
      </div>
    </div>
  );
};

export default DropdownInput;
