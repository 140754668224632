import { useAuth0 } from "@auth0/auth0-react";
import { Scheduling } from "../../core/types";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Schedule } from "../../icons/scheduling_clock.svg";
import { ReactComponent as UserIcon } from "../../icons/user_inverted.svg";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import { ReactComponent as CloseIcon } from "../../icons/close_icon.svg";
import Card from "../Card";
import { sendCancelNotification, sendCreateNotification } from "../../core/api";
import { useDropdown } from "../Dropdown/useDropdown";

interface Props {
  targetUuid: string;
  scheduling?: Scheduling;
  onUpdate: (targetUuid: string, schedule: Scheduling | undefined) => void;
  className: string;
}

export default function ScheduleReminder({ targetUuid, scheduling, onUpdate, className }: Props) {
  const { dropdownRef, onClickTrigger, isOpened } = useDropdown();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { getAccessTokenSilently } = useAuth0();
  const tomorrow = new Date(); //used for date-picker
  tomorrow.setDate(tomorrow.getDate() + 1);

  const onChange = async (date: Date) => {
    if (isLoading) {
      return;
    }
    //Weird date parsing logic due to JSON parsing error for JS Date objects
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds())
    );
    //Set hours with the user's timezone
    utcDate.setUTCHours(8, 0, 0, 0);
    const sendAtUnix = Math.floor(utcDate.getTime() / 1000);
    setIsLoading(true);
    const scheduleResponse = await sendCreateNotification(targetUuid, sendAtUnix, await getAccessTokenSilently());
    onUpdate(targetUuid, scheduleResponse);
    setIsLoading(false);
  };

  const onCancel = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    onUpdate(targetUuid, undefined);
    await sendCancelNotification(targetUuid, await getAccessTokenSilently());
    setIsLoading(false);
  };

  return (
    <div className={`${className} flex`}>
      {scheduling && scheduling.send_at ? (
        <div className="items-center">
          <div onClick={onClickTrigger}>
            <Schedule className={`ml-2 w-8 stroke-purple`}></Schedule>
          </div>
          {isOpened && (
            <div ref={dropdownRef} className=" absolute translate-x-[-190px] z-50">
              <Card className=" flex w-[210px] h-[75px] my-1">
                <ol className="w-full text-xs text-gray cursor-default ">
                  <li className="w-full hover:bg-gray-400 hover:rounded-t-lg h-1/3 items-center flex">
                    <CalendarIcon stroke="#6e6e6e" className="mx-1"></CalendarIcon>
                    Reminder set {new Date(scheduling.send_at).toDateString()}
                  </li>
                  <li className="w-full hover:bg-gray-400 h-1/3 items-center flex">
                    <UserIcon fill="#6e6e6e" className="mx-1"></UserIcon>
                    {scheduling.receiver}
                  </li>
                  <li
                    className="w-full hover:bg-gray-400 h-1/3 items-center flex cursor-pointer hover:rounded-b-lg"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await onCancel();
                    }}>
                    <CloseIcon stroke="#6e6e6e" className="mx-1"></CloseIcon>Cancel Reminder
                  </li>
                </ol>
              </Card>
            </div>
          )}
        </div>
      ) : (
        <DatePicker
          className=""
          onChange={onChange}
          minDate={tomorrow}
          disabled={isLoading}
          portalId="root-portal"
          customInput={<Schedule className={`ml-2 w-8 ${!isLoading ? "stroke-[#6e6e6e]" : "stroke-purple"} hover:stroke-purple`}></Schedule>}
          dateFormat="dd/MM/yyyy"></DatePicker>
      )}
    </div>
  );
}
