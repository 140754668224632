import { Contact, Prospect } from "../../core/types";
import Tooltip from "../Tooltip";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";
import { saveContact } from "../../core/api";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../Button/Button";
import { useState } from "react";
import { ReactComponent as SkeletonUser } from "../../icons/defaultprofile.svg";
import { ReactComponent as OwnedIcon } from "../../icons/social/checkmark.svg";
import "./Prospects.scss";
import { capitalizeAllWords } from "../../core/helpers";
import GetEmailComponent from "../ContactDetails/GetEmailComponent";
import mixpanel from "mixpanel-browser";

interface Props {
  className: string;
  prospects: Prospect[];
  isLoading: boolean;
  onSave?: (contact: Contact) => void;
}

export default function ProspectsList({ prospects, onSave, className, isLoading }: Props) {
  const prospectsSorted = prospects.sort((a, b) => {
    return a.position !== b.position ? a.position.localeCompare(b.position) : a.last_name.localeCompare(b.last_name);
  });

  const { currentPage, setCurrentPage, pages, currentPageData } = usePagination(prospectsSorted, 5);
  const { getAccessTokenSilently } = useAuth0();
  const [isSaving, setIsSaving] = useState(false);
  // const navigate = useNavigate();

  function renderProspects() {
    return currentPageData.map((p) => renderProspectInfo(p));
  }

  return (
    <ol className={`bg-white leading-[14px] font-medium rounded-[10px]  ${className}`}>
      <li className="grid grid-cols-5 font-semibold my-4 ml-12">
        <div className="ml-0">Name</div>
        <div className="ml-0.5">Title</div>
        <div className="ml-0.5">Contact Info</div>
        <div className="ml-2">Location</div>
      </li>
      <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
      {currentPageData && currentPageData.length && !isLoading ? renderProspects() : renderEmptyProspectInfo()}
      <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </ol>
  );

  function renderEmptyProspectInfo() {
    return (
      <div className="flex">
        <ul className="w-full">
          {(() => {
            const skeletonRow = [];
            for (let i = 0; i < 5; i++) {
              skeletonRow.push(
                <div key={`skellington${i}`}>
                  <div className={"flex mt-1 mb-0 h-10 "}>
                    <SkeletonUser className="mr-2 w-7 h-7 mt-1"></SkeletonUser>
                    <ol className="grid grid-cols-5 font-semibold my-6 w-full">{getSkeletonElement()}</ol>
                  </div>
                  {i !== 4 && <hr className="h-px mb-0 bg-gray-200 border-0 dark:bg-gray-700"></hr>}
                </div>
              );
            }
            return skeletonRow;
          })()}
        </ul>
      </div>
    );
  }

  function getSkeletonElement() {
    return (
      <>
        {(() => {
          const skeletonElements = [];
          for (let i = 0; i < 4; i++) {
            skeletonElements.push(
              <li className="ml-4 mr-24 mt-1" key={"skellington#" + i}>
                <div className={`Prospect--default_top ${isLoading ? "Prospect--animation" : "Prospect--default_top"}`}></div>
                <div className={`Prospect--default_bottom ${isLoading ? "Prospect--animation" : ""}`}></div>
              </li>
            );
          }
          return skeletonElements;
        })()}
      </>
    );
  }

  function renderProspectInfo(prospect: Prospect) {
    const onClickSave = async (isSaving: boolean, prospect: Prospect) => {
      if (isSaving) {
        return;
      }
      setIsSaving(true);
      return saveContact(prospect, await getAccessTokenSilently())
        .then((res) => {
          //To update contact lists outside of component
          if (onSave) {
            onSave(res);
          }
          mixpanel.track("save_action", { entity: "contact", from: "search" });
          prospect.isOwned = !prospect.isOwned;
          setIsSaving(false);
          return res;
        })
        .catch(() => setIsSaving(false));
    };

    // const navigateToContactPage = async (p: Prospect) => {
    //   p.isOwned
    //     ? await getSavedContactByLinkedin(p.linkedin_url, await getAccessTokenSilently()).then((res) =>
    //         navigate(RoutesEnum.SingleContact.replace(":id", res.uuid), { state: { from: capitalizeFirst(p.company) } })
    //       )
    //     : await onClickSave(isSaving, p).then((res) => {
    //         if (res?.uuid) {
    //           //consider passing prospects forth and back, how to deal with state consistency? i.e. navigating back from contact, we want to "save" the prospects but navigating to another company we don't want the state...
    //           navigate(RoutesEnum.SingleContact.replace(":id", res.uuid), { state: { from: capitalizeFirst(p.company) } });
    //         }
    //       });
    // };

    return (
      <div key={prospect.linkedin_url} className="hover:bg-[#dedede] relative rounded-sm w-full ">
        <div
          className="mb-0 py-2 flex w-full "
          onClick={(e) => {
            e.preventDefault();
            // navigateToContactPage(prospect);
          }}>
          <img
            src={prospect.image_url ? prospect.image_url : process.env.PUBLIC_URL + "/images/default_profile_image.png"}
            className={"h-7 w-7 rounded-full mt-1 ml-2"}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = process.env.PUBLIC_URL + "/images/default_profile_image.png";
            }}></img>
          <ol className="grid grid-cols-5 mt-1 my-6 text-[12px] items-center w-full">
            <li className="truncate block mx-1 ml-5">{`${capitalizeAllWords(prospect.first_name)} ${capitalizeAllWords(prospect.last_name)}`}</li>
            <li className="truncate block mx-1 ">{prospect.position}</li>
            <li className="ml-1 truncate">
              {/* not working properly, try move tooltip to getemailcomponent maybe? might be stoppropagation in getemailcomp */}
              <Tooltip
                content={"Contact saved"}
                onClick={(e, isOpened, setIsOpened) => {
                  e.stopPropagation();
                  if (isOpened) return;
                  setIsOpened(true);
                  setIsSaving(true);
                  prospect.isOwned = true;
                  setIsSaving(false);
                }}>
                <GetEmailComponent
                  className={"mt-1"}
                  email={prospect.email}
                  isSaved={false}
                  emailStatus={prospect.email_status}
                  styling="table"
                  contactDetails={{
                    first_name: prospect.first_name,
                    last_name: prospect.last_name,
                    domain: prospect.company_domain,
                    linkedin_url: prospect.linkedin_url,
                    photo_url: prospect.image_url,
                    email: prospect.email,
                    title: prospect.position
                  }}></GetEmailComponent>
              </Tooltip>
            </li>
            <li className="truncate block ml-3">{prospect.country}</li>
            <li className="right-8 absolute justify-center items-center z-40">
              <Tooltip
                className=" z-50 absolute right-2 my-4 "
                content={!prospect.isOwned ? "Contact unsaved" : "Contact saved"}
                onClick={async (e, isOpened, setIsOpened) => {
                  e.stopPropagation();
                  if (isOpened) return;
                  setIsOpened(true);
                  await onClickSave(isSaving, prospect);
                }}>
                <Button className="h-6 " styling="save">
                  {!prospect.isOwned ? (
                    " + Save "
                  ) : (
                    <div className="flex items-center">
                      <OwnedIcon className="mr-1"></OwnedIcon> {"Saved"}
                    </div>
                  )}
                </Button>
              </Tooltip>
            </li>
          </ol>
        </div>
        <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
      </div>
    );
  }
}
