import { createContext } from "react";

type PreferencesContextType = {
  profilePref: string[];
  languagePref: string[];
  countryPref: string[];
  prospectPref: string[];
};

export const PrefContext = createContext<PreferencesContextType>({ profilePref: [], languagePref: [], countryPref: [], prospectPref: [] });
