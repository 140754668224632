import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import Card from "../Card";
import { useDropdown } from "../Dropdown/useDropdown";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";

interface CalendarFilterProps {
  onFromDateChange: (date: Date | null) => void;
  onToDateChange: (date: Date | null) => void;
  eventName: string;
  className?: string;
}

const CalendarFilterComponent: React.FC<CalendarFilterProps> = ({ eventName, onFromDateChange, onToDateChange, className }) => {
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const handleFromDateChange = (date: Date | null) => {
    onFromDateChange(date);
    setFromDate(date);
  };

  const handleToDateChange = (date: Date | null) => {
    onToDateChange(date);
    setToDate(date);
  };

  const { menuRef, dropdownRef, onClickTrigger, isOpened } = useDropdown();

  return (
    <div className={`select-none relative`} ref={dropdownRef}>
      <div
        className={`${className} ${
          fromDate || toDate ? "border-[#877CFF] text-[#877CFF]" : "border-gray-100 text-gray"
        } border-[0.2px] rounded-lg resize-none`}>
        <div className="mr-2 flex items-center pt-0.5 hover:cursor-pointer" onClick={onClickTrigger}>
          <p className="">Date Posted</p>
          <Polygon className=" ml-2"></Polygon>
        </div>
      </div>
      <div className="relative">
        {isOpened && (
          <div ref={menuRef}>
            <Card className="absolute top-0 left-0 flex-col w-[240px] z-10 mt-2">
              <div className="mt-2 text-gray ">
                <label className=" font-bold ml-2 pt-2">{eventName} </label>
                <label className="">between</label>
              </div>
              <div className="">
                <DatePicker
                  id="fromDate"
                  dateFormat="dd/MM/yyyy"
                  className="outline-none placeholder-gray border-gray-100 border-[0.2px] rounded-md mx-2 mb-2 mt-2 w-[149px]"
                  placeholderText="dd / MM / yyyy"
                  selected={fromDate}
                  maxDate={new Date()}
                  showIcon
                  icon={<CalendarIcon className="w-5 h-5 ml-[3px] mt-[7px]" stroke="gray" />}
                  onChange={handleFromDateChange}
                />
                <label className="absolute  top-[57px] right-5 transform -translate-x-1/2 -translate-y-1/2 text-gray">and</label>
              </div>
              <DatePicker
                id="toDate"
                dateFormat="dd/MM/yyyy"
                className="outline-none placeholder-gray border-gray-100 border-[0.2px] rounded-md mx-2 mb-2 mt-2 w-[149px]"
                icon={<CalendarIcon className="w-5 h-5 ml-[3px] mt-[7px] " stroke="gray" />}
                placeholderText="dd / MM / yyyy"
                selected={toDate}
                minDate={fromDate}
                maxDate={new Date()}
                showIcon
                onChange={handleToDateChange}
              />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarFilterComponent;
