import { RoutesEnum } from "../core/enums";
import { ReactComponent as Zap } from "../icons/zap.svg";
import { ReactComponent as Users } from "../icons/users.svg";
import { ReactComponent as Scoops } from "../icons/scoops_selected.svg";
import { ReactComponent as Companies } from "../icons/briefcase.svg";
import { Link } from "react-router-dom";
import "./Home.scss";

export const EXTENSION_LINK = "https://chrome.google.com/webstore/detail/sharpreach/pkeohopamnaclncnfadpgkldipmakgnh?hl=en&authuser=0";

const LINKS = [
  {
    to: RoutesEnum.Scoops,
    label: "Scoops",
    icon: <Scoops />,
    description:
      "A personalized feed of actionable insights at your fingertips. Stay ahead of the curve and keep up-to-date with the latest updates within your industry, empowering you to make informed decisions and seize opportunities before anyone else."
  },
  {
    to: RoutesEnum.CompanyLookup,
    label: "Search",
    icon: <Zap />,
    description:
      "Experience the ease and efficiency of our contact discovery. With a simple search of any company URL, unlock a wealth of invaluable data and gain direct access to key contacts within that organization."
  },
  {
    to: RoutesEnum.SavedCompanies,
    label: "Companies",
    icon: <Companies />,
    description:
      "Streamline your outreach and keep your prospects organized. save any company you've searched for, ensuring that you never miss an opportunity to connect and engage."
  },
  {
    to: RoutesEnum.Contacts,
    label: "Contacts",
    icon: <Users />,
    description: "Save individual contacts within the companies you've searched for, ensuring you never lose track of valuable connections."
  }
];

export default function Home() {
  const cardClassName = "rounded-[10px] bg-white Home__card py-[30px] px-[53px] min-h-[196px] bg-white no-underline block mb-6 lg:mb-0";
  return (
    <div className="Home px-5 grow pt-8" data-testid="Home">
      <div className="container-sm">
        <div className="text-3xl Title font-bold text-center mb-14 mt-8"></div>
        <div className="lg:grid gap-16 grid-cols-2 max-w-[1060px] mx-auto">
          {LINKS.map((l) => (
            <Link key={l.label} className={cardClassName} to={l.to} data-testid={`link-${l.label.replace(/\s/g, "_")}`}>
              <div className="leading-none Home__icon">{l.icon}</div>
              <div className="text-lg leading-[22px] font-medium mb-3">{l.label}</div>
              <div className="text-xs text-gray">{l.description}</div>
            </Link>
          ))}
          {/* eslint-disable-next-line */}
          {/* <a href={EXTENSION_LINK} target="_blank" rel="noopener noreferrer" className={cardClassName} data-testid="link-Extension">
            <div
              className="leading-none"
              style={{
                marginBottom: 6
              }}>
              <img src={Google} alt="google-icon" className="h-8 w-8" />
            </div>
            <div className="text-lg leading-[22px] font-medium mb-3">Google Chrome Extension</div>
            <div className="text-xs text-gray">
              Use our Google Chrome Extension to collect contact information and generate personalized icebreakers on the fly.
            </div>
          </a> */}
        </div>
      </div>
    </div>
  );
}
