import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./reset.scss";
import "./index.css";
import "./styles.scss";
import { Auth0Provider } from "@auth0/auth0-react";
import mixpanel from "mixpanel-browser";

const queryClient = new QueryClient();
mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY!, { debug: true, track_pageview: true, persistence: "localStorage" });
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Auth0Provider
        domain="https://sharpreach-dev.us.auth0.com"
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        redirectUri={process.env.REACT_APP_AUTH0_CALLBACK_URL}
        audience="https://sharpreach.api"
        scope="openid, profile, email">
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
