import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import "./Input.scss";

interface Props {
  label?: string;
  name: string;
  error: string | undefined;
  errorClassName?: string;
  isMultiline?: boolean;
}

export default function Input({ label, className = "", errorClassName = "", error, name, ...rest }: InputHTMLAttributes<HTMLInputElement> & Props) {
  return (
    <div className={`Input ${className}`}>
      {label && <div className="Input__label text-lg leading-[22px] mb-2 text-gray">{label}</div>}
      <input
        {...rest}
        className={classNames(
          "Input--field ",
          {
            "border-red-500": !!error
          },
          `${className}`
        )}
        name={name}
      />
      {error && <div className={`text-[11px] leading-[13px] text-red-500 pt-1 ${errorClassName}`}>{error}</div>}
    </div>
  );
}
