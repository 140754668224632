import classNames from "classnames";
import { ChangeEvent, HTMLAttributes, MouseEventHandler, PropsWithChildren } from "react";
import { Transition } from "react-transition-group";
import "./Dropdown.scss";
import { useDropdown } from "./useDropdown";

export interface DropdownItem {
  onClick?: MouseEventHandler<HTMLDivElement>;
  label: string;
  className?: string;
  icon?: JSX.Element;
  testId?: string;
  checkBox?: JSX.Element;
  checkboxOnChange?: (event: ChangeEvent<HTMLInputElement> | null) => void;
}

interface Props {
  trigger: JSX.Element | ((isOpened: boolean) => JSX.Element);
  items: DropdownItem[];
  className?: string;
  displayText?: string | JSX.Element;
  closeOnClick?: boolean;
  popupDirection?: "left" | "right";
}

export default function Dropdown({
  trigger,
  items,
  className = "",
  children,
  displayText,
  popupDirection,
  closeOnClick,
  ...rest
}: PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>) {
  const { dropdownRef, menuRef, onClickTrigger, isOpened } = useDropdown();

  const testid = ({ ...rest } as any)["data-testid"];

  return (
    <div
      className={`Dropdown relative ${
        popupDirection === "left" ? "Dropdown__expand-left" : popupDirection === "right" ? "Dropdown__expand-right" : ""
      } ${className}`}
      ref={dropdownRef}
      {...rest}>
      <div
        className={classNames("Dropdown__trigger h-full flex  items-center cursor-pointer rounded-[0.2rem]", {
          "Dropdown__trigger--active": isOpened
        })}
        onClick={onClickTrigger}
        data-testid={testid ? `${testid}-trigger` : undefined}>
        <div className=" flex items-center h-full">
          {displayText && <div className="hover:bg-[#877cff2e] rounded-sm">{displayText}</div>}
          {typeof trigger === "function" ? trigger(isOpened) : trigger}
        </div>
      </div>

      <Transition in={isOpened} timeout={{ enter: 0, exit: 250 }} unmountOnExit nodeRef={menuRef}>
        <div
          ref={menuRef}
          className={classNames(
            `Dropdown__menu absolute  z-10 Scrollbar--sm ${
              popupDirection === "left" ? " right-[100%] " : popupDirection === "right" ? " left-[100%] " : "" //TODO: fix me
            }`,
            {
              fadeIn: isOpened,
              fadeOut: !isOpened
            }
          )}>
          {items.map((item) => (
            <div
              key={item.label}
              className={`flex items-center Dropdown__item cursor-pointer text-[11px] leading-[13px] text-gray hover:text-purple ${
                item.className || ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                item.onClick ? item.onClick(e) : undefined;
                if (closeOnClick) {
                  onClickTrigger(e);
                }
              }}
              data-testid={item.testId || testid ? `${testid}-${item.label.replace(/\s/g, "")}` : undefined}>
              {item.checkBox && <div className="mr-1.5"> {item.checkBox} </div>}
              {item.icon && <div className="Dropdown__icon">{item.icon}</div>}
              <div className={`grow Dropdown__label`}>{item.label}</div>
            </div>
          ))}
          {children}
        </div>
      </Transition>
    </div>
  );
}
