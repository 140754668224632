import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import { ReactComponent as Checkmark } from "../icons/checkmark.svg";

export default function Checkbox({ checked, ...props }: Omit<InputHTMLAttributes<HTMLInputElement>, "type">) {
  return (
    <label
      className={classNames("w-[15px] h-[15px] relative rounded-[3px] border-[0.5px] border-solid border-gray shrink-0 block ", {
        "bg-white border-gray-100": !checked,
        "bg-purple border-purple": checked
      })}>
      {checked && (
        <div className="absolute top-0 left-2/4 translate-x-[-50%]">
          <Checkmark />
        </div>
      )}
      <input className="hidden" {...props} checked={checked} type="checkbox" />
    </label>
  );
}
