import { useAuth0 } from "@auth0/auth0-react";
import { useQueries } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  fetchCommentsForCompany,
  getCompanyInfo,
  getCurrentUser,
  getOrganizationMembers,
  getProspectOptions,
  getProspects,
  getSavedContactsForCompany,
  isCompanySavedForOrganization,
  setCompanyOwner
} from "../../core/api";
import RelatedScoopsComponent from "./RelatedScoopsComponent";
import Header from "./Header";
import InfoCard from "./InfoCard";
import ProspectsList from "./ProspectsList";
import Loader from "../Loader";
import { capitalizeAllWords, sleep, sortArrayOnCreated } from "../../core/helpers";
import { ReactComponent as Briefcase } from "../../icons/briefcase.svg";
import { Button } from "../Button/Button";
import { ReactComponent as SearchIcon } from "../../icons/search_icon.svg";
import { DropdownItem } from "../Dropdown/Dropdown";
import MultipleSelectDropdown from "../Dropdown/MultipleSelectDropdown";
import { Contact, JobListing, Prospect, Comment } from "../../core/types";
import EmptyPageMessage from "../EmptyPageMessage";
import { CommentComponent } from "../Comment/CommentComponent";
import SavedContactsTable from "./SavedContactsTable";
import mixpanel from "mixpanel-browser";
import OwnedByComponent from "../ContactDetails/OwnedByComponent";

interface Props {
  className?: string;
  companyDomain: string;
  shouldRefetch?: boolean;
  setShouldRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CompanyInfoCompiled({ companyDomain, className, shouldRefetch, setShouldRefetch }: Props) {
  const [isError, setIsError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isFetchingProspects, setIsFetchingProspects] = useState(false);
  const [relatedJobListings, setRelatedJobListings] = useState<JobListing[]>([]);
  const [prospectCategory, setProspectCategory] = useState("");
  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [savedContacts, setSavedContacts] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState(!shouldRefetch);
  const [initialFetch, setInitialFetch] = useState(!shouldRefetch);
  const [comments, setComments] = useState<Comment[]>();

  const { getAccessTokenSilently } = useAuth0();
  const [user, company, roles, orgMembers] = useQueries({
    queries: [
      {
        queryKey: ["user"],
        queryFn: async () =>
          await getCurrentUser(await getAccessTokenSilently()).then((res) => {
            if (res.prospect_roles && res.prospect_roles.length) {
              setProspectCategory(res.prospect_roles[0]);
            }
            return res;
          }),
        refetchOnWindowFocus: false
      }, //consider implementing a fetchSavedCompanies -method
      {
        queryKey: ["company", companyDomain],
        queryFn: async () =>
          await getCompanyInfo(companyDomain, await getAccessTokenSilently())
            .then(async (res) => {
              await isCompanySavedForOrganization(res.uuid, await getAccessTokenSilently()).then((r) => setIsSaved(r));
              await fetchCommentsForCompany(res.uuid, await getAccessTokenSilently()).then((c) => {
                if (!c || !c.length) {
                  setComments([]);
                }
                setComments(sortArrayOnCreated(c, "ascending") as Comment[]);
              });
              setRelatedJobListings(res.job_listings);
              setIsError(false);
              return res;
            })
            .catch(() => {
              setIsError ? setIsError(true) : null;
            }),
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["roles"],
        queryFn: async () => await getProspectOptions(await getAccessTokenSilently()),
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["OrgMembers"],
        queryFn: async () => await getOrganizationMembers(await getAccessTokenSilently())
      },
      {
        queryKey: ["savedContacts", companyDomain],
        enabled: isSaved,
        queryFn: async () =>
          await getSavedContactsForCompany(companyDomain, await getAccessTokenSilently()).then((res) => {
            setSavedContacts(res);
            return res;
          })
      }
    ]
  });

  useEffect(() => {
    if (!isLoading && initialFetch) {
      fetchInitialData();
    }
    handleLoading();

    async function fetchInitialData() {
      setIsLoading(true);
      setInitialFetch(false);
      await company.refetch();
      // await prospects.refetch();
    }
  }, [initialFetch, company, prospects]);

  useEffect(() => {
    if (!shouldRefetch || isLoading || !setShouldRefetch) {
      return;
    }
    setProspects([]);
    refetchData();
    setShouldRefetch(false);
    handleLoading();

    async function refetchData() {
      setIsLoading(true);
      await company.refetch();
    }
  }, [shouldRefetch, company, prospects]);

  function handleLoading() {
    if ((company.data && user.data && !company.isLoading && !company.isRefetching && !user.isLoading) || isError) {
      setIsLoading(false);
    }
  }

  function toDropdownItems(items: string[]): DropdownItem[] {
    if (!items || !items.length) {
      return [];
    }
    return items?.toSorted().map((label) => {
      return {
        onClick: () => {
          setProspectCategory(label);
        },
        label: label !== "C_SUITE" ? capitalizeAllWords(label.replace("_", " ")) : "C-Suite"
      } as DropdownItem;
    });
  }

  async function onSave() {
    await sleep(200); //To discourage race condition and for style purposes
    await user.refetch();
    setIsSaved(!isSaved);
  }

  async function onSearch() {
    if (!prospectCategory) {
      return;
    }
    setProspects([]);
    setIsFetchingProspects(true);
    const payload = {
      companyDomain: companyDomain,
      prospectCategory: prospectCategory
    };
    const prospects = await getProspects(payload, await getAccessTokenSilently())
      .then((res) => {
        setProspects(res);
        setIsFetchingProspects(false);
        return res;
      })
      .catch(() => setIsFetchingProspects(false));

    mixpanel.track("search_action", { entity: "prospect", error: !prospects || !prospects.length, query: prospectCategory, company: companyDomain });
  }

  async function onSaveContact(contact: Contact) {
    const isSaved = savedContacts.map((c) => c.uuid).includes(contact.uuid);
    if (isSaved) {
      const updatedContacts = savedContacts.filter((c) => c.uuid !== contact.uuid);
      setSavedContacts(updatedContacts);
    } else {
      const updatedContacts = [...savedContacts, contact];
      setSavedContacts(updatedContacts);
    }
  }

  return (
    <div className={`${className}`}>
      {isLoading || shouldRefetch ? (
        <Loader></Loader>
      ) : isError ? (
        <EmptyPageMessage
          icon={Briefcase}
          message={`Could not find Company Information for ${companyDomain} `}
          className=" mt-[200px]"></EmptyPageMessage>
      ) : (
        <>
          <Header isSaved={isSaved} onSave={onSave} company={company.data!} className="mt-1"></Header>
          {user.data && isSaved && company.data && (
            <div>
              {orgMembers.data && (
                <OwnedByComponent
                  className="mt-5"
                  ownerEmail={company.data.assignees[0]?.email} //BE handles filtering of assignees
                  targetUuid={company.data.uuid}
                  orgMembers={orgMembers.data}
                  setNewOwnerApiCall={setCompanyOwner}></OwnedByComponent>
              )}
              <CommentComponent type="company" comments={comments || []} targetUuid={company.data.uuid} user={user.data}></CommentComponent>
            </div>
          )}
          <InfoCard company={company.data!} className="mt-5 flex"></InfoCard>
          {savedContacts && isSaved && savedContacts.length > 0 && (
            <div>
              <h2 className=" my-7 mx-3 font-semibold">Saved Contacts</h2>
              <SavedContactsTable contacts={savedContacts} className={""}></SavedContactsTable>
            </div>
          )}
          <div className="grid grid-cols-5 font-semibold my-4">
            <h2 className=" my-3 mx-3 font-semibold">Key Contacts</h2>
            <div className="absolute right-2 flex self-center items-center">
              <MultipleSelectDropdown
                items={toDropdownItems(roles.data)}
                displayText={"Select Role"}
                preSelected={prospectCategory ? toDropdownItems([prospectCategory]) : undefined}
                className={" bg-white mr-2 h-8 text-sm"}
                isSingleSelect={true}></MultipleSelectDropdown>
              <Button
                styling="primary"
                className="min-w-[62px] w-22 font-normal items-center h-8 text-sm content-center flex"
                onClick={() => onSearch()}>
                <SearchIcon className="w-4 h-4"></SearchIcon>Search
              </Button>
            </div>
          </div>
          <ProspectsList onSave={onSaveContact} prospects={prospects} className="py-2 pl-4 pr-3" isLoading={isFetchingProspects}></ProspectsList>
          {!company.isRefetching && ((company.data?.articles && company.data?.articles?.length > 0) || relatedJobListings.length > 0) ? (
            <div>
              {company.data?.articles && company.data?.articles?.length > 0 ? (
                <RelatedScoopsComponent
                  articles={company.data.articles.map((a) => {
                    a.type = "article";
                    return a;
                  })}
                  className="mt-3 py-2 pl-4 pr-3 "></RelatedScoopsComponent>
              ) : (
                <></>
              )}
              {company.data?.job_listings && company.data?.job_listings.length > 0 ? (
                <RelatedScoopsComponent
                  articles={relatedJobListings.map((j) => {
                    j.type = "joblisting";
                    return j;
                  })}
                  className="mt-3 py-2 pl-4 pr-3 "></RelatedScoopsComponent>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}
