import Papa from "papaparse";
import { Contact } from "../../core/types";

export const generateCsv = (contactsData: Contact[]) => {
  if (!contactsData[0]) {
    return `data:text/csv;charset=utf-8,${[].map((rowArray: string[]) => rowArray.join(",") + "\r\n").join("")}`;
  }
  const columnsToSkip = ["uuid", "groupUuid", "updated_at", "image_url", "ownerUuid", "emailLookupUuid", "email_draft", "organization", "tags"];
  const header = Object.keys(contactsData[0]).filter((k) => !columnsToSkip.includes(k));

  const data = contactsData.map((c) =>
    Object.entries(c)
      .filter(([header]) => columnsToSkip.indexOf(header) === -1)
      .map(([k, v]) => {
        if (!v) {
          return [" "];
        }
        switch (k) {
          case "comments":
            return [v.length ? v[0].message : " "];
          case "status":
            return [v.name || " "];
          case "owner":
            return [v.email || " "];
          default:
            return [String(v)];
        }
      })
      .map((arr) => arr?.flat())
      .flat()
  );

  const rows = [[...header], ...data];

  return `data:text/csv;charset=utf-8,${Papa.unparse(rows)}`;
};
