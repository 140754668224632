import { ReactNode, useState } from "react";
import EmailInputEditor from "./EmailInputEditor";
import { Article, User } from "../../core/types";
import { GenerateEmailForm } from "./GenerateEmailForm";

interface Props {
  draft?: string;
  contactUuid?: string;
  relatedArticles: Article[];
  contactDetails?: ReactNode;
  commentSection?: ReactNode;
  ownedBy?: ReactNode;
  className?: string;
  user?: User;
}

export interface UserEmailSettings {
  user_company_name?: string;
  description?: string;
  language?: string;
}

export default function EmailBuilderComponent({
  draft,
  contactUuid,
  relatedArticles,
  contactDetails,
  commentSection,
  ownedBy,
  className,
  user
}: Props) {
  const [userEmailSetup, setUserEmailSetup] = useState<UserEmailSettings>(user?.generate_email_settings || {});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailOutput, setEmailOutput] = useState<string>(draft || "");
  return (
    <div className={`${className} flex  min-h-full `}>
      <div className="flex-col flex-1 flex ">
        {contactDetails && <div className="mb-2">{contactDetails}</div>}
        {ownedBy && <div>{ownedBy}</div>}
        {commentSection && <div className="z-80">{commentSection}</div>}
        <EmailInputEditor
          className="mt-2 flex-1 min-w-[80%] "
          setEmailInput={setEmailOutput}
          emailInput={emailOutput}
          isLoading={isLoading}></EmailInputEditor>
      </div>
      <div className="mx-10 flex-col flex ">
        <GenerateEmailForm
          className="flex-1 w-full"
          setUserSetup={setUserEmailSetup}
          userSetup={userEmailSetup}
          setEmailOutput={setEmailOutput}
          articles={relatedArticles}
          contactUuid={contactUuid}
          setIsLoading={setIsLoading}></GenerateEmailForm>
      </div>
    </div>
  );
}
