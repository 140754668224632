import classNames from "classnames";
import { ReactComponent as ChevronLeft } from "../../icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";

interface Props {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pages: number | undefined;
}

export default function Pagination({ pages = 1, currentPage, setCurrentPage }: Props) {
  const onClickNext = () => {
    setCurrentPage((p) => p + 1);
  };

  const onClickPrev = () => {
    setCurrentPage((p) => p - 1);
  };

  const delimetr = <span>...</span>;

  const onPageClick = (n: number) => {
    setCurrentPage(n);
  };

  const pagesArr = Array(pages)
    .fill(1)
    .map((_, i) => i + 1);

  const middle = pagesArr.slice(3, -3);
  const renderMiddle = () => {
    if (pages <= 6) return null;

    if (currentPage < 3) {
      return delimetr;
    }

    return (
      <>
        {middle.length > 0 && currentPage > 5 && delimetr}
        {(currentPage === 3
          ? middle.slice(0, 3)
          : currentPage === 4
          ? middle.slice(currentPage - 4, currentPage - 1)
          : middle.slice(currentPage - 5, currentPage - 2)
        ).map((n) => {
          return (
            <div className="px-4 flex items-center" key={n}>
              <button
                className={classNames("text-sm hover:text-purple transition", {
                  "text-purple": currentPage === n
                })}
                onClick={() => onPageClick(n)}
                disabled={currentPage === n}>
                {n}
              </button>
            </div>
          );
        })}
        {middle.length > 3 && currentPage < pages - 4 && delimetr}
      </>
    );
  };

  if (pages <= 1) return null;

  return (
    <div className="flex items-center my-4 justify-center mx-[-16px]">
      <div className="flex items-center px-4">
        <button
          disabled={currentPage === 1}
          className={classNames("Pagination__btn", {
            "opacity-50": currentPage === 1
          })}
          onClick={onClickPrev}
          data-testid="prev-page-btn">
          <ChevronLeft />
        </button>
      </div>
      <div className="px-4 mx-[-16px] flex items-center">
        {pagesArr.slice(0, 3).map((n) => {
          return (
            <div className="px-4 flex items-center" key={n}>
              <button
                className={classNames("text-sm hover:text-purple transition", {
                  "text-purple": currentPage === n
                })}
                onClick={() => onPageClick(n)}
                disabled={currentPage === n}>
                {n}
              </button>
            </div>
          );
        })}
        {renderMiddle()}
        {pagesArr.slice(middle.length + 3).map((n) => {
          return (
            <div className="px-4 flex items-center" key={n}>
              <button
                className={classNames("text-sm hover:text-purple transition", {
                  "text-purple": currentPage === n
                })}
                onClick={() => onPageClick(n)}
                disabled={currentPage === n}>
                {n}
              </button>
            </div>
          );
        })}
      </div>
      <div className="flex items-center px-4">
        <button
          disabled={currentPage === pages}
          className={classNames("Pagination__btn", {
            "opacity-50": currentPage === pages
          })}
          onClick={onClickNext}
          data-testid="next-page-btn">
          <ChevronRight />
        </button>
      </div>
    </div>
  );
}
