import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Dropdown, { DropdownItem } from "../Dropdown/Dropdown";
import { setContactStatus } from "../../core/api";
import { Status } from "../../core/types";
import classNames from "classnames";

interface Props {
  contactUuid: string;
  currentStatus?: Status;
  possibleStatuses: Status[];
  style: "profile" | "minimized";
  onChange: (targetUuid: string, newStatus: Status) => void;
  className?: string;
}

export default function StatusComponent({ contactUuid, currentStatus, possibleStatuses, style, onChange, className }: Props) {
  const UNTAGGED_VALUE = { name: "Not Started", color: "#C4C4C4" };
  const [status, setStatus] = useState<Status>(currentStatus || UNTAGGED_VALUE);
  const [statuses, setStatuses] = useState<DropdownItem[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setStatuses(possibleStatuses.length ? toDropdownItems(possibleStatuses) : []);
  }, [possibleStatuses, status]);

  function toDropdownItems(statusList: Status[]) {
    const statusData: Status[] = [];
    //To sort the statuses so the Nots are first
    const firstStatus = statusList.find((s) => s.name === "Not Started");
    const secondStatus = statusList.find((s) => s.name === "Not Interested");
    if (firstStatus) {
      statusData.push(firstStatus);
    }
    if (secondStatus) {
      statusData.push(secondStatus);
    }
    statusList.map((s) => {
      if (!statusData.map((stat) => stat.name).includes(s.name)) {
        statusData.push(s);
      }
    });
    return statusData.map((status) => {
      return {
        onClick: async () => handleClick(contactUuid, status),
        label: status.name
      } as DropdownItem;
    });
  }

  async function handleClick(contactUuid: string, status: Status) {
    if (!contactUuid) {
      return;
    }
    setContactStatus(contactUuid, status.name, await getAccessTokenSilently());
    setStatus(status);
    if (onChange) {
      onChange(contactUuid, status);
    }
  }

  return style === "minimized" && statuses.length ? (
    <Dropdown
      className={`${className} h-6  rounded-[0.2rem] `}
      trigger={<div className=""></div>}
      style={{ backgroundColor: `${status.color}` }}
      displayText={
        <div
          className={classNames(
            `overflow-hidden text-ellipsis whitespace-nowrap block-inline  rounded-[0.2rem] px-2 h-6 flex contents-center text-sm hover:bg-[#877cff2e] items-center ${
              status.name !== UNTAGGED_VALUE.name ? ` text-white` : " text-gray"
            } `
          )}>
          {status.name}
        </div>
      }
      items={statuses || []}></Dropdown>
  ) : (
    <></>
  );
}
