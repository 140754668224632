//eslint disabling due to weird bug where it complains on a non-existent variable
/* eslint-disable no-unused-vars */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Dropdown from "../../components/Dropdown/Dropdown";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { deleteBatch, getBatchGenerate, postNewBatch } from "../../core/api";
import { ReactComponent as More } from "../../icons/more.svg";
import { ReactComponent as Trash } from "../../icons/trash.svg";
import { ReactComponent as Download } from "../../icons/download.svg";
import { ReactComponent as Edit } from "../../icons/edit.svg";
import { Button } from "../../components/Button/Button";
import { useState } from "react";
import NewBatch from "./NewBatch";
import "./BatchGenerate.scss";
import MiniLoader from "../../components/MiniLoader";
import { format } from "date-fns";
import { downloadFile, normalizeDateFE, sortArrayOnCreated } from "../../core/helpers";
import PageWrapper from "../../wrappers/PageWrapper";
import Card from "../../components/Card";
import CreateEditNameModal from "../../components/CreateEditNameModal";
import { useModal } from "../../hooks/useModal";
import { FormikErrors } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import Papa from "papaparse";
import Pagination from "../../components/Pagination/Pagination";
import usePagination from "../../components/Pagination/usePagination";

export interface Batch {
  uuid: string;
  file_name: string;
  size: number;
  csv_file: string[][];
  status: string;
  created_at: string;
  updated_at: string;
}

export default function BatchGenerate() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [processingFiles, setProcessingFiles] = useState<string[]>([]);
  const [batchToEdit, setBatchToEdit] = useState<Batch>();
  const { isModalOpened, openModal, closeModal } = useModal();

  const { getAccessTokenSilently } = useAuth0();

  const queryClient = useQueryClient();

  const batches = useQuery(["BatchGenerate"], async () => getBatchGenerate(await getAccessTokenSilently()));
  const { currentPage, setCurrentPage, pages, currentPageData } = usePagination(sortArrayOnCreated(batches.data || [], "ascending"));

  const onClickBack = () => {
    setIsCreating(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendBatch = async (name: string, data: any[], column: string, withEmails: boolean) => {
    setProcessingFiles((p) => [...p, name]);

    postNewBatch({ name, data, column }, await getAccessTokenSilently()).then((res) => {
      setProcessingFiles((p) => p.filter((v) => v !== name));

      queryClient.setQueryData(["BatchGenerate"], () => sortArrayOnCreated([res, ...(batches.data || [])], "ascending"));
    });

    onClickBack();
  };

  // const onSubmitRenameBatch = (uuid?: string) => (name: string, resetForm: () => void) => {
  //   if (!uuid) return;

  //   setIsLoading(true);

  //   patchBatch(uuid, name)
  //     .then((res) => {
  //       queryClient.setQueryData(["BatchGenerate"], (p: Batch[] | undefined) => {
  //         if (!p) return [];

  //         return p.map((b) => {
  //           if (b.uuid === uuid) {
  //             // return res;
  //             return {
  //               ...b,
  //               file_name: name
  //             };
  //           }
  //           return b;
  //         });
  //       });
  //       closeModal();
  //       resetForm();
  //       setBatchToEdit(undefined);
  //     })
  //     .catch(console.error)
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  const fileInProgress = () => {
    if (!!processingFiles.length || !!batches.data?.find((batch) => batch.status === "in_progress")) {
      return true;
    }
    return false;
  };

  const usedNames = (batches.data || []).filter((g) => !batchToEdit || g.uuid !== batchToEdit.uuid).map((g) => g.file_name);

  if (batches.isLoading) return <Loader />;

  if (isCreating) {
    return <NewBatch onClickBack={onClickBack} onSendBatch={sendBatch} />;
  }

  return (
    <PageWrapper
      data-testid="BatchGenerate"
      title="Batch Generate"
      btn={
        <Button
          onClick={() => {
            setIsCreating(true);
          }}
          data-testid="new-batch-btn">
          New Batch
        </Button>
      }
      top={getBatchLoading()}>
      <Card className="grow max-h-[672px] pb-5 flex flex-col">
        {isLoading && <Loader />}
        <Table<Batch>
          className="grow Table--BatchGenerate"
          columns={[
            {
              label: "Batch",
              render: (r) => (
                <div className="flex flex-col justify-center h-full">
                  <div className="text-[11px] leading-[13px]">{r.file_name}</div>
                  <div className="text-[8px] leading-[11px] text-gray">{r.size} contacts</div>
                </div>
              )
            },
            {
              label: "Status",
              render: (r) => (
                <div className="text-[11px] leading-[13px] flex items-center h-full">{r.status === "in_progress" ? "in progress" : r.status}</div>
              )
            },
            {
              label: "Created",
              render: (r) => (
                <div className="text-lowercase text-[11px] leading-[13px] flex items-center h-full">
                  {format(normalizeDateFE(r.created_at), "dd MMM. yyyy")}
                </div>
              )
            },
            {
              label: "Actions",
              empty: true,
              size: "24px",
              render: (r) => (
                <div className="text-lowercase text-[11px] leading-[13px] flex items-center justify-content-end h-full">
                  <Dropdown
                    data-testid={`more-btn-${r.uuid}`}
                    trigger={<More stroke="black" />}
                    items={[
                      {
                        label: "Rename",
                        onClick: () => {
                          openModal();
                          setBatchToEdit(r);
                        },
                        icon: <Edit />
                      },
                      {
                        label: "Download (.csv)",
                        onClick: () => {
                          if (r.status === "complete") {
                            const unparsedFile = Papa.unparse(r.csv_file);
                            const blob = new Blob([unparsedFile]);
                            const fileToDownload = new File([blob], r.file_name);
                            const url = URL.createObjectURL(fileToDownload);
                            const outputName = r.file_name.match(/^.+\.csv$/)
                              ? r.file_name.toLowerCase().replace(/\s/g, "_")
                              : `${r.file_name.toLowerCase().replace(/\s/g, "_")}.csv`;
                            downloadFile(url, outputName);
                          }
                        },
                        icon: <Download />
                      },
                      {
                        label: "Delete",
                        onClick: async () => {
                          setIsLoading(true);
                          deleteBatch(r.uuid, await getAccessTokenSilently()).then(() => {
                            setIsLoading(false);
                            queryClient.setQueryData<Batch[]>(["BatchGenerate"], (data) => {
                              return data?.filter((b) => b.uuid !== r.uuid);
                            });
                          });
                        },
                        icon: <Trash />
                      }
                    ]}
                  />
                </div>
              )
            }
          ]}
          rows={currentPageData}
        />
        <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        <CreateEditNameModal
          data-testid="rename-modal"
          initialName={batchToEdit?.file_name}
          isModalOpened={isModalOpened}
          closeModal={closeModal}
          // onSubmit={onSubmitRenameBatch(batchToEdit?.uuid)}
          onSubmit={() => setIsLoading(false)} //patch this maybe?
          btnLabel="Rename batch"
          validate={(values) => {
            const errors: FormikErrors<typeof values> = {};

            if (!values.name) {
              errors.name = "Required";
            }

            if (values.name && usedNames.includes(values.name)) {
              errors.name = `Batch name is already used`;
            }

            return errors;
          }}
          placeholder="Batch name"
        />
      </Card>
    </PageWrapper>
  );

  function getBatchLoading(): JSX.Element | undefined {
    if (fileInProgress()) {
      if (processingFiles.length) {
        return (
          <>
            <div className="pb-4" data-testid="inprogress-batches">
              {processingFiles.map((p) => loaderElement(p))}
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="pb-4" data-testid="inprogress-batches">
              {batches.data
                ?.filter((batch) => batch.status === "in_progress")
                .map((p) => {
                  return loaderElement(p.file_name);
                })}
            </div>
          </>
        );
      }
    }
    return;

    function loaderElement(p: string): JSX.Element {
      return (
        <div key={p} className="bg-white mb-4 rounded-[10px] BatchGenerate__card flex items-center py-4 p-6 text-[15px] leading-[18px] font-medium">
          <div className="mr-5">{p} - In Progress</div>
          <MiniLoader />
        </div>
      );
    }
  }
}
