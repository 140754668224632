import "./EmailInviteForm.scss";
import { isEmail } from "../../core/helpers";
import { useState } from "react";
import { LinkButton } from "../Button/Button";
import { sendOrganizationInvite } from "../../core/api";
import { useAuth0 } from "@auth0/auth0-react";

type EmailState = {
  items: string[];
  value: string;
  error?: string | null;
  success?: boolean;
};

interface Props {
  className?: string;
}

export default function EmailInviteForm({ className }: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const [emailState, setEmailState] = useState<EmailState>({
    items: [],
    value: "",
    error: null,
    success: false
  });

  const handleKeyDown: (evt: any) => void = (evt: KeyboardEvent) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      const value = emailState.value.trim();

      if (value && isValid(value) && !isInList(value)) {
        setEmailState({
          items: [...emailState.items, emailState.value],
          value: ""
        });
      }
    }
  };

  const handleChange = (evt: any) => {
    setEmailState({
      items: emailState.items,
      value: evt.target.value,
      error: null
    });
  };

  const handleDelete = (item: string) => {
    setEmailState({
      items: emailState.items.filter((i) => i !== item),
      value: emailState.value
    });
  };

  const handlePaste = (evt: any) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData("text");
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter((email: string) => !isInList(email));

      setEmailState({
        items: [...emailState.items, ...toBeAdded],
        value: emailState.value
      });
    }
  };

  function isValid(email: string) {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setEmailState({
        items: emailState.items,
        value: "",
        error: error
      });

      return false;
    }

    return true;
  }

  function isInList(email: string) {
    return emailState.items.includes(email);
  }

  async function handleSendInvite() {
    await sendOrganizationInvite(emailState.items, await getAccessTokenSilently())
      .then((res) => {
        setEmailState({ items: emailState.items, value: "", success: true });
        return res.data;
      })
      .catch((e) => setEmailState({ items: emailState.items, value: emailState.value, error: e.message }));
  }

  return (
    <div className={`EmailInviteForm--base ${className} mx-10 flex`}>
      <ol className="flex mx-1 ">
        {emailState.items.map((item) => (
          <li className="EmailInviteForm--tag-item" onClick={(e) => e.preventDefault()} key={item + "-list"}>
            {item}
            <button type="button" className="button hover:text-purple  " key={`${item}-button`} onClick={() => handleDelete(item)}>
              &times;
            </button>
          </li>
        ))}
      </ol>
      <label className=" EmailInviteForm--input rounded-md flex items-center">
        <input
          className={" outline-none ml-1" + (emailState.error && "EmailInviteForm--has-error")}
          onSelect={(e) => {
            e.preventDefault;
          }}
          value={emailState.value}
          placeholder={!emailState.items.length ? "Email Address" : ""}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}></input>
        {emailState.error && <p className="EmailInviteForm--error">{emailState.error}</p>}
        {emailState.success && <p className="EmailInviteForm--success">{"Invites sent."}</p>}
      </label>
      <LinkButton className="mt-4 h-10 text-center cursor-pointer" disabled={emailState.items.length ? false : true} onClick={handleSendInvite}>
        Invite member
      </LinkButton>
    </div>
  );
}
