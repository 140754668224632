import { useAuth0 } from "@auth0/auth0-react";
import { useQueries } from "@tanstack/react-query";
import { getNewsFlowOptions, getOnboardingOptionsForOrg, getProspectOptions, updateScoopPreferences } from "../../core/api";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import { MouseEvent, useState } from "react";
import Loader from "../../components/Loader";
import { DropdownItem } from "../../components/Dropdown/Dropdown";
import { Button } from "../../components/Button/Button";
import { Organization } from "../../core/types";
import { capitalizeAllWords } from "../../core/helpers";
import FormDropdown from "../../components/Form/FormDropdownInterface";
import FormInput from "../../components/Form/FormInputInterface";
import GenericForm from "../../components/Form/GenericForm";
import FormDropdownInput from "../../components/Form/FormDropdownInputInterface";

interface Props {
  onSubmit: React.MouseEventHandler<Element>;
  organization: Organization;
  articleTag?: string; //since it's a nested object I cannot figure out how to pass it to it's state in time
  className?: string;
}

enum DropdownCategory {
  SELF_INDUSTRY,
  LINKEDIN_URL,
  LOCATION,
  ARTICLE_INDUSTRY,
  ARTICLE_LOCATION,
  TARGET_SIZE,
  TARGET_INDUSTRY,
  TARGET_ROLES,
  JOBLISTING_INDUSTRY,
  JOBLISTING_ROLES,
  JOBLISTING_LOCATION
}

export default function ScoopPreferences({ organization, articleTag, className, onSubmit }: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const [selfIndustry, setSelfIndustry] = useState<string>(organization.self_industry || "");
  const [companyUrl, setCompanyUrl] = useState<string>(organization.linkedin_url || "");
  const [targetLocation, setTargetLocation] = useState<string>(organization.target_location || "");
  const [targetIndustry, setTargetIndustry] = useState<string[]>(organization.suggestion_industry || []);
  const [targetSize, setTargetSize] = useState<string[]>(organization.target_size || []);
  const [targetRoles, setTargetRoles] = useState<string>(organization.target_roles || "");
  const [articleTargetIndustry, setArticleTargetIndustry] = useState<string>(articleTag || "");
  const [articleLocation, setArticleLocation] = useState<string>(organization.article_location || "");
  const [joblistingIndustry, setJoblistingIndustry] = useState<string[]>(organization.joblisting_industry || []);
  const [joblistingCustomRoles, setJoblistingTargetRoles] = useState<string[]>(organization.joblisting_custom_roles || []);
  const [joblistingDepartments, setJoblistingDepartments] = useState<string[]>(organization.joblisting_departments || []);
  const [joblistingLocation, setJoblistingLocation] = useState<string>(organization.joblisting_location || "");

  const [isLoading, setIsLoading] = useState(false);
  const [newsFlowOptions, prospectRolesOptions, onboardingOptions] = useQueries({
    queries: [
      {
        queryKey: ["NewsFlowPrefs"],
        queryFn: async () => await getNewsFlowOptions(await getAccessTokenSilently())
      },
      { queryKey: ["ProspectRoles"], queryFn: async () => await getProspectOptions(await getAccessTokenSilently()) },
      { queryKey: ["OnboardingOptions"], queryFn: async () => await getOnboardingOptionsForOrg(await getAccessTokenSilently()) }
    ]
  });

  function toDropdownItems(items: string[], populateArray: DropdownCategory): DropdownItem[] {
    return items?.map((label) => {
      return {
        onClick: () => addToPreferences(label, populateArray),
        label:
          label === "C_SUITE"
            ? "C-Suite"
            : label === "B2B Services"
            ? "B2B Services"
            : capitalizeAllWords(label.replaceAll("industry_", "").replaceAll("_", " "))
      } as DropdownItem;
    });
  }

  function toCheckboxDropdownItems(items: string[], populateArray: DropdownCategory): DropdownItem[] {
    return items?.map((label) => {
      return {
        checkboxOnChange: () => addToPreferences(label, populateArray),
        label: label === "C_SUITE" ? "C-Suite" : label === "B2B Services" ? "B2B Services" : capitalizeAllWords(label.replaceAll("_", " "))
      } as DropdownItem;
    });
  }

  async function addToPreferences(label: string, category: DropdownCategory) {
    switch (category) {
      case DropdownCategory.LOCATION: {
        setTargetLocation(label);
        break;
      }
      case DropdownCategory.TARGET_SIZE: {
        targetSize.includes(label)
          ? setTargetSize((targetSize) => targetSize.filter((size) => size !== label))
          : setTargetSize((targetSize) => [...targetSize, label]);
        break;
      }
      case DropdownCategory.TARGET_INDUSTRY: {
        targetIndustry.includes(label)
          ? setTargetIndustry((targetIndustry) => targetIndustry.filter((industry) => industry !== label))
          : setTargetIndustry((targetIndustry) => [...targetIndustry, label]);
        break;
      }
      case DropdownCategory.TARGET_ROLES: {
        setTargetRoles(label);
        break;
      }
      case DropdownCategory.SELF_INDUSTRY: {
        setSelfIndustry(label);
        break;
      }
      case DropdownCategory.ARTICLE_INDUSTRY: {
        setArticleTargetIndustry(label);
        break;
      }
      case DropdownCategory.ARTICLE_LOCATION: {
        setArticleTargetIndustry("");
        setArticleLocation(label);
        break;
      }
      case DropdownCategory.JOBLISTING_LOCATION: {
        setJoblistingLocation(label);
        break;
      }
      case DropdownCategory.JOBLISTING_INDUSTRY: {
        joblistingIndustry.includes(label)
          ? setJoblistingIndustry((joblistingIndustry) => joblistingIndustry.filter((industry) => industry !== label))
          : setJoblistingIndustry((joblistingIndustry) => [...joblistingIndustry, label]);
        break;
      }
      case DropdownCategory.JOBLISTING_ROLES: {
        joblistingDepartments.includes(label)
          ? setJoblistingDepartments((joblistingDepartments) => joblistingDepartments.filter((industry) => industry !== label))
          : setJoblistingDepartments((joblistingDepartments) => [...joblistingDepartments, label]);
        break;
      }
      default:
        throw new Error(`Dropdown category with value ${DropdownCategory[category]} does not exist as onboarding option`);
    }
  }

  async function onClickSubmit(e: MouseEvent) {
    setIsLoading(true);
    e.preventDefault();
    await updateScoopPreferences(
      await getAccessTokenSilently(),
      selfIndustry,
      companyUrl,
      targetLocation,
      targetIndustry,
      targetRoles,
      targetSize,
      articleTargetIndustry,
      joblistingLocation || undefined,
      joblistingIndustry || undefined,
      joblistingCustomRoles.length
        ? joblistingCustomRoles.filter((role) => !joblistingDepartments.map((d) => capitalizeAllWords(d)).includes(role))
        : undefined, //due to the generic dropdownInput and separation of custom roles and department
      joblistingDepartments || undefined
    );
    onSubmit(e);
    setIsLoading(false);
  }

  const formFieldClassName =
    "w-3/4 min-w-[250px] max-w-[424px] h-[36px] text-[14px] leading-8 rounded-lg my-2 border-[0.2px] border-gray-100 bg-transparent focus:outline-none placeholder-gray ";
  const inputDropdownClassName =
    "w-3/4 min-w-[250px] max-w-[424px] min-h-[36px] text-[14px] leading-8 rounded-lg my-2 border-[0.2px] border-gray-100 bg-transparent focus:outline-none placeholder-gray ";
  return (
    <div className={`   w-full mt-10 content-center center items-center grid ${className}`}>
      {!newsFlowOptions.data || !prospectRolesOptions.data || isLoading ? (
        <Loader></Loader>
      ) : (
        <div className="mb-[136px]">
          <div className="grid grid-cols-2 gap-y-6 gap-x-8 mb-10 ">
            <div>
              <div className="mb-8">{getSelfDescriptionForm()}</div>
              <div className="mb-8">{getTargetCustomerForm()}</div>
              <div className="mb-8">{getPressReleaseSelection()}</div>
            </div>
            <div>{getJoblistingForm()}</div>
          </div>
        </div>
      )}
      <Button className=" absolute bottom-4 mt-2   inset-x-[42%] w-24 mb-4" onClick={onClickSubmit}>
        Save
      </Button>
    </div>
  );

  function getSelfDescriptionForm() {
    return (
      <div>
        <div className="flex-col ">
          <div className=" mb-4 text-lg font-semibold  ">Company information</div>
        </div>
        <GenericForm
          className={" "}
          fields={[
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toDropdownItems(onboardingOptions.data?.self_industry, DropdownCategory.SELF_INDUSTRY),
              selected: selfIndustry.length ? toDropdownItems([selfIndustry], DropdownCategory.SELF_INDUSTRY) : [],
              placeholder: "What does your company do?",
              name: "self_industry"
            } as FormDropdown,
            {
              type: "input",
              className:
                formFieldClassName + " pl-2 resize-none overscroll-y-none whitespace-nowrap no-scrollbar overflow-y-none focus:placeholder-opacity-0",
              onChange: (val: string) => setCompanyUrl(val),
              value: companyUrl,
              name: "self_company_website",
              placeholder: "Company Website URL"
            } as FormInput
          ]}
          dropdownTrigger={<Polygon></Polygon>}></GenericForm>
      </div>
    );
  }

  function getTargetCustomerForm() {
    return (
      <div>
        <div className="flex-col ">
          <div className="mb-4 text-lg font-semibold  ">My target market</div>
        </div>
        <GenericForm
          className={" "}
          fields={[
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toDropdownItems(onboardingOptions.data?.target_location, DropdownCategory.LOCATION),
              selected: targetLocation ? toDropdownItems([targetLocation], DropdownCategory.LOCATION) : null,
              placeholder: "Location",
              name: "target_location"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toCheckboxDropdownItems(onboardingOptions.data?.target_industry, DropdownCategory.TARGET_INDUSTRY),
              selected: toCheckboxDropdownItems(targetIndustry, DropdownCategory.TARGET_INDUSTRY),
              placeholder: "Industry",
              isMulti: true,
              selectAllOption: true,
              name: "target_industry"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toCheckboxDropdownItems(onboardingOptions.data?.target_size, DropdownCategory.TARGET_SIZE),
              selected: toCheckboxDropdownItems(targetSize, DropdownCategory.TARGET_SIZE),
              isMulti: true,
              selectAllOption: true,
              placeholder: "Company size",
              name: "target_size"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName + " mb-0 ",
              options: toDropdownItems(onboardingOptions.data?.target_roles, DropdownCategory.TARGET_ROLES),
              selected: targetRoles ? toDropdownItems([targetRoles], DropdownCategory.TARGET_ROLES) : null,
              placeholder: "Department",
              name: "target_roles",
              description: "Select the department where your target customer works"
            } as FormDropdown
          ]}
          dropdownTrigger={<Polygon></Polygon>}></GenericForm>
      </div>
    );
  }

  function getPressReleaseSelection() {
    return (
      <div>
        <div className="flex-col  ">
          <div className=" mb-4 text-lg font-semibold ">News & press release settings</div>
        </div>
        <GenericForm
          className={""}
          fields={[
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toDropdownItems(onboardingOptions.data?.target_location, DropdownCategory.ARTICLE_LOCATION),
              selected: articleLocation ? toDropdownItems([articleLocation], DropdownCategory.ARTICLE_LOCATION) : null,
              placeholder: "Location",
              name: "article_location"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toDropdownItems(onboardingOptions.data?.article_industries, DropdownCategory.ARTICLE_INDUSTRY),
              selected: articleTargetIndustry ? toDropdownItems([articleTargetIndustry], DropdownCategory.ARTICLE_INDUSTRY) : null,
              placeholder: "Industry",
              name: "article_industry"
            } as FormDropdown
          ]}
          dropdownTrigger={<Polygon></Polygon>}></GenericForm>
      </div>
    );
  }

  function getJoblistingForm() {
    return (
      <div>
        <div className="flex-col content-center ">
          <div className=" mb-4 text-lg font-semibold  ">Job listing settings</div>
        </div>
        <GenericForm
          className={""}
          fields={[
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toDropdownItems(onboardingOptions.data?.joblisting_locations, DropdownCategory.JOBLISTING_LOCATION),
              selected: joblistingLocation ? toDropdownItems([joblistingLocation], DropdownCategory.JOBLISTING_LOCATION) : null,
              placeholder: "Location",
              name: "target_location"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toCheckboxDropdownItems(onboardingOptions.data?.target_industry, DropdownCategory.JOBLISTING_INDUSTRY),
              selected: joblistingIndustry ? toDropdownItems(joblistingIndustry, DropdownCategory.JOBLISTING_INDUSTRY) : null,
              placeholder: "Industry",
              isMulti: true,
              selectAllOption: true,
              name: "target_industry"
            } as FormDropdown,
            {
              type: "dropdown_input",
              className: inputDropdownClassName,
              options: toCheckboxDropdownItems(onboardingOptions.data?.joblisting_roles, DropdownCategory.JOBLISTING_ROLES),
              selectedInputs: joblistingCustomRoles,
              selectedDropdownItems: toCheckboxDropdownItems(joblistingDepartments, DropdownCategory.JOBLISTING_ROLES),
              setExternalState: setJoblistingTargetRoles,
              placeholder: "Roles",
              name: "target_roles"
            } as FormDropdownInput
          ]}
          dropdownTrigger={<Polygon></Polygon>}></GenericForm>
      </div>
    );
  }
}
