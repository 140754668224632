import { Dispatch } from "react";
import Card from "../Card";
import { ReactComponent as Copy } from "../../icons/copy.svg";
import { ReactComponent as Magic } from "../../icons/magic.svg";
import "./EmailInputEditor.scss";
import copy from "copy-to-clipboard";
import EmptyPageMessage from "../EmptyPageMessage";
import Loader from "../Loader";

interface Props {
  className?: string;
  setEmailInput: Dispatch<React.SetStateAction<string>>;
  emailInput: string;
  isLoading: boolean;
}

export default function EmailInputEditor({ className, setEmailInput, emailInput, isLoading }: Props) {
  return (
    <Card className={`px-8 py-8 ${className} shadow-none`}>{isLoading ? <Loader></Loader> : emailInput ? showEmailInput() : showEmptyMessage()}</Card>
  );

  function showEmptyMessage() {
    return (
      <div className="h-[100%] justify-center align-middle justify-items-center flex">
        <EmptyPageMessage className="" icon={Magic} message={"Fill out the form to the right to see your content show up here."}></EmptyPageMessage>
      </div>
    );
  }

  function showEmailInput() {
    return (
      <div className="h-full">
        <div className="EmailEditor-header">
          <label className=" font-bold text-[#6955FF]">Message</label>
          <button className="ml-7 IconBtn ContactDetails__copy shrink-0" onClick={() => copy(emailInput)}>
            <Copy />
          </button>
        </div>
        <textarea
          className="EmailEditor-input border-2 "
          name={"Email editor"}
          value={emailInput}
          onChange={(evt) => {
            setEmailInput(evt.target.value);
          }}></textarea>
      </div>
    );
  }
}
