import { useQueries, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../components/Button/Button";
import Card from "../components/Card";
import Table from "../components/Table";
import { deleteContact, getGroup, removeContactFromGroup } from "../core/api";
import { Contact } from "../core/types";
import PageWrapper from "../wrappers/PageWrapper";
import { ReactComponent as More } from "../icons/more.svg";
import { ReactComponent as Trash } from "../icons/trash.svg";
import { format } from "date-fns";
import Dropdown from "../components/Dropdown/Dropdown";
import { RoutesEnum } from "../core/enums";
import Loader from "../components/Loader";
import { downloadFile, normalizeDateFE, sortArrayOnCreated } from "../core/helpers";
import { ReactComponent as UsersIcon } from "../icons/users.svg";
import { generateCsv } from "./Contacts/Contacts.helpers";
import "./Group.scss";
import { useAuth0 } from "@auth0/auth0-react";
import Pagination from "../components/Pagination/Pagination";
import usePagination from "../components/Pagination/usePagination";
import CheckboxDropdown from "../components/CheckboxDropdown";
import Checkbox from "../components/Checkbox";
import classNames from "classnames";
import GetEmailComponent from "../components/ContactDetails/GetEmailComponent";

export default function Group() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [selected, setSelected] = useState<string[]>([]);
  const [exportType, setExportType] = useState<"currentPage" | "all" | undefined>("currentPage");

  const [group] = useQueries({
    queries: [
      {
        queryKey: ["Group", id],
        queryFn: async () => getGroup(id!, await getAccessTokenSilently()),
        initialData: undefined
      }
    ]
  });

  const onClickBack = () => {
    navigate(RoutesEnum.Contacts);
  };

  const contactsData = group.data?.group_contacts;
  const groupContacts = useMemo(() => contactsData || [], [contactsData, id]);
  const { currentPage, setCurrentPage, pages, currentPageData } = usePagination(sortArrayOnCreated(groupContacts, "ascending"));

  useEffect(() => {
    setExportType(undefined);
    setSelected([]);
  }, [currentPage]);

  if (group.isLoading) return <Loader />;

  const onClickSelectAll = () => {
    setExportType("all");
    setSelected(groupContacts.map((c) => c.uuid));
  };

  const onClickSelectCurrentPageContacts = () => {
    setExportType("currentPage");
    setSelected(currentPageData.map((c) => c.uuid));
  };

  const isExportSelectChecked = () => {
    if (exportType === "all") return selected.length === groupContacts.length;
    if (exportType === "currentPage") return selected.length === currentPageData.length;
    return false;
  };

  return (
    <PageWrapper
      data-testid="Group"
      title={group.data?.name || ""}
      btn={
        <Button
          data-testid="Export-btn"
          disabled={!selected.length}
          onClick={() => {
            downloadFile(generateCsv(groupContacts.filter((c) => selected.includes(c.uuid))), `group-${group.data?.name}-contacts.csv`);
          }}>
          Export
        </Button>
      }
      onClickBack={onClickBack}>
      <Card className="grow pb-5 flex flex-col">
        {isLoading && <Loader />}
        <Table<Contact>
          className="grow Table--Contacts flex flex-col"
          columns={[
            {
              size: "minmax(150px, 1fr)",
              label: "Name",
              render: (r) => (
                <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Name-${r.uuid}`}>
                  <img
                    src={r.image_url}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = process.env.PUBLIC_URL + "/images/default_profile_image.png";
                    }}
                    className="w-7 h-7 rounded-full"
                  />
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline ml-[6px] Contacts__name">
                    {r.first_name} {r.last_name}
                  </span>
                </div>
              )
            },
            {
              label: "Company",
              render: (r) => (
                <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Company-${r.uuid}`}>
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.company}</span>
                </div>
              )
            },
            {
              label: "Job Title",
              render: (r) => (
                <div className="text-[11px] leading-[13px]  text-left flex items-center h-full" data-testid={`Job Title-${r.uuid}`}>
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.position}</span>
                </div>
              )
            },
            {
              label: "Email",
              render: (r) => (
                <div
                  className="text-[11px] leading-[13px] flex  items-center h-full truncate"
                  data-testid={`Email-${r.uuid}`}
                  onClick={(e) => e.stopPropagation()}>
                  <GetEmailComponent
                    className={" truncate align-middle"}
                    email={r.email}
                    isSaved={true}
                    emailStatus={r.email_status || null}
                    contactUuid={r.uuid}
                    styling="table"></GetEmailComponent>
                </div>
              )
            },
            {
              label: "Added Date",
              render: (r) => (
                <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Added Date-${r.uuid}`}>
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">
                    {format(normalizeDateFE(r.created_at), "dd MMM. yyyy")}
                  </span>
                </div>
              )
            },
            {
              label: "Linkedin",
              render: (r) => (
                <div className="text-[11px] leading-[13px] flex text-left items-center h-full" data-testid={`Linkedin-${r.uuid}`}>
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.linkedin_url}</span>
                </div>
              )
            },
            {
              label: "Actions",
              empty: true,
              size: "36px",
              render: (r) => (
                <div
                  className="text-[11px] leading-[13px] flex items-center justify-end h-full cursor-default"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <Dropdown
                    data-testid={`contact-more-btn-${r.uuid}`}
                    className="Dropdown--Group"
                    trigger={<More stroke="black" />}
                    items={[
                      {
                        label: "Delete",
                        onClick: async () => {
                          setIsLoading(true);
                          deleteContact(r.uuid, await getAccessTokenSilently()).then(() => {
                            setIsLoading(false);
                            queryClient.setQueryData<unknown | undefined>(["Group", id], () => {
                              if (!group.data) {
                                return;
                              }
                              group.data.group_contacts = group.data.group_contacts?.filter((c) => c.uuid !== r.uuid);
                              return group.data;
                            });
                          });
                        },
                        icon: <Trash />
                      },
                      {
                        label: "Remove from group",
                        onClick: async () => {
                          setIsLoading(true);
                          //TODO: update this with a proper remove from group function when we implement several groups for contacts
                          removeContactFromGroup(r.uuid, id!, await getAccessTokenSilently()).then(() => {
                            setIsLoading(false);
                            queryClient.setQueryData<unknown | undefined>(["Group", id], () => {
                              if (!group.data) {
                                return;
                              }
                              group.data.group_contacts = group.data.group_contacts?.filter((c) => c.uuid !== r.uuid);
                              return group.data;
                            });
                          });
                        },
                        icon: <UsersIcon />
                      }
                    ]}
                  />
                </div>
              )
            },
            {
              label: "Select",
              empty: true,
              size: "33px",

              render: (c) => (
                <div
                  className="flex items-center justify-center h-full cursor-default"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <Checkbox
                    onChange={(e) => {
                      setSelected((s) => (e.target.checked ? [...s, c.uuid] : s.filter((uuid) => uuid !== c.uuid)));
                    }}
                    checked={selected.includes(c.uuid)}
                    data-testid={`contact-checkbox-${c.uuid}`}
                  />
                </div>
              ),
              btn: (
                <div>
                  <CheckboxDropdown
                    onChange={(e) => {
                      if (e.target.checked) {
                        onClickSelectCurrentPageContacts();
                      } else {
                        setExportType(undefined);
                        setSelected([]);
                      }
                    }}
                    checked={isExportSelectChecked()}
                    items={[
                      {
                        className: classNames({
                          "Dropdown__item--active": exportType === "currentPage"
                        }),
                        label: `Select this page (${currentPageData.length})`,
                        onClick: onClickSelectCurrentPageContacts,
                        testId: "export-page"
                      },
                      {
                        className: classNames({
                          "Dropdown__item--active": exportType === "all"
                        }),
                        label: `Select all people (${groupContacts.length})`,
                        onClick: onClickSelectAll,
                        testId: "export-all"
                      }
                    ]}
                  />
                </div>
              )
            }
          ]}
          rows={currentPageData}
        />
        <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </Card>
    </PageWrapper>
  );
}
