import { useAuth0 } from "@auth0/auth0-react";
import { useQueries } from "@tanstack/react-query";
import { getNewsFlowOptions, getOnboardingOptionsForOrg, getProspectOptions, updateScoopPreferences } from "../../core/api";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import { ReactComponent as SrLogo } from "../../icons/logo.svg";
import { MouseEvent, useState } from "react";
import Loader from "../../components/Loader";
import { DropdownItem } from "../../components/Dropdown/Dropdown";
import { Button } from "../../components/Button/Button";
import { User } from "../../core/types";
import { capitalizeAllWords } from "../../core/helpers";
import FormDropdown from "../../components/Form/FormDropdownInterface";
import FormInput from "../../components/Form/FormInputInterface";
import GenericForm from "../../components/Form/GenericForm";
import NewsPrefCard from "../../components/NewsFlow/NewsPrefCard";
import FormDropdownInput from "../../components/Form/FormDropdownInputInterface";

interface Props {
  onSubmit: React.MouseEventHandler<Element>;
  user?: User;
  className?: string;
}

enum DropdownCategory {
  SELF_INDUSTRY,
  LINKEDIN_URL,
  LOCATION,
  TARGET_SIZE,
  TARGET_INDUSTRY,
  TARGET_ROLES,
  JOBLISTING_INDUSTRY,
  JOBLISTING_ROLES,
  JOBLISTING_LOCATION
}

export default function NewsFlowOnboarding({ onSubmit, user, className }: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const [selfIndustry, setSelfIndustry] = useState<string>("");
  const [companyUrl, setCompanyUrl] = useState<string>("");
  const [targetLocation, setTargetLocation] = useState<string>("");
  const [targetIndustry, setTargetIndustry] = useState<string[]>([]);
  const [targetSize, setTargetSize] = useState<string[]>([]);
  const [targetRoles, setTargetRoles] = useState<string>("");
  const [newsTargetIndustry, setNewsTargetIndustry] = useState<string>("");
  const [joblistingIndustry, setJoblistingIndustry] = useState<string[]>([]);
  const [joblistingCustomRoles, setJoblistingTargetRoles] = useState<string[]>([]);
  const [joblistingDepartments, setJoblistingDepartments] = useState<string[]>([]);
  const [joblistingLocation, setJoblistingLocation] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState(false);
  const [newsFlowOptions, prospectRolesOptions, onboardingOptions] = useQueries({
    queries: [
      {
        queryKey: ["NewsFlowPrefs"],
        queryFn: async () => await getNewsFlowOptions(await getAccessTokenSilently())
      },
      { queryKey: ["ProspectRoles"], queryFn: async () => await getProspectOptions(await getAccessTokenSilently()) },
      { queryKey: ["OnboardingOptions"], queryFn: async () => await getOnboardingOptionsForOrg(await getAccessTokenSilently()) }
    ]
  });

  function toDropdownItems(items: string[], populateArray: DropdownCategory): DropdownItem[] {
    return items?.map((label) => {
      return {
        onClick: () => addToPreferences(label, populateArray),
        label:
          label === "C_SUITE"
            ? "C-Suite"
            : label === "B2B Services"
            ? "B2B Services"
            : capitalizeAllWords(label.replaceAll("industry_", "").replaceAll("_", " "))
      } as DropdownItem;
    });
  }

  function toCheckboxDropdownItems(items: string[], populateArray: DropdownCategory): DropdownItem[] {
    return items?.map((label) => {
      return {
        checkboxOnChange: () => addToPreferences(label, populateArray),
        label: label === "C_SUITE" ? "C-Suite" : label === "B2B Services" ? "B2B Services" : capitalizeAllWords(label.replaceAll("_", " "))
      } as DropdownItem;
    });
  }

  async function addToPreferences(label: string, category: DropdownCategory) {
    switch (category) {
      case DropdownCategory.LOCATION: {
        setTargetLocation(label);
        break;
      }
      case DropdownCategory.TARGET_SIZE: {
        targetSize.includes(label)
          ? setTargetSize((targetSize) => targetSize.filter((size) => size !== label))
          : setTargetSize((targetSize) => [...targetSize, label]);
        break;
      }
      case DropdownCategory.TARGET_INDUSTRY: {
        targetIndustry.includes(label)
          ? setTargetIndustry((targetIndustry) => targetIndustry.filter((industry) => industry !== label))
          : setTargetIndustry((targetIndustry) => [...targetIndustry, label]);
        break;
      }
      case DropdownCategory.TARGET_ROLES: {
        setTargetRoles(label);
        break;
      }
      case DropdownCategory.SELF_INDUSTRY: {
        setSelfIndustry(label);
        break;
      }
      case DropdownCategory.JOBLISTING_LOCATION: {
        setJoblistingLocation(label);
        break;
      }
      case DropdownCategory.JOBLISTING_INDUSTRY: {
        joblistingIndustry.includes(label)
          ? setJoblistingIndustry((joblistingIndustry) => joblistingIndustry.filter((industry) => industry !== label))
          : setJoblistingIndustry((joblistingIndustry) => [...joblistingIndustry, label]);
        break;
      }
      case DropdownCategory.JOBLISTING_ROLES: {
        joblistingDepartments.includes(label)
          ? setJoblistingDepartments((joblistingDepartments) => joblistingDepartments.filter((industry) => industry !== label))
          : setJoblistingDepartments((joblistingDepartments) => [...joblistingDepartments, label]);
        break;
      }
      default:
        throw new Error(`Dropdown category with value ${DropdownCategory[category]} does not exist as onboarding option`);
    }
  }

  async function onClickSubmit(e: MouseEvent) {
    setIsLoading(true);
    e.preventDefault();
    await updateScoopPreferences(
      await getAccessTokenSilently(),
      selfIndustry,
      companyUrl,
      targetLocation,
      targetIndustry,
      targetRoles,
      targetSize,
      newsTargetIndustry,
      joblistingLocation,
      joblistingIndustry,
      joblistingCustomRoles.filter((role) => !joblistingDepartments.map((d) => capitalizeAllWords(d)).includes(role)), //due to the generic dropdowninput and separation of custom roles and department
      joblistingDepartments
    );
    onSubmit(e);
    setIsLoading(false);
  }

  async function onClickSkipAndSubmit(e: MouseEvent) {
    setIsLoading(true);
    e.preventDefault();
    await updateScoopPreferences(
      await getAccessTokenSilently(),
      selfIndustry,
      companyUrl,
      targetLocation,
      targetIndustry,
      targetRoles,
      targetSize,
      newsTargetIndustry
    );
    onSubmit(e);
    setIsLoading(false);
  }

  const formFieldClassName =
    "w-[424px] max-w-[424px] min-w-[424px] h-[36px] text-[14px] leading-8 rounded-lg my-2 border-[0.2px] border-gray-100 bg-transparent focus:outline-none placeholder-gray ";
  const inputDropdownClassName =
    "w-[424px] max-w-[424px] min-w-[424px] min-h-[36px] text-[14px] leading-8 rounded-lg my-2 border-[0.2px] border-gray-100 bg-transparent focus:outline-none placeholder-gray ";
  return (
    <div className={`self-center grid place-items-center w-full mt-10  ${className}`}>
      <div>
        <SrLogo className="w-44"></SrLogo>
      </div>
      {!newsFlowOptions.data || !prospectRolesOptions.data || isLoading ? (
        <Loader></Loader>
      ) : page === 1 ? (
        getSelfDescriptionForm()
      ) : page === 2 ? (
        getTargetCustomerForm()
      ) : page === 3 ? (
        getPressReleaseSelection()
      ) : page === 4 && user?.organization?.name === "Sharpreach" ? (
        getWebsiteVisitScript(1234567890)
      ) : (
        getJoblistingForm()
      )}
    </div>
  );

  function getSelfDescriptionForm() {
    return (
      <div>
        <div className="flex-col content-center ">
          <div className=" mb-4 text-3xl font-semibold text-center ">Tell us about your company!</div>
          <p className="mb-4 text-xs text-gray text-center w-full  ">
            This will help us learn about your company and give you better recommendations.
          </p>
        </div>
        <GenericForm
          className={" justify-center flex   content-center"}
          fields={[
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toDropdownItems(onboardingOptions.data?.self_industry, DropdownCategory.SELF_INDUSTRY),
              selected: selfIndustry.length ? toDropdownItems([selfIndustry], DropdownCategory.SELF_INDUSTRY) : [],
              placeholder: "What does your company do?",
              name: "self_industry"
            } as FormDropdown,
            {
              type: "input",
              className:
                formFieldClassName + " pl-2 resize-none overscroll-y-none whitespace-nowrap no-scrollbar overflow-y-none focus:placeholder-opacity-0",
              onChange: (val: string) => setCompanyUrl(val),
              value: companyUrl,
              name: "self_company_url",
              placeholder: "Company Website URL"
            } as FormInput
          ]}
          button={
            <div className="w-full flex justify-center">
              <Button
                type="submit"
                onClick={onClickNextSelf}
                disabled={!companyUrl || !selfIndustry}
                className="mt-4 w-[424px] max-w-[424px] min-w-[424px]   ">
                Next
              </Button>
            </div>
          }
          dropdownTrigger={<Polygon></Polygon>}></GenericForm>
      </div>
    );
  }

  function onClickNextSelf() {
    if (companyUrl && selfIndustry) {
      setPage(2);
    }
  }

  function getTargetCustomerForm() {
    return (
      <div>
        <div className="flex-col content-center ">
          <div className=" mb-4 text-3xl font-semibold text-center w-full ">Tell us about your target customers</div>
          <p className="mb-4 text-xs text-gray text-center w-full  ">You can always change this later in your settings.</p>
        </div>
        <GenericForm
          className={" justify-center flex   content-center"}
          fields={[
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toDropdownItems(onboardingOptions.data?.target_location, DropdownCategory.LOCATION),
              selected: targetLocation ? toDropdownItems([targetLocation], DropdownCategory.LOCATION) : null,
              placeholder: "Location",
              name: "target_location"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toCheckboxDropdownItems(onboardingOptions.data?.target_industry, DropdownCategory.TARGET_INDUSTRY),
              selected: toCheckboxDropdownItems(targetIndustry, DropdownCategory.TARGET_INDUSTRY),
              placeholder: "Industry",
              isMulti: true,
              selectAllOption: true,
              name: "target_industry"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toCheckboxDropdownItems(onboardingOptions.data?.target_size, DropdownCategory.TARGET_SIZE),
              selected: toCheckboxDropdownItems(targetSize, DropdownCategory.TARGET_SIZE),
              defaultValue: user?.organization?.target_size[0],
              isMulti: true,
              selectAllOption: true,
              placeholder: "Company size",
              name: "target_size"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName + " mb-0 ",
              options: toDropdownItems(onboardingOptions.data?.target_roles, DropdownCategory.TARGET_ROLES),
              selected: targetRoles ? toDropdownItems([targetRoles], DropdownCategory.TARGET_ROLES) : null,
              placeholder: "Department",
              name: "target_roles",
              description: "Select the department where your target customer works"
            } as FormDropdown
          ]}
          button={
            <div className="w-full flex justify-center">
              <Button
                type="submit"
                onClick={onClickNextTarget}
                disabled={!targetIndustry.length || !targetSize.length || !targetLocation || !targetRoles}
                className="mt-4 w-[424px] max-w-[424px] min-w-[424px]   ">
                Next
              </Button>
            </div>
          }
          dropdownTrigger={<Polygon></Polygon>}></GenericForm>
        {goBackButton()}
      </div>
    );
  }

  function onClickNextTarget() {
    if (targetIndustry && targetSize && targetLocation && targetRoles) {
      setPage(3);
    }
  }

  function goBackButton() {
    return (
      <div className="w-full mt-2 flex justify-center">
        <Button type="submit" styling={"outline-purple"} onClick={() => setPage(page - 1)} className=" w-[424px] max-w-[424px] min-w-[424px]   ">
          Back
        </Button>
      </div>
    );
  }

  function getPressReleaseSelection() {
    return (
      <div>
        <div className="flex-col content-center ">
          <div className=" mb-2 text-3xl font-semibold text-center">News & Press Releases</div>
          <p className="mb-4 text-xs text-gray text-center w-full  ">Please select an industry you want to monitor.</p>
        </div>
        <div className="grid grid-cols-2 gap-y-6 gap-x-8">
          {onboardingOptions.data?.article_industries.map((industry: string) => getPressReleaseCard(industry))}
        </div>
        {
          <div className="w-full mt-10 flex justify-center">
            <Button
              type="submit"
              onClick={onClickNextPressRelease}
              disabled={!newsTargetIndustry}
              className=" w-[424px] max-w-[424px] min-w-[424px]   ">
              Next
            </Button>
          </div>
        }
        {goBackButton()}
      </div>
    );
  }

  function getPressReleaseCard(label: string) {
    return (
      <NewsPrefCard
        key={label + "-press_card"}
        onClick={() => setNewsTargetIndustry(label)}
        label={capitalizeAllWords(label.replace("industry_", "").replaceAll("_", " "))}
        selected={newsTargetIndustry === label}></NewsPrefCard>
    );
  }

  function onClickNextPressRelease() {
    if (newsTargetIndustry) {
      setPage(4);
    }
  }

  function getJoblistingForm() {
    return (
      <div>
        <div className="flex-col content-center ">
          <div className=" mb-4 text-3xl font-semibold text-center w-full ">Job listings</div>
          <p className="mb-4 text-xs text-gray text-center w-full  ">You can always change this later in your settings.</p>
        </div>
        <GenericForm
          className={" justify-center flex   content-center"}
          fields={[
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toDropdownItems(onboardingOptions.data?.joblisting_locations, DropdownCategory.JOBLISTING_LOCATION),
              selected: joblistingLocation ? toDropdownItems([joblistingLocation], DropdownCategory.JOBLISTING_LOCATION) : null,
              placeholder: "Location",
              name: "target_location"
            } as FormDropdown,
            {
              type: "dropdown",
              className: formFieldClassName,
              options: toCheckboxDropdownItems(onboardingOptions.data?.target_industry, DropdownCategory.JOBLISTING_INDUSTRY),
              selected: toDropdownItems(joblistingIndustry, DropdownCategory.JOBLISTING_INDUSTRY),
              placeholder: "Industry",
              isMulti: true,
              selectAllOption: true,
              name: "target_industry"
            } as FormDropdown,
            {
              type: "dropdown_input",
              className: inputDropdownClassName,
              options: toCheckboxDropdownItems(onboardingOptions.data?.joblisting_roles, DropdownCategory.JOBLISTING_ROLES),
              selectedInputs: joblistingCustomRoles,
              selectedDropdownItems: toCheckboxDropdownItems(joblistingDepartments, DropdownCategory.JOBLISTING_ROLES),
              setExternalState: setJoblistingTargetRoles,
              placeholder: "Roles",
              name: "target_roles"
            } as FormDropdownInput
          ]}
          button={getSubmitButton()}
          dropdownTrigger={<Polygon></Polygon>}
          skipLink={
            <div className=" w-full content-end mt-2" style={{ textAlign: "right" }}>
              <a className=" underline text-lightBlue-700 text-xs cursor-pointer" onClick={(e) => onClickSkipAndSubmit(e)}>
                Skip & Submit
              </a>
            </div>
          }></GenericForm>
        {goBackButton()}
      </div>
    );
  }

  function getSubmitButton(): JSX.Element | undefined {
    return (
      <div className="w-full flex justify-center">
        <Button
          type="submit"
          onClick={onClickSubmit}
          disabled={!joblistingIndustry.length || !joblistingLocation || (!joblistingDepartments.length && !joblistingCustomRoles.length)}
          className="mt-1 w-[424px] max-w-[424px] min-w-[424px]   ">
          Submit
        </Button>
      </div>
    );
  }

  function getWebsiteVisitScript(visitId: number) {
    return (
      <div>
        <div className="flex-col content-center ">
          <div className=" mb-4 text-3xl font-semibold text-center w-full ">Job listings</div>
          <p className="mb-4 text-xs text-gray text-center w-full  ">You can always change this later in your settings.</p>
        </div>
        <div className=" w-[700px] h-[350px] resize-none overflow-auto">
          <pre className="bg-gray p-4 resize-none rounded-lg whitespace-pre-wrap">
            <code className="language-html line-numbers  text-white" data-prismjs-copy="Copy to clipboard">
              {`<script>(function() {function sendData(timeSpent, pagePath, userId) {fetch("https://localhost:1337/enrich/ip/get", {method: "POST",headers: {"Content-Type": "application/json"},body: JSON.stringify({timeSpent: timeSpent,pagePath: pagePath, userId: userId})}).then(response => response.json()).then(data => console.log("Success:", data)).catch((error) => console.error("Error:", error));}var startTime = new Date().getTime();var pagePath = window.location.pathname;window.addEventListener("beforeunload", function() {var endTime = new Date().getTime();var timeSpent = endTime - startTime;sendData(timeSpent, pagePath, ${visitId});});})();</script>`}
            </code>
          </pre>
        </div>
        {goBackButton()}
        {getSubmitButton()}
      </div>
    );
  }
}
