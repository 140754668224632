import { CompanyDomain, CompanyOfInterest, User } from "../../core/types";
import IconListButtons from "../Article/IconListButtons";
import { MentionsList } from "../Article/MentionsList";
import { capitalizeAllWords } from "../../core/helpers";
import OwnedByComponent from "../ContactDetails/OwnedByComponent";
import { setCompanySuggestionOwner } from "../../core/api";
import { useRef, useState, useEffect } from "react";
interface Props {
  data: CompanyOfInterest;
  className?: string;
  orgMembers: User[];
  onOwnershipChange?: (ownerUuid: string, targetUuid: string) => void;
  onClickSave?: (uuid: string, isSaving: any, setIsSaving: any) => Promise<void>;
  onClickCompanyIcon?: (companyInfo: CompanyDomain) => void;
}

export default function CompanyOfInterestCard({ data, className = "", orgMembers, onOwnershipChange, onClickCompanyIcon }: Props) {
  const titleRef = useRef<HTMLParagraphElement>(null);
  const [maxWidth, setMaxWidth] = useState<string>("none");

  useEffect(() => {
    const calculateMaxWidth = () => {
      if (titleRef.current && titleRef.current.parentNode instanceof HTMLElement) {
        const availableWidth = titleRef.current.parentNode?.clientWidth || 0;
        setMaxWidth(`${availableWidth - 180}px`);
      }
    };

    calculateMaxWidth();
    window.addEventListener("resize", calculateMaxWidth);

    return () => {
      window.removeEventListener("resize", calculateMaxWidth);
    };
  }, []);

  return (
    <div
      className={`bg-white py-2 pl-4 pr-3  flex text-[15px] leading-[18px] font-medium rounded-[10px] align-top  ${className}`}
      data-testid={`article-${data.uuid}`}>
      <div className=" h-fit pt-2 mt-0 w-full">
        <div className="pr-2 text-lg w-full flex items-center text-center">
          <IconListButtons
            data={[{ logo_url: data.company.logo_url, name: data.company.name, domain: data.company.domain }]}
            onClickButton={onClickCompanyIcon}
            className="my-2 mr-2"></IconListButtons>
          <p ref={titleRef} className="truncate flex-shrink" style={{ maxWidth }}>
            {capitalizeAllWords(data.company.name)}
          </p>
          <div className="ml-auto flex items-center">
            <div className="mr-2">
              <OwnedByComponent
                onChange={onOwnershipChange}
                orgMembers={orgMembers}
                style="scoop"
                className=""
                setNewOwnerApiCall={setCompanySuggestionOwner}
                ownerEmail={data.owner?.email}
                targetUuid={data.uuid}></OwnedByComponent>
            </div>
          </div>
        </div>
        <div className="mx-[4px] items-start align-top pt-2 pr-2 text-sm text-gray">
          <div className="flex">
            {/* split at T because we have extensive datetime in BE */}
            <div className={` bg-indigo-400 mr-2 px-2 text-white rounded-lg w-fit`}>{data.created_at.split("T")[0]}</div>
            <div className={` bg-gray-100 px-2 text-white rounded-lg w-fit`}>
              {data.description.includes("Unique pages Visited:") ? "Website Visit" : "Recommended company"}
            </div>
          </div>
          <div className="max-h-[150px] overflow-clip ">
            {!data.description.includes("Unique pages Visited:") && <p className="mt-4 mr-0 text-base text-black">🚀 Description:</p>}
            <p className="mt-2 mr-0" style={{ whiteSpace: "pre-line" }}>
              {data.description}
            </p>
          </div>
          <div className="flex my-3">
            <p className=" text-black">HQ:</p>
            <div className={` bg-indigo-400 h-fit px-1 ml-1 text-white rounded-lg w-fit`}>
              {data.company.city ? `${data.company.city}, ${data.company.country}` : `${data.company.country}`}
            </div>
          </div>
          <div className="flex my-3">
            <p className=" text-black">Employee Count:</p>
            <div className={` bg-indigo-400 h-fit px-1 ml-1 text-white rounded-lg w-fit`}>{data.company.employee_count}</div>
          </div>
          <div className="flex my-3">
            <p className=" text-black">Annual Revenue:</p>
            <div className={` bg-indigo-400 h-fit px-1 ml-1 text-white rounded-lg w-fit`}>
              {data.company.annual_revenue ? data.company.annual_revenue : "Unknown"}
            </div>
          </div>
          {data.mentions && data.mentions.length > 0 && <MentionsList mentions={data.mentions} className={"mb-2 mt-3"}></MentionsList>}
        </div>
      </div>
    </div>
  );
}
