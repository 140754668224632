import { Company } from "../../core/types";
import phone from "../../icons/social/phone.svg";
import { ReactComponent as Checkmark } from "../../icons/checkmark_circle.svg";
import { Button } from "../Button/Button";
import { useState } from "react";
import MiniLoader from "../MiniLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { saveCompanyForOrganization } from "../../core/api";
import SocialMediaButtons from "../SocialMediaButtons";
import mixpanel from "mixpanel-browser";

interface Props {
  company: Company;
  isSaved?: boolean;
  onSave?: () => Promise<void>;
  className?: string;
}

export default function Header({ company, isSaved, onSave, className }: Props) {
  const [isSaving, setIsSaving] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const onClickSave = async (isSaving: boolean, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    mixpanel.track("save_action", { entity: "company", from: "company_info" });
    await saveCompanyForOrganization(company.uuid, await getAccessTokenSilently());
    onSave ? await onSave() : null;
    setIsSaving(false);
  };

  return (
    <div className={`bg-white text-[15px] font-medium rounded-[10px] pb-4 ${!className ? "pt-10" : ""} ${className}`}>
      <div className="flex justify-center">
        {onSave ? (
          <Button className=" py-0 px-2 h-8 mt-4 mr-5 absolute right-2 " styling="save" onClick={() => onClickSave(isSaving, setIsSaving)}>
            {isSaving ? (
              <MiniLoader></MiniLoader>
            ) : isSaved! ? (
              <>
                <Checkmark className="w-3 h-3 my-2.5 mr-1"></Checkmark>
                {"Saved"}
              </>
            ) : (
              " + Save "
            )}
          </Button>
        ) : (
          <></>
        )}
        <div className="mt-10 flex">
          <img src={company.logo_url} className="w-16 h-16"></img>
          <h4 className="font-semibold text-2xl flex justify-center ml-2 mt-4">{company.name}</h4>
        </div>
      </div>
      <div className="flex justify-center items-center divide-x divide-warmGray-400 my-4 pt-3 ">
        <SocialMediaButtons
          className="mx-2"
          data={{ linkedin_url: company.linkedin_url, twitter_url: company.twitter_url, facebook_url: company.facebook_url }}></SocialMediaButtons>

        <div>
          {company.phone && (
            <a href={`tel:${company.phone?.trim()}`} className="whitespace-nowrap flex text-purple ml-2">
              <img className="mt-1 w-4 h-4 mx-1" src={phone}></img>
              {company.phone}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
