import { ChangeEventHandler, useEffect, useState } from "react";
import { ReactComponent as More } from "../../icons/more.svg";
import { ReactComponent as Trash } from "../../icons/trash.svg";
import { ReactComponent as Briefcase } from "../../icons/briefcase.svg";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { fetchSavedCompaniesForOrg, saveCompanyForOrganization, setCompanyOwner, getOrganizationMembers, getCompanyTags } from "../../core/api";
import "./SavedCompanies.scss";
import { useAuth0 } from "@auth0/auth0-react";
import Card from "../../components/Card";
import Dropdown, { DropdownItem } from "../../components/Dropdown/Dropdown";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { Company } from "../../core/types";
import PageWrapper from "../../wrappers/PageWrapper";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import CompanyInfoCompiled from "../../components/Company/CompanyInfoCompiled";
import { capitalizeAllWords } from "../../core/helpers";
import EmptyPageMessage from "../../components/EmptyPageMessage";
import OwnedByComponent from "../../components/ContactDetails/OwnedByComponent";
import Checkbox from "../../components/Checkbox";
import TagComponent from "../../components/Tag/TagComponent";

export default function SavedCompanies() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [ownerFilter, setOwnerFilter] = useState<string[]>([]);
  const [displayCompanies, setDisplayCompanies] = useState<Company[]>([]);
  const [allCompanies, setAllCompanies] = useState<Company[]>([]);
  const [companyTags, setCompanyTags] = useState<string[]>([]);
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const UNASSIGNED_OWNER_LABEL = "Unassigned";
  const UNASSIGNED_TAG_LABEL = "No Tag";

  const [companies, orgMembers] = useQueries({
    queries: [
      {
        queryKey: ["Companies"],
        queryFn: async () =>
          fetchSavedCompaniesForOrg(await getAccessTokenSilently()).then((res) => {
            setAllCompanies(res);
            setDisplayCompanies(res);
            return res;
          })
      },
      {
        queryKey: ["OrgMembers"],
        queryFn: async () => await getOrganizationMembers(await getAccessTokenSilently())
      },
      {
        queryKey: ["AvailableCompanyTags"],
        queryFn: async () =>
          await getCompanyTags(await getAccessTokenSilently()).then((res) => {
            setCompanyTags(res);
            return res;
          })
      }
    ]
  });

  useEffect(() => {
    if (!allCompanies) {
      setDisplayCompanies([]);
    }
    //get unassigned companies if checked;
    const companyData: Company[] = ownerFilter.includes(UNASSIGNED_OWNER_LABEL) ? allCompanies!.filter((c) => !c.assignees.length) : [];
    ownerFilter.length
      ? setDisplayCompanies([...companyData, ...allCompanies!.filter((c) => c.assignees[0] && ownerFilter.includes(c.assignees[0].email))])
      : setDisplayCompanies(allCompanies!);
  }, [ownerFilter]);

  useEffect(() => {
    if (!allCompanies) {
      setDisplayCompanies([]);
    }
    //get untagged companies if checked;
    const companyData: Company[] = tagFilter.includes(UNASSIGNED_TAG_LABEL) ? allCompanies!.filter((c) => !c.companyTags.length) : [];
    tagFilter.length
      ? setDisplayCompanies([...companyData, ...allCompanies!.filter((c) => c.companyTags.some((t) => tagFilter.includes(t)))])
      : setDisplayCompanies(allCompanies!);
  }, [tagFilter]);

  const onClickRow = (domain: string | number) => () => {
    if (selectedCompany) {
      setShouldUpdate(true);
    }
    setSelectedCompany(String(domain));
  };

  const ownersDropdownItems = () => {
    if (!orgMembers.data) {
      return [];
    }
    const dropdownItems = orgMembers.data
      .map((orgMember) => orgMember.email)
      .map((email) => {
        return getOwnerDropdownItem(email);
      });
    dropdownItems.push(getOwnerDropdownItem(UNASSIGNED_OWNER_LABEL));
    return dropdownItems;
  };

  function getOwnerDropdownItem(email: string): DropdownItem {
    return {
      label: email,
      checkBox: (
        <Checkbox
          checked={ownerFilter.includes(email)}
          onChange={() => {
            ownerFilter.includes(email) ? setOwnerFilter(ownerFilter.filter((e) => e !== email)) : setOwnerFilter([...ownerFilter, email]);
          }}></Checkbox>
      )
    } as DropdownItem;
  }

  const onOwnershipChange = (ownerUuid: string, companyUuid: string) => {
    const orgMember = orgMembers.data ? orgMembers.data.find((member) => member.uuid === ownerUuid) : null;
    setDisplayCompanies((savedCompanies) =>
      savedCompanies.map((company) => {
        if (company.uuid === companyUuid) {
          return { ...company, assignees: orgMember ? [orgMember] : [] };
        }
        return company;
      })
    );

    //make sure we update reference data for filtering
    setAllCompanies((allCompanies) =>
      allCompanies?.map((company) => {
        if (company.uuid === companyUuid) {
          return { ...company, assignees: orgMember ? [orgMember] : [] };
        }
        return company;
      })
    );
  };

  const getTagDropdownItems = () => {
    return companyTags.map((t) => {
      return {
        label: t,
        checkBox: (
          <Checkbox
            checked={tagFilter.includes(t)}
            onChange={() => {
              tagFilter.includes(t) ? setTagFilter(tagFilter.filter((e) => e !== t)) : setTagFilter([...tagFilter, t]);
            }}></Checkbox>
        )
      } as DropdownItem;
    });
  };

  const onTagChange = async (companyUuid: string, tagName: string) => {
    if (!companyTags.includes(tagName)) {
      setCompanyTags([...companyTags, tagName]);
    }

    setDisplayCompanies((savedCompanies) =>
      savedCompanies.map((c) => {
        if (c.uuid === companyUuid) {
          return { ...c, companyTags: c.companyTags.includes(tagName) ? c.companyTags.filter((t) => t !== tagName) : [...c.companyTags, tagName] };
        }
        return c;
      })
    );
    //make sure we update reference data for filtering
    setAllCompanies((allCompanies) =>
      allCompanies?.map((c) => {
        if (c.uuid === companyUuid) {
          return { ...c, companyTags: c.companyTags.includes(tagName) ? c.companyTags.filter((t) => t !== tagName) : [...c.companyTags, tagName] };
        }
        return c;
      })
    );
  };

  const onDeleteTag = (tagName: string) => {
    setDisplayCompanies((savedCompanies) =>
      savedCompanies.map((c) => {
        c.companyTags = c.companyTags.filter((t) => t !== tagName);
        return c;
      })
    );
    setCompanyTags((companyTags) => companyTags.filter((t) => t !== tagName));
  };

  const onSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const search = event.target.value;
    if (!search) {
      setDisplayCompanies(allCompanies);
    } else {
      setDisplayCompanies(allCompanies.filter((c) => c.name.toLowerCase().includes(search.toLowerCase())));
    }
  };

  if (companies.isLoading) return <Loader />;
  return (
    <PageWrapper
      data-testid="Companies"
      title={`${selectedCompany ? "Saved Companies" : ""}`}
      onClickBack={selectedCompany ? () => setSelectedCompany("") : undefined}>
      {selectedCompany ? (
        <>
          <CompanyInfoCompiled companyDomain={selectedCompany} shouldRefetch={shouldUpdate} setShouldRefetch={setShouldUpdate}></CompanyInfoCompiled>
        </>
      ) : allCompanies.length ? (
        getCompanyList()
      ) : (
        <EmptyPageMessage icon={Briefcase} message={"Companies you save will appear here"} className=" mt-[200px]"></EmptyPageMessage>
      )}
    </PageWrapper>
  );

  function getCompanyList() {
    return (
      <Card className="grow pb-4 flex flex-col Companies mt-5">
        {isLoading && <Loader />}

        <div className="grow">
          <Table<Company>
            className="grow Table__Companies flex flex-col"
            columns={[
              {
                size: "minmax(150px, 1fr)",
                label: "Name",
                render: (r) => (
                  <div className="text-[11px] leading-[13px] flex ml-[5px] items-center h-full" data-testid={`Name-${r.uuid}`}>
                    <img
                      src={r.logo_url}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = process.env.PUBLIC_URL + "/images/default_profile_image.png";
                      }}
                      className=" w-7 h-7 rounded-md"
                    />
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline ml-[6px] Companies__name">{r.name}</span>
                  </div>
                ),
                btn: (
                  <div>
                    <input
                      className="w-[110px]  border-gray-100 rounded-md pl-1 py-1 mt-[1px] focus:outline-none text-gray border-[0.5px] ml-1"
                      disabled={tagFilter.length > 0 || ownerFilter.length > 0}
                      placeholder="Search"
                      onChange={onSearch}></input>
                  </div>
                )
              },
              {
                label: "Industry",
                render: (r) => (
                  <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Company-${r.uuid}`}>
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">
                      {r.industry ? capitalizeAllWords(r.industry) : ""}
                    </span>
                  </div>
                )
              },
              {
                label: "Employees",
                render: (r) => (
                  <div className="text-[11px] leading-[13px] ml-1 flex items-center h-full" data-testid={`Job Title-${r.uuid}`}>
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.employee_count}</span>
                  </div>
                )
              },

              {
                label: "Tags",
                render: (r) => (
                  <div className={"text-[11px] leading-[13px] items-center flex  "} data-testid={`Tags-${r.uuid}`}>
                    <TagComponent
                      className=""
                      targetUuid={r.uuid}
                      possibleTags={companyTags || []}
                      currentTags={r.companyTags}
                      style={"profile"}
                      onTagUpdate={onTagChange}
                      onDelete={onDeleteTag}
                    />
                  </div>
                ),
                btn: <Dropdown trigger={<Polygon className="ml-1" />} items={getTagDropdownItems()}></Dropdown>
              },

              {
                label: "Owner",
                render: (r) => (
                  <div className={"text-[11px] leading-[13px] items-center flex w-full "} data-testid={`Owner-${r.uuid}`}>
                    <OwnedByComponent
                      className=""
                      style="minimized"
                      targetUuid={r.uuid}
                      ownerEmail={r.assignees[0]?.email || undefined}
                      orgMembers={orgMembers.data || []}
                      onChange={onOwnershipChange}
                      setNewOwnerApiCall={setCompanyOwner}></OwnedByComponent>
                  </div>
                ),
                btn: <Dropdown trigger={<Polygon className="ml-1" />} items={ownersDropdownItems()}></Dropdown>
              },
              {
                label: "Company Location",
                render: (r) => (
                  <div className="text-[11px] leading-[13px] ml-5 flex items-center h-full" data-testid={`Email-${r.uuid}`}>
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{`${r.country ? r.country + ", " : ""} ${
                      r.state ? r.state : ""
                    }`}</span>
                  </div>
                )
              },
              {
                label: "Actions",
                empty: true,
                size: "36px",
                render: (r) => (
                  <div
                    className="text-[11px] leading-[13px] flex items-center justify-end h-full cursor-default"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <Dropdown
                      data-testid={`company-more-btn-${r.uuid}`}
                      className="Dropdown__Companies"
                      trigger={<More stroke="black" />}
                      items={[
                        {
                          label: "Delete",
                          onClick: async () => {
                            setIsLoading(true);
                            saveCompanyForOrganization(r.uuid, await getAccessTokenSilently()).then(() => {
                              queryClient.setQueryData<Company[] | undefined>(["Companies"], (companies) => {
                                return companies?.filter((c) => c.uuid !== r.uuid);
                              });
                              setAllCompanies((allCompanies) => allCompanies.filter((c) => c.uuid !== r.uuid));
                              setDisplayCompanies((displayCompanies) => displayCompanies.filter((c) => c.uuid !== r.uuid));
                              setIsLoading(false);
                            });
                          },
                          icon: <Trash />
                        }
                      ]}></Dropdown>
                  </div>
                )
              }
            ]}
            rows={displayCompanies}
            onClickRow={onClickRow}
          />
        </div>
        <div className="pl-[18px] pr-[29px]"></div>
      </Card>
    );
  }
}
