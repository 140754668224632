import { DropdownItem } from "./Dropdown";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import { ReactComponent as Trash } from "../../icons/trash.svg";
import "./MultipleSelectDropdown.scss";
import { useEffect, useRef, useState } from "react";
import { capitalizeAllWords } from "../../core/helpers";

interface Props {
  displayText?: string;
  items: DropdownItem[];
  preSelected?: DropdownItem[];
  isSingleSelect?: boolean;
  className: string;
}

//OBS DEPRECATED!!
export default function MultipleSelectDropdown({ displayText, items, preSelected, isSingleSelect, className }: Props) {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValues, setSelectedValues] = useState<DropdownItem[]>(preSelected || []);
  const inputref = useRef<any>();

  useEffect(() => {
    const handler = (e: any) => {
      if (inputref.current && !inputref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = () => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValues || !selectedValues.length) {
      return displayText;
    }
    return (
      <div className={`dropdown-tags`}>
        {selectedValues.map((item: DropdownItem) => (
          <div key={item.label} className="dropdown-tag-item">
            {item.label}
            <Trash className=" text-blue " />
          </div>
        ))}
      </div>
    );
  };

  const removeItem = (item: DropdownItem) => {
    return selectedValues?.filter((i: DropdownItem) => i.label !== item.label);
  };

  const onItemClick = (item: DropdownItem) => {
    if (!selectedValues) {
      setSelectedValues([item]);
    }
    if (selectedValues && !isSelected(item)) {
      if (isSingleSelect) {
        setSelectedValues([item]);
        return;
      }

      selectedValues.push(item);
      setSelectedValues(selectedValues);
    } else if (selectedValues && isSelected(item)) {
      setSelectedValues(removeItem(item));
    }
  };

  const isSelected = (item: DropdownItem) => {
    if (!selectedValues) {
      return false;
    }
    return selectedValues.findIndex((i) => i.label === item.label) >= 0;
  };

  return (
    <div className={`dropdown-container ${className}`}>
      <div ref={inputref} onClick={() => handleInputClick()} className="dropdown-input">
        <div className="dropdown-selected-value text-gray text-center">{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Polygon />
          </div>
        </div>
      </div>
      {showMenu && <div className="dropdown-menu">{items.map((i) => getItemOptions(i, onItemClick, isSelected))}</div>}
    </div>
  );
}

function getItemOptions(i: DropdownItem, onItemClick: (item: DropdownItem) => void, isSelected: (item: DropdownItem) => boolean): JSX.Element {
  return (
    <div
      onClick={(e) => {
        i.onClick ? i.onClick(e) : undefined;
        onItemClick(i);
      }}
      key={i.label}
      className={`dropdown-item ${isSelected(i) && "selected"} px-2`}>
      {capitalizeAllWords(i.label)}
    </div>
  );
}
