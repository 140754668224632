export enum RoutesEnum {
  Group = "/contacts/group/:id",
  SingleContact = "/contacts/:id",
  Contacts = "/contacts",
  Settings = "/settings",
  BatchGenerate = "/batch-generate",
  Subscription = "/subscription",
  OneOffIcebreakers = "/one-off-icebreakers",
  NewBatch = "/new-batch",
  ResetPassword = "/reset-password",
  Scoops = "/scoops",
  CompanyInfo = "/companies/info/:companyDomain",
  CompanyLookup = "/companies/search",
  SavedCompanies = "/companies/saved",
  WebsiteVisits = "/website-visits",
  JobListings = "/job-listings"
}
