import { ReactComponent as UsersIcon } from "../../icons/users.svg";
import { Link } from "react-router-dom";
import { RoutesEnum } from "../../core/enums";
import Dropdown from "../../components/Dropdown/Dropdown";
import { ReactComponent as More } from "../../icons/more.svg";
import { ReactComponent as Trash } from "../../icons/trash.svg";
import { ReactComponent as Download } from "../../icons/download.svg";
import { ReactComponent as Edit } from "../../icons/edit.svg";
import { Group } from "../../core/types";

interface ContactsGroupProps {
  group: Group;
  onClickRename: () => void;
  onClickRemove: () => void;
  onClickDownload: () => void;
}

export default function ContactsGroup({ group, onClickRename, onClickRemove, onClickDownload }: ContactsGroupProps) {
  const { name, uuid: id } = group;

  return (
    <div className="flex items-center px-2">
      <Link
        data-testid={`link-group-${group.uuid}`}
        to={RoutesEnum.Group.replace(":id", String(id))}
        className="flex items-center IconBtn hover:text-purple transition duration-300 Contacts__btn text-[11px] leading-[13px] mr-1 w-fit">
        <UsersIcon className="w-6 h-6 shrink-0" />
        <span className="ml-[7px] text-ellipsis whitespace-nowrap">{name}</span>
      </Link>
      <Dropdown
        data-testid={`dropdown-group-${group.uuid}`}
        className="Dropdown--right--outside"
        trigger={<More stroke="black" />}
        items={[
          {
            label: "Rename",
            onClick: onClickRename,
            icon: <Edit />
          },
          {
            label: "Download (.csv)",
            onClick: onClickDownload,
            icon: <Download />
          },
          {
            label: "Delete",
            onClick: onClickRemove,
            icon: <Trash />
          }
        ]}
      />
    </div>
  );
}
