import { Button } from "../Button/Button";
import { ReactComponent as Envelope } from "../../icons/social/email_found.svg";
import { ReactComponent as EmailUnverified } from "../../icons/social/questionmark.svg";
import { ReactComponent as EmailVerified } from "../../icons/social/checkmark.svg";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Mention } from "../../core/types";
import { getEmailForSavedContact, getEmailFortUnsavedContact } from "../../core/api";
import { isEmail } from "../../core/helpers";
import copy from "copy-to-clipboard";
import Tooltip from "../Tooltip";

interface Props {
  className: string;
  email: string;
  isSaved: boolean;
  emailStatus: "available" | "verified" | "unavailable" | "guessed" | "unavailable" | "bounced" | "pending_manual_fulfillment" | null;
  contactDetails?: Mention;
  contactUuid?: string;
  styling?: "card" | "table";
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export default function GetEmailComponent({
  email: initialEmail,
  className,
  isSaved,
  contactDetails,
  contactUuid,
  emailStatus,
  styling = "card"
}: Props) {
  const [email, setEmail] = useState(emailStatus === "unavailable" ? "Email not available." : initialEmail);
  const { getAccessTokenSilently } = useAuth0();

  const onGetEmail = async () => {
    if (isSaved && contactUuid) {
      getEmailForSavedContact(contactUuid, await getAccessTokenSilently()).then((res) => {
        setEmail(res || "Email unavailable");
      });
    } else if (!isSaved && contactDetails) {
      getEmailFortUnsavedContact(contactDetails, await getAccessTokenSilently()).then((res) => setEmail(res || "Email unavailable"));
    }
  };

  return (
    <div className={`${className}`} key={`email-${contactDetails?.linkedin_url}`}>
      {email === "email_not_unlocked@domain.com" ? (
        <Button
          title={`email ${emailStatus}`}
          styling="outline"
          size="xs"
          className="w-fit px-[9px] ml-0 h-7 font-normal items-center align-top flex"
          value={"Get email"}
          onClick={() => onGetEmail()}>
          {emailStatus === "available" || emailStatus === "verified" ? (
            <EmailVerified className="w-3 h-3 mr-1" />
          ) : (
            <EmailUnverified className="w-4 h-4 mr-1"></EmailUnverified>
          )}
          {"Access email"}
        </Button>
      ) : isEmail(email) ? (
        <Tooltip
          className="flex"
          content="email copied to clipboard"
          onClick={async (e, isOpened, setIsOpened) => {
            e.stopPropagation();
            if (isOpened) return;
            copy(email);
            setIsOpened(true);
          }}>
          <div className="flex items-center justify-center shrink-0">{styling === "card" && <Envelope className="w-4 h-4 mr-1" />}</div>
          <div className="overflow-hidden text-ellipsis whitespace-nowrap flex items-center grow mt-[2px]">
            <div
              className={`overflow-hidden text-ellipsis whitespace-nowrap hover:text-purple ${
                styling === "card" ? "text-sm" : "text-[11px] font-normal"
              } leading-[14px] h-[18px] font-medium grow`}>
              {email}
            </div>
          </div>
        </Tooltip>
      ) : (
        <div className="flex items-center w-full">
          <div className="flex items-center justify-center  shrink-0">{styling === "card" && <EmailUnverified className="w-4 h-4 mr-1" />}</div>
          <div className="text-ellipsis whitespace-nowrap flex items-center grow ">
            <a className={` ${styling === "card" ? "text-sm" : "text-[11px] font-normal"}  leading-[14px] h-[18px] font-medium grow`}>
              Email unavailable
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
