import { useState } from "react";

interface Props {
  className?: string;
  onClick: () => void;
  selected: boolean;
  label: string;
}

export default function NewsPrefCard({ className, onClick, selected, label }: Props) {
  const [gradient] = useState<any>({
    background: `linear-gradient(to bottom right, ${getRandomColor()}, ${getRandomColor()})`
  });
  const description = `Funding updates, key hiring, expansions, new projects in the ${label} scene.`;

  function getRandomColor() {
    const hexValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];
    let randomColor = "#";
    for (let i = 0; i < 6; i++) {
      const x = Math.floor(Math.random() * 16);
      randomColor += hexValues[x];
    }
    return randomColor;
  }

  return (
    <div
      className={`${className} ${selected ? "border-[2px] border-purple" : " border-[0.2px] border-[#c4c4c4]"} rounded-lg w-64 h-32 bg-[#EAECFF]`}
      onClick={onClick}>
      <div id={`gradientSquare-${label}`} className="w-5 h-5 ml-3 mt-3 rounded-md" style={gradient}></div>
      <p>
        <b className="ml-3 text-lg">{label}</b>
      </p>
      <p className="ml-3 mr-2 mb-2 text-xs">{description}</p>
    </div>
  );
}
