import { CompanyDomain, JobListing, User } from "../../core/types";
import { capitalizeAllWords } from "../../core/helpers";
import IconListButtons from "../Article/IconListButtons";
import { MentionsList } from "../Article/MentionsList";
import { ReactComponent as LinkedinButton } from "../../icons/social/linkedin_icon.svg";
import OwnedByComponent from "../ContactDetails/OwnedByComponent";
import { setJoblistingOwner } from "../../core/api";
import { useRef, useState, useEffect } from "react";
interface Props {
  data: JobListing;
  className?: string;
  onClickSave?: (uuid: string, isSaving: any, setIsSaving: any) => Promise<void>;
  orgMembers: User[];
  onOwnershipChange?: (ownerUuid: string, targetUuid: string) => void;
  onClickCompanyIcon?: (companyInfo: CompanyDomain) => void;
}

export default function JobListingCard({ data, className = "", orgMembers, onOwnershipChange, onClickCompanyIcon }: Props) {
  //Used to crop title when window size changes
  const titleRef = useRef<HTMLParagraphElement>(null);
  const [maxWidth, setMaxWidth] = useState<string>("none");
  useEffect(() => {
    const calculateMaxWidth = () => {
      if (titleRef.current && titleRef.current.parentNode instanceof HTMLElement) {
        const availableWidth = titleRef.current.parentNode?.clientWidth || 0;
        setMaxWidth(`${availableWidth - 180}px`);
      }
    };

    calculateMaxWidth();
    window.addEventListener("resize", calculateMaxWidth);

    return () => {
      window.removeEventListener("resize", calculateMaxWidth);
    };
  }, []);

  const locale = data.location.split(",");
  return (
    <div
      key={data.uuid}
      className={`bg-white py-2 pl-4 pr-3  flex text-[15px] leading-[18px] font-medium rounded-[10px] align-top  ${className}`}
      data-testid={`joblisting-${data.uuid}`}>
      <div className="  w-full pt-2 mt-0 ">
        <div className="pr-2 text-lg w-full  flex items-center">
          <IconListButtons
            data={[{ logo_url: data.company.logo_url, name: data.company.name, domain: data.company.domain }]}
            onClickButton={onClickCompanyIcon}
            className="my-2 mr-2"></IconListButtons>
          <p ref={titleRef} className="truncate flex-shrink" style={{ maxWidth }}>{`${capitalizeAllWords(data.company.name)} is hiring - ${
            data.title
          }`}</p>
          <div className="ml-auto">
            <OwnedByComponent
              orgMembers={orgMembers}
              style="scoop"
              className="absolute right-2"
              onChange={onOwnershipChange}
              setNewOwnerApiCall={setJoblistingOwner}
              ownerEmail={data.owner?.email}
              targetUuid={data.uuid}></OwnedByComponent>
          </div>
        </div>
        <div className="mx-[4px] items-start align-top pt-2 pr-2 text-sm text-gray">
          <div className="flex mt-2">
            {/* split at T because we have extensive datetime in BE */}
            <div className={` bg-indigo-400 mr-2 px-2 text-white rounded-lg w-fit`}>{data.created.substring(0, 10)}</div>
            <div className={` bg-gray-100 px-2 text-white rounded-lg w-fit`}>Job Listing</div>
          </div>
          <div className="max-h-[110px] mt-4 overflow-clip ">
            {data.listings_count === 0 ? (
              <a
                className="hover:text-purple"
                target={"_blank"}
                rel="noreferrer"
                href={`${data.company_url}/jobs`}>{`${data.company.name} is hiring for 1 role you're interested in.`}</a>
            ) : (
              <p className="mt-4 mr-0">
                {`${data.company.name} is also hiring for `}{" "}
                <a className=" underline hover:text-purple" target={"_blank"} rel="noreferrer" href={`${data.company_url}/jobs`}>{`${
                  data.listings_count
                } other ${data.listings_count > 1 ? "roles" : "role"}`}</a>{" "}
                {"you are interested in."}
              </p>
            )}
          </div>
          <div className="flex mt-4">
            <a className=" font-semibold hover:text-purple flex " target={"_blank"} rel="noreferrer" href={`${data.url}`}>
              <LinkedinButton className="w-4 h-4 pt-1 mr-1"></LinkedinButton> View LinkedIn Listing
            </a>
          </div>
          <div className="flex my-3">
            <p className=" text-black">Location:</p>
            <div className={` bg-indigo-400 h-fit px-1 ml-1 text-white rounded-lg w-fit`}>
              {locale.length === 3 ? `${locale[0]}, ${locale[2]}` : locale[0]}
            </div>
          </div>
          <div className="flex my-3">
            <p className=" text-black">Employee Count:</p>
            <div className={` bg-indigo-400 h-fit px-1 ml-1 text-white rounded-lg w-fit`}>{data.company.employee_count}</div>
          </div>
          <div className="flex mt-3 mb-5">
            <p className=" text-black">Annual Revenue:</p>
            <div className={` bg-indigo-400 h-fit px-1 ml-1 text-white rounded-lg w-fit`}>
              {data.company.annual_revenue ? data.company.annual_revenue : "Unknown"}
            </div>
          </div>
          {data.mentions && data.mentions.length > 0 && <MentionsList mentions={data.mentions} className={"mb-2 mt-3"}></MentionsList>}
        </div>
      </div>
    </div>
  );
}
