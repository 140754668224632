import classNames from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren, AnchorHTMLAttributes, MouseEventHandler } from "react";
import { Link, LinkProps, To } from "react-router-dom";
import { Size } from "../../core/types";
import "./Button.scss";
type Styling = "primary" | "outline" | "outline-purple" | "save";

interface Props {
  styling?: Styling;
  className?: string;
  size?: Size;
}

function isLink(
  props: (LinkProps & React.RefAttributes<HTMLAnchorElement>) | AnchorHTMLAttributes<HTMLAnchorElement>
): props is LinkProps & React.RefAttributes<HTMLAnchorElement> {
  return "to" in (props as any) && (props as any).to !== undefined;
}

const defaultCn = "Button rounded-[5px] font-bold hover:shadow-md transition-shadow duration-300 ";
const primaryCn = "Button--primary text-white";
const outlineCn = "border bg-white border-gray-300";
const outlinePurpleCn = "border bg-white border-purple text-purple";
const saveCn = "border bg-white border-gray-300 ";

const getSizeClassName = (size: Size, styling: Styling) => {
  if (styling === "save") {
    return " min-w-[80px] flex  justify-center font-normal text-[13px] text-center items-center w-fit leading-[22px] border";
  }
  if (size === "lg" && styling !== "primary") {
    return " min-w-[139px] py-[10.5px] px-[15px] text-lg leading-[22px] border-2";
  }
  if (size === "md" && styling !== "primary") {
    return " min-w-[139px] py-[10px] px-[15px] text-[15px] leading-none";
  }
  if (size === "xs" && styling !== "primary") {
    return " min-w-[80px] py-[8px] px-[10px] text-[11px] leading-none";
  }
  if (size === "lg" && styling === "primary") {
    return " min-w-[139px] py-[11.5px] px-[15px] text-lg leading-[22px]";
  }

  return " min-w-[139px] py-[11px] px-4 text-[15px] leading-none";
};

export function Button({
  children,
  styling = "primary",
  className,
  size = "md",
  ...rest
}: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement> & Props>) {
  const classes = classNames(defaultCn, getSizeClassName(size, styling), className, {
    [primaryCn]: styling === "primary",
    [outlineCn]: styling === "outline",
    [outlinePurpleCn]: styling === "outline-purple",
    [saveCn]: styling === "save"
  });

  return (
    <button {...(rest as ButtonHTMLAttributes<HTMLButtonElement>)} className={classes}>
      {children}
    </button>
  );
}

export function LinkButton({
  children,
  styling = "primary",
  className,
  to,
  disabled,
  onClick,
  size = "md",
  ...rest
}: PropsWithChildren<
  (AnchorHTMLAttributes<HTMLAnchorElement> | (LinkProps & React.RefAttributes<HTMLAnchorElement>)) & Props & { to?: To; disabled?: boolean }
>) {
  const classes = classNames(defaultCn, getSizeClassName(size, styling), className, {
    [primaryCn]: styling === "primary",
    [outlineCn]: styling === "outline",
    [outlinePurpleCn]: styling === "outline-purple"
  });
  if (isLink({ ...rest, to })) {
    return (
      <Link
        {...({ ...rest, to } as LinkProps & React.RefAttributes<HTMLAnchorElement>)}
        className={classNames(classes, {
          "Button--disabled": disabled
        })}
        onClick={onClick as MouseEventHandler<HTMLAnchorElement>}>
        {children}
      </Link>
    );
  }

  return (
    <a
      {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
      className={classNames(classes, {
        "Button--disabled": disabled
      })}
      onClick={onClick as MouseEventHandler<HTMLAnchorElement>}>
      {children}
    </a>
  );
}
