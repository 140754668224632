import { getTimeAgo } from "../../core/helpers";
import Dropdown, { DropdownItem } from "../Dropdown/Dropdown";
import { Comment } from "../../core/types";
import { ReactComponent as More } from "../../icons/more.svg";
import { ReactComponent as Trash } from "../../icons/trash.svg";
import "./CommentComponent.scss";
import { deleteComment } from "../../core/api";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  className?: string;
  comments: Comment[];
  setCommentList: React.Dispatch<React.SetStateAction<Comment[]>>;
  userUuid: string;
  maxDisplay?: number;
  style?: "expanded" | "minimized";
}
export function CommentList({ comments, userUuid, maxDisplay, style = "expanded", className, setCommentList }: Props) {
  const { getAccessTokenSilently } = useAuth0();
  function getDropdownOptions(comment: Comment): DropdownItem[] {
    return [
      {
        label: "Remove",
        onClick: async () => {
          deleteComment(comment.uuid, await getAccessTokenSilently()).then(() => setCommentList(comments.filter((c) => c.uuid !== comment.uuid)));
        },
        icon: <Trash></Trash>
      }
    ];
  }

  return (
    <ol
      className={
        `${className} flex flex-col-reverse z-10 ${comments.length > 0 ? `overflow-y-scroll` : ""} ` //can we make the height dynamic on maxDisplay?
      }
      style={{ maxHeight: maxDisplay ? maxDisplay : 120 }}>
      {comments.length > 0 &&
        comments.map((c) =>
          style === "expanded" ? (
            <li key={c.uuid || "most_recent"} className="flex my-2 text-sm relative">
              <div className="flex-col w-[100%]">
                <span className="mb-1 flex items-center">
                  <div className="Comment--user-icon ">{c.author.email.charAt(0).toUpperCase()}</div>
                  <strong> {c.author.email}</strong> <small className=" ml-2 text-xs">{getTimeAgo(c.created_at)}</small>
                </span>
                <p className="ml-[30px]">{c.message}</p>
              </div>
              {userUuid === c.author.uuid && (
                <Dropdown trigger={<More stroke="gray" />} popupDirection="left" items={getDropdownOptions(c)} className=" "></Dropdown>
              )}
            </li>
          ) : (
            <li key={c.uuid || "most_recent"} className="flex my-1 text-sm relative ">
              <div className="flex-col w-full overflow-hidden text-ellipsis ml-1 flex-wrap">
                <span className=" flex items-center">
                  <div className="Comment--user-icon ml-1">{c.author.email.charAt(0).toUpperCase()}</div>
                  <strong className="text-black"> {c.author.email.split("@")[0]}</strong>
                  <small className=" mt-[1px] ml-2 text-xs">{getTimeAgo(c.created_at)}</small>
                </span>
                <div className="flex flex-wrap h-fit w-full">
                  <p className="ml-[35px] h-fit text-black flex-wrap">{c.message}</p>
                </div>
              </div>
              {userUuid === c.author.uuid && (
                <Dropdown trigger={<More stroke="gray" />} popupDirection="left" items={getDropdownOptions(c)} className=" "></Dropdown>
              )}
            </li>
          )
        )}
    </ol>
  );
}
