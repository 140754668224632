import { useAuth0 } from "@auth0/auth0-react";
import { useQueries } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactDetails from "../components/ContactDetails/ContactDetails";
import Loader from "../components/Loader";
import { getArticlesForCompany, getContact, getCurrentUser, getOrganizationMembers } from "../core/api";
import PageWrapper from "../wrappers/PageWrapper";
import { Article, Contact } from "../core/types";
import { useState } from "react";
import { capitalizeFirst } from "../core/helpers";

export default function SingleContact() {
  const navigate = useNavigate();
  const [contact, setContact] = useState<Contact>();
  const [articles, setArticles] = useState<Article[]>([]);
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>(500);
  const { id: contactUuid } = useParams<{ id: string }>();
  const { getAccessTokenSilently } = useAuth0();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [contactQuery, user, orgMembers] = useQueries({
    queries: [
      {
        queryKey: ["Contact"],
        queryFn: async () =>
          getContact(contactUuid!, await getAccessTokenSilently()).then(async (res) => {
            if (!res.company_domain) {
              setRefetchInterval(500);
              return res;
            }
            setRefetchInterval(undefined);
            await getArticlesForCompany(res.company_domain, await getAccessTokenSilently()).then((arts) => setArticles(arts));
            setContact(res);
            return res;
          }),
        refetchInterval: refetchInterval
      },
      { queryKey: ["User"], queryFn: async () => getCurrentUser(await getAccessTokenSilently()), refetchOnWindowFocus: false },
      {
        queryKey: ["OrgMembers"],
        queryFn: async () => await getOrganizationMembers(await getAccessTokenSilently())
      }
    ]
  });

  const location = useLocation();

  const backToGroups = location.state.from === "group";

  const onClickBack = () => {
    navigate(-1);
  };

  if (user.isLoading) return <Loader />;

  return (
    <PageWrapper title={backToGroups ? "Back to group" : capitalizeFirst(location.state.from)} onClickBack={onClickBack} data-testid="SingleContact">
      <ContactDetails orgMembers={orgMembers.data || []} contactData={contact} articles={articles} user={user.data}></ContactDetails>
    </PageWrapper>
  );
}
