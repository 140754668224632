import React from "react";
import Checkbox from "./Checkbox";
import Dropdown, { DropdownItem } from "./Dropdown/Dropdown";
import { ReactComponent as Polygon } from "../icons/polygon.svg";
import "./CheckboxDropdown.scss";
interface Props {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  items: DropdownItem[];
}

export default function CheckboxDropdown({ onChange, checked, items }: Props) {
  return (
    <div className="flex items-center bg-gray-400 rounded-sm p-[3px] justify-start w-[33px]">
      <Checkbox onChange={onChange} checked={checked} />
      <Dropdown
        data-testid="CheckboxDropdown"
        className="Dropdown--CheckboxDropdown Dropdown--bottom--right ml-0.5"
        trigger={<Polygon className="w-3 h-4" />}
        items={items}
      />
    </div>
  );
}
