import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Dropdown, { DropdownItem } from "../Dropdown/Dropdown";
import { deleteCompanyTag, setCompanyTag, unsetCompanyTag } from "../../core/api";
import classNames from "classnames";
import { useDropdown } from "../Dropdown/useDropdown";
import { ReactComponent as More } from "../../icons/more.svg";
import { ReactComponent as Trash } from "../../icons/trash.svg";
import mixpanel from "mixpanel-browser";

interface Props {
  targetUuid: string;
  currentTags: string[];
  possibleTags: string[];
  style: "profile" | "minimized";
  onTagUpdate: (targetUuid: string, tagToAdd: string) => Promise<void>; //for external state updating (table)
  onDelete: (tagName: string) => void;
  className?: string;
}

export default function TagComponent({ targetUuid, currentTags, possibleTags, style, onTagUpdate, onDelete, className }: Props) {
  const UNTAGGED_VALUE = "No Tag";
  const [currentTagsState, setCurrentTagsState] = useState<string[]>(currentTags || []);
  const [allTags, setAllTags] = useState<DropdownItem[]>(toDropdownItems(possibleTags));
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const { getAccessTokenSilently } = useAuth0();
  const { dropdownRef, menuRef, onClickTrigger, isOpened } = useDropdown();

  useEffect(() => {
    setAllTags(toDropdownItems(possibleTags));
  }, [possibleTags]);

  useEffect(() => {
    setCurrentTagsState(currentTags);
  }, [currentTags]);

  function toDropdownItems(tagList: string[]) {
    return tagList.map((tag) => {
      return {
        onClick: async () => handleClick(targetUuid, tag),
        label: tag
      } as DropdownItem;
    });
  }

  async function handleClick(companyUuid: string, tagToSet: string) {
    if (!companyUuid) {
      return;
    }
    if (currentTagsState.includes(tagToSet)) {
      await handleUnselect(tagToSet);
    } else {
      await handleSetNewTag(tagToSet);
    }
  }

  async function handleUnselect(tag: string) {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await onTagUpdate(targetUuid, tag);
    setCurrentTagsState((currentTagsState) => currentTagsState.filter((t) => t !== tag));
    await unsetCompanyTag(targetUuid, tag, await getAccessTokenSilently());
    setIsLoading(false);
  }

  async function handleSetNewTag(tag: string) {
    if (currentTagsState.includes(tag) || isLoading) {
      return;
    }
    setIsLoading(true);

    mixpanel.track("tag_action");
    if (!allTags.map((t) => t.label).includes(tag)) {
      setAllTags((allTags) => [...allTags, ...toDropdownItems([tag])]);
    }
    await onTagUpdate(targetUuid, tag);
    setCurrentTagsState([...currentTagsState, tag]);
    await setCompanyTag(targetUuid, tag, await getAccessTokenSilently());
    setIsLoading(false);
  }

  const handleChange = (evt: any) => {
    setInputValue(evt.target.value);
  };

  const handleKeyDown: (evt: any) => Promise<void> = async (evt: KeyboardEvent) => {
    if ("Backspace" === evt.key && !inputValue && currentTagsState.length) {
      evt.preventDefault();
      const toRemove = currentTagsState[currentTagsState.length - 1];
      if (toRemove) {
        await handleUnselect(toRemove);
      }
    }
    if (["Enter", "Tab"].includes(evt.key)) {
      evt.preventDefault();
      if (inputValue) {
        const inputCopy = inputValue.trim();
        setInputValue("");
        await handleSetNewTag(inputCopy);
      }
    }
  };

  const deleteTag = async (tagName: string) => {
    deleteCompanyTag(tagName, await getAccessTokenSilently());
    setAllTags((allTags) => allTags.filter((t) => t.label !== tagName));
    onDelete(tagName);
  };

  return style === "minimized" && allTags.length ? (
    <Dropdown
      className={`${className} h-6  rounded-[0.2rem] `}
      trigger={<div className=""></div>}
      displayText={
        <div className="flex overflow-hidden truncate">
          {currentTagsState.map((s) => (
            <div
              key={s}
              className={classNames(
                `overflow-hidden text-ellipsis whitespace-nowrap block-inline  rounded-[0.2rem] px-2 h-6 flex contents-center text-sm bg-gray-100 hover:bg-[#877cff2e] items-center  `
              )}></div>
          ))}
        </div>
      }
      items={allTags || []}></Dropdown>
  ) : (
    <div
      ref={dropdownRef}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      key={`${targetUuid}-TagDropdown`}
      className={` mb-[5px] flex max-w-full text-sm rounded-lg  ${className}`}>
      <div className="flex-col max-w-full" onClick={onClickTrigger}>
        <div className="flex items-center max-w-full ">
          <div className={`dropdown-toggle max-w-full my-[2px] h-5`}>
            <div className="text-gray flex max-w-full truncate ">
              {currentTagsState.length > 0 ? (
                currentTagsState.map((item) => {
                  return (
                    <div
                      key={"selected-" + item}
                      className="mr-1 w-fit  flex rounded-[0.2rem]  hover:cursor-pointer h-6 items-center  px-1 pb-[1px] text-center my-[1px] align-middle bg-violet ">
                      <p className="mx-1 ">{item}</p>
                    </div>
                  );
                })
              ) : (
                <div className="flex  rounded-[0.2rem] hover:cursor-pointer h-6 items-center px-1 pb-[1px] text-center my-[1px] align-middle bg-gray-300">
                  <p className="mx-1">{UNTAGGED_VALUE}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {isOpened && (
          <div ref={menuRef} className="dropdown-menu h-fit min-w-[275px] max-w-[350px] mt-1 overflow-x-hidden no-scrollbar pb-3">
            <div className="h-8 py-1 bg-gray-200 ">
              <div className="text-gray flex h-6 overflow-x-scroll no-scrollbar">
                {currentTagsState.map((item) => {
                  return (
                    <div
                      key={"selected-" + item}
                      className="ml-1 w-fit flex  rounded-[0.2rem] whitespace-nowrap items-center hover:cursor-pointer  px-1 pb-[1px]  text-center my-[1px] bg-violet"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await handleUnselect(item);
                      }}>
                      <p className="w-fit mx-1">{item}</p>
                      <button type="button" className="button hover:text-purple mr-[1px] " key={`${item}-delete`}>
                        &times;
                      </button>
                    </div>
                  );
                })}
                <input
                  id="tag_input"
                  className="bg-transparent outline-none placeholder-gray pl-1"
                  onClick={(e) => e.stopPropagation()}
                  autoComplete="off"
                  onSelect={(e) => {
                    e.preventDefault();
                  }}
                  autoFocus={isOpened}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  value={inputValue}
                  placeholder={"type a tag to add"}></input>
              </div>
            </div>
            <hr className="h-px mb-0 bg-gray-300 border-0 dark:bg-gray-700 "></hr>
            {allTags.map((item, index) => (
              <div
                key={"tag-" + index}
                className="flex justify-between w-full py-1  hover:bg-gray-200 "
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleSetNewTag(item.label);
                }}>
                <div
                  className={`pr-2 rounded-[0.2rem]  w-fit   flex items-center ml-1  h-6`}
                  style={{ backgroundColor: `${currentTagsState.includes(item.label) ? "#D0D5FF" : "#d9d9d9"}` }}>
                  <p className="ml-2">{item.label}</p>
                </div>
                <Dropdown
                  className="right-1 absolute"
                  trigger={<More className="h-6" stroke="gray" />}
                  items={[{ label: "Delete", icon: <Trash></Trash>, onClick: async () => deleteTag(item.label) }]}
                  closeOnClick={true}
                  popupDirection="left"></Dropdown>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
