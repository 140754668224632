import { PropsWithChildren } from 'react';
import './Card.scss';

interface Props {
  className?: string;
}

export default function Card({
  children,
  className = '',
}: PropsWithChildren<Props>) {
  return (
    <div className={`Card bg-white rounded-[10px] ${className}`}>
      {children}
    </div>
  );
}
