import Card from "../Card";
import EmailInviteForm from "./EmailInviteForm";

interface Props {
  className?: string;
  headline?: string;
}
export default function EmailInvite({ headline, className }: Props) {
  return (
    <Card className={` ${className}`}>
      <div className="text-xl leading-[24px] font-semibold pt-3 pb-3.5 pl-12 pr-4 border-b border-gray-100 mb-5">{headline}</div>
      <div className="relative">
        <EmailInviteForm className=""></EmailInviteForm>
      </div>
    </Card>
  );
}
