import { useState, useEffect } from "react";
import { ReactComponent as More } from "../../icons/more.svg";
import { ReactComponent as Trash } from "../../icons/trash.svg";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import Table from "../Table";
import Dropdown, { DropdownItem } from "../Dropdown/Dropdown";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { deleteContact, setContactOwner, getOrganizationMembers, getStatusTags, getCurrentUser } from "../../core/api";
import { Contact, Scheduling, Status } from "../../core/types";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import Checkbox from "../Checkbox";
import { id } from "date-fns/locale";
import linkedin from "../../icons/social/linkedin_white_icon.svg";
import Tooltip from "../Tooltip";
import GetEmailComponent from "../ContactDetails/GetEmailComponent";
import OwnedByComponent from "../ContactDetails/OwnedByComponent";
import StatusComponent from "../ContactDetails/StatusComponent";
import "./SavedContactsTable.scss";
import { CommentComponent } from "../Comment/CommentComponent";
import ScheduleReminder from "../ContactDetails/ScheduleReminder";

interface Props {
  contacts: Contact[];
  className: string;
}

export default function SavedContactsTable({ contacts, className }: Props) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [orgMembers, statuses, user] = useQueries({
    queries: [
      {
        queryKey: ["OrgMembers"],
        queryFn: async () => await getOrganizationMembers(await getAccessTokenSilently())
      },
      {
        queryKey: ["Statuses"],
        queryFn: async () => await getStatusTags(await getAccessTokenSilently())
      },
      { queryKey: ["User"], queryFn: async () => getCurrentUser(await getAccessTokenSilently()) }
    ]
  });
  const [contactsData, setContactsData] = useState<Contact[]>(contacts || []);
  const [ownerFilter, setOwnerFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const UNASSIGNED_OWNER_LABEL = "Unassigned";

  useEffect(() => {
    if (!contacts) {
      setContactsData([]);
    }
    //get unassigned contacts if checked;
    const contactDataFiltered: Contact[] = ownerFilter.includes(UNASSIGNED_OWNER_LABEL) ? contacts!.filter((c) => !c.owner) : [];
    ownerFilter.length
      ? setContactsData([...contactDataFiltered, ...contacts!.filter((c) => c.owner && ownerFilter.includes(c.owner.email))])
      : setContactsData(contacts!);
  }, [ownerFilter, contacts]);

  useEffect(() => {
    if (!contacts) {
      setContactsData([]);
    }
    const unsetStatus = "Not Started";
    //get unassigned contacts if checked;
    const contactDataFiltered: Contact[] = statusFilter.includes(unsetStatus) ? contacts!.filter((c) => !c.status) : [];
    statusFilter.length
      ? setContactsData([...contactDataFiltered, ...contacts!.filter((c) => c.status && statusFilter.includes(c.status.name))])
      : setContactsData(contacts!);
  }, [statusFilter, contacts]);

  const ownersDropdownItems = () => {
    if (!orgMembers.data) {
      return [];
    }
    //find all relevant owners in the data, On^2 but shouldn't affect speed too much, consider rewriting...
    const dropdownItems = orgMembers.data
      .map((orgMember) => orgMember.email)
      .filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      })
      .map((email) => {
        return getOwnerDropdownItem(email);
      });
    dropdownItems.push(getOwnerDropdownItem(UNASSIGNED_OWNER_LABEL));
    return dropdownItems;
  };

  function getOwnerDropdownItem(email: string): DropdownItem {
    return {
      label: email,
      checkBox: (
        <Checkbox
          checked={ownerFilter.includes(email)}
          onChange={() => {
            ownerFilter.includes(email) ? setOwnerFilter(ownerFilter.filter((e) => e !== email)) : setOwnerFilter([...ownerFilter, email]);
          }}></Checkbox>
      )
    } as DropdownItem;
  }

  const onOwnershipChange = (ownerUuid: string, contactUuid: string) => {
    const orgMember = orgMembers.data ? orgMembers.data.find((member) => member.uuid === ownerUuid) : null;
    setContactsData((contactsData) =>
      contactsData.map((contact) => {
        if (contact.uuid === contactUuid) {
          contact.owner = orgMember;
        }
        return contact;
      })
    );
  };

  const statusDropdownItems = () => {
    if (!statuses.data) {
      return [];
    }
    const statusData: Status[] = [];
    const firstStatus = statuses.data.find((s) => s.name === "Not Started");
    const secondStatus = statuses.data.find((s) => s.name === "Not Interested");
    if (firstStatus) {
      statusData.push(firstStatus);
    }
    if (secondStatus) {
      statusData.push(secondStatus);
    }
    statuses.data.map((s) => {
      if (!statusData.map((stat) => stat.name).includes(s.name)) {
        statusData.push(s);
      }
    });
    const dropdownItems = statusData.map((status) => {
      return getStatusDropdownItem(status);
    });
    return dropdownItems;
  };

  function getStatusDropdownItem(status: Status): DropdownItem {
    return {
      label: status.name,
      checkBox: (
        <Checkbox
          checked={statusFilter.includes(status.name)}
          onChange={() => {
            statusFilter.includes(status.name)
              ? setStatusFilter(statusFilter.filter((e) => e !== status.name))
              : setStatusFilter([...statusFilter, status.name]);
          }}></Checkbox>
      )
    } as DropdownItem;
  }

  const onStatusChange = (contactUuid: string, status: Status) => {
    setContactsData((contactsData) =>
      contactsData.map((contact) => {
        if (contact.uuid === contactUuid) {
          contact.status = status;
        }
        return contact;
      })
    );
  };

  const onDateSelect = (uuid: string, scheduling: Scheduling | undefined) => {
    setContactsData((contactsData) =>
      contactsData.map((c) => {
        if (c.uuid === uuid) {
          return { ...c, email_notification: scheduling };
        }
        return c;
      })
    );
  };

  return (
    <div className={`${className} `}>
      <div className=" max-h-[278px] overflow-y-auto rounded-md">
        <Table<Contact>
          className="grow Table--Contacts flex flex-col"
          columns={[
            {
              size: "minmax(150px, 1fr)",
              label: "Name",
              render: (r) => (
                <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Name-${r.uuid}`}>
                  <img
                    src={r.image_url}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = process.env.PUBLIC_URL + "/images/default_profile_image.png";
                    }}
                    className="w-7 h-7 rounded-full"
                  />
                  <div className="flex flex-col overflow-hidden text-ellipsis whitespace-nowrap block-inline ">
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline ml-[6px] Contacts__name">
                      {r.first_name} {r.last_name}
                    </span>
                    <Tooltip content={r.linkedin_url}>
                      <a target={"_blank"} rel="noreferrer" href={r.linkedin_url} onClick={(e) => e.stopPropagation()}>
                        <img className="w-2 h-2 mt-1 ml-2 hover:cursor-pointer" src={linkedin}></img>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              )
            },
            {
              label: "Job Title",
              render: (r) => (
                <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Job Title-${r.uuid}`}>
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.position}</span>
                </div>
              )
            },
            {
              label: "Status",
              render: (r) => (
                <div
                  className={classNames(`text-[11px] leading-[13px] items-center w-full content-center flex h-5  align-middle pt-[3px] `)}
                  data-testid={`Status-${r.uuid}`}>
                  <StatusComponent
                    style="minimized"
                    contactUuid={r.uuid}
                    currentStatus={r.status || undefined}
                    className=""
                    possibleStatuses={statuses.data || []}
                    onChange={onStatusChange}></StatusComponent>
                  {/* <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.owner?.email || "unassigned"}</span> */}
                </div>
              ),
              btn: <Dropdown trigger={<Polygon className="ml-1" />} items={statusDropdownItems()}></Dropdown>
            },
            {
              label: "Owner",
              render: (r) => (
                <div
                  className={classNames(`text-[11px] leading-[13px] rounded-md  items-center flex h-5 max-w-fit align-middle pt-[3px] `)}
                  data-testid={`Owner-${r.uuid}`}>
                  <OwnedByComponent
                    style="minimized"
                    targetUuid={r.uuid}
                    ownerEmail={r.owner?.email || undefined}
                    orgMembers={orgMembers.data || []}
                    onChange={onOwnershipChange}
                    setNewOwnerApiCall={setContactOwner}></OwnedByComponent>
                  {/* <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.owner?.email || "unassigned"}</span> */}
                </div>
              ),
              btn: <Dropdown trigger={<Polygon className="ml-1" />} items={ownersDropdownItems()}></Dropdown>
            },
            {
              label: "Email",
              render: (r) => (
                <div
                  className="text-[11px] leading-[13px] flex  items-center h-full truncate"
                  data-testid={`Email-${r.uuid}`}
                  onClick={(e) => e.stopPropagation()}>
                  <GetEmailComponent
                    className={"truncate align-middle"}
                    email={r.email}
                    isSaved={true}
                    emailStatus={r.email_status || null}
                    contactUuid={r.uuid}
                    styling="table"></GetEmailComponent>
                </div>
              )
            },
            {
              label: "Notes",
              render: (r) => (
                <div className="text-[11px] leading-[13px] flex items-center h-full contents-center " data-testid={`Added Date-${r.uuid}`}>
                  {user.data && (
                    <CommentComponent
                      targetUuid={r.uuid}
                      user={user.data}
                      style="minimized"
                      comments={r.comments || []}
                      type="contact"></CommentComponent>
                  )}
                </div>
              )
            },
            {
              label: "Scheduling",
              empty: true,
              size: "36px",
              render: (r) => <ScheduleReminder targetUuid={r.uuid} scheduling={r.email_notification} onUpdate={onDateSelect} className={""} />
            },
            {
              label: "Actions",
              empty: true,
              size: "36px",
              render: (r) => (
                <div
                  className="text-[11px] leading-[13px] flex items-center justify-end h-full cursor-default"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <Dropdown
                    data-testid={`contact-more-btn-${r.uuid}`}
                    className="Dropdown--Contacts"
                    popupDirection="left"
                    trigger={<More stroke="black" />}
                    items={[
                      {
                        label: "Delete",
                        onClick: async () => {
                          deleteContact(r.uuid, await getAccessTokenSilently())
                            .then(() => {
                              queryClient.setQueryData<unknown | undefined>(["Contacts", id], () => {
                                if (!contactsData) {
                                  return;
                                }
                                contacts = contacts?.filter((c) => c.uuid !== r.uuid);
                                setContactsData(contacts || []);
                                return contactsData;
                              });
                            })
                            .catch((e) => console.warn(`caught error while trying to delete contact: ${e}`));
                        },
                        icon: <Trash />
                      }
                    ]}></Dropdown>
                </div>
              )
            }
          ]}
          rows={contactsData}
        />
      </div>
    </div>
  );
}
