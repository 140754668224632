import { useAuth0 } from "@auth0/auth0-react";
import { getCurrentUser } from "../../core/api";
import PageWrapper from "../../wrappers/PageWrapper";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import NewsFlowOnboarding from "./NewsFlowOnboarding";
import { ArticleList } from "../../components/Article/ArticleList";
import { PrefContext } from "../../hooks/PreferenceContext";
import { useEffect, useState } from "react";
import { ScrollPositionProvider } from "../../hooks/ScrollPositionContext";

export default function NewsFlow() {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const user = useQuery({
    queryKey: ["User"],
    queryFn: async () => await getCurrentUser(await getAccessTokenSilently()),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (user && !user.isLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [user]);
  return (
    <PageWrapper title="">
      {isLoading ? (
        <Loader></Loader>
      ) : !user.data?.organization?.self_industry ? (
        <PrefContext.Provider value={{ profilePref: [], languagePref: [], countryPref: [], prospectPref: [] }}>
          <NewsFlowOnboarding
            className=""
            onSubmit={async () => {
              setIsLoading(true);
              await user.refetch();
              setIsLoading(false);
            }}></NewsFlowOnboarding>
        </PrefContext.Provider>
      ) : (
        <ScrollPositionProvider>
          <ArticleList className="mt-5"></ArticleList>
        </ScrollPositionProvider>
      )}
    </PageWrapper>
  );
}
