export function lockScroll(isLocked: boolean) {
  document.querySelector("html")!.style.overflow = isLocked ? "hidden" : "";
}

export function sleep(ms = 300) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const isEmail = (value: string) =>
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    value
  );

export function isUrl(value: string) {
  // eslint-disable-next-line
  return /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
    value
  );
}

export const isUrlRegex = new RegExp(
  // eslint-disable-next-line
  /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/
);

export function capitalizeFirst(value: string) {
  return value.charAt(0).toUpperCase() + value.substring(1, value.length).toLowerCase();
}

export function capitalizeAllWords(value: string) {
  return value
    .split(" ")
    .map((s) => capitalizeFirst(s))
    .join(" ");
}

export function isDomain(value: string) {
  // eslint-disable-next-line no-useless-escape
  return /^(https:\/\/|http:\/\/|)(www.|)[\w\-]+\.[\w\.]+\/?$/.test(value);
}

export const isPasswordValid = (value: string) =>
  containsCapital(value) &&
  containsNonCapital(value) &&
  containsDigit(value) &&
  containsSpecialCharacter(value) &&
  noSpacesOnEnds(value) &&
  isPasswordLongEnough(value);

export function noSpacesOnEnds(v: string) {
  return v === v.trim();
}

export function containsCapital(v: string) {
  return /[A-Z]/.test(v);
}
export function containsNonCapital(v: string) {
  return /[a-z]/.test(v);
}
export function containsDigit(v: string) {
  return /\d/.test(v);
}
export function containsSpecialCharacter(v: string) {
  const characters = [
    "^",
    "$",
    "*",
    ".",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "?",
    '"',
    "!",
    "@",
    "#",
    "%",
    "&",
    "/",
    "\\",
    ",",
    ">",
    "<",
    "'",
    ":",
    ";",
    "|",
    "_",
    "~",
    "`",
    "=",
    "+",
    "-"
  ];

  return !!v.split("").filter((c) => characters.includes(c)).length;
}
export function isPasswordLongEnough(password: string) {
  return password.length >= 8;
}

export const formatNumber = (amount: number, options?: Intl.NumberFormatOptions, lang?: string) => {
  return new Intl.NumberFormat(lang || "en", {
    ...(options
      ? {
          ...options,
          style: options.currency ? "currency" : options.style
        }
      : {})
  }).format(amount);
};

export function downloadFile(href: string, name: string) {
  const link = document.createElement("a");
  link.href = href;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const normalizeDateFE = (iso: string) => {
  return getUTCDate(new Date(iso));
};

export const getUTCDate = (d = new Date()) => {
  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
};

export const getCalendarDate = (iso: string) => {
  return parseToCalendarDate(new Date(iso));
};

export const parseToCalendarDate = (d = new Date()) => {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export function sortArrayOnCreated<T extends { [created_at: string]: any }>(array: T[], order: "ascending" | "descending") {
  return array.sort((a, b) => {
    return order === "ascending"
      ? normalizeDateFE(b.created_at).valueOf() - normalizeDateFE(a.created_at).valueOf()
      : normalizeDateFE(a.created_at).valueOf() - normalizeDateFE(b.created_at).valueOf();
  });
}

export const getTimeAgo = (fromDate: string) => {
  const createdDate = new Date(fromDate);
  const today = new Date();
  let requiredDifferentDays;

  const oneSecondInMillis = 1000;
  const oneMinuteInSeconds = 60;
  const oneHourInSeconds = oneMinuteInSeconds * 60;
  const oneDayInSeconds = oneHourInSeconds * 24;
  const oneYearInSeconds = oneDayInSeconds * 30 * 12;
  const diffSeconds = Math.round(Math.abs((createdDate.valueOf() - today.valueOf()) / oneSecondInMillis));

  if (diffSeconds >= oneYearInSeconds) {
    const tempDate = createdDate.toUTCString();
    requiredDifferentDays = ` ${tempDate.substring(4, 16)}`;
  } else if (diffSeconds >= oneDayInSeconds) {
    const tempDate = createdDate.toUTCString();
    requiredDifferentDays = ` ${tempDate.substring(4, 12)}`;
  } else if (diffSeconds >= oneHourInSeconds) {
    requiredDifferentDays =
      Math.floor(diffSeconds / oneHourInSeconds) == 1
        ? `${Math.floor(diffSeconds / oneHourInSeconds)}h ago`
        : `${Math.floor(diffSeconds / oneHourInSeconds)}h ago`;
  } else if (diffSeconds >= oneMinuteInSeconds) {
    requiredDifferentDays =
      Math.floor(diffSeconds / oneMinuteInSeconds) == 1
        ? `${Math.floor(diffSeconds / oneMinuteInSeconds)} minute ago`
        : `${Math.floor(diffSeconds / oneMinuteInSeconds)} minutes ago`;
  } else {
    requiredDifferentDays = "just now";
  }

  return requiredDifferentDays;
};

export const convertMillisToTime = (timeInMillis: number) => {
  const oneSecondInMillis = 1000;
  const oneMinuteInSeconds = 60;
  const oneHourInSeconds = oneMinuteInSeconds * 60;
  const diffSeconds = timeInMillis / oneSecondInMillis;

  if (diffSeconds >= oneHourInSeconds) {
    return Math.floor(diffSeconds / oneHourInSeconds) == 1
      ? `${Math.floor(diffSeconds / oneHourInSeconds)}hour`
      : `${Math.floor(diffSeconds / oneHourInSeconds)}hours`;
  } else if (diffSeconds >= oneMinuteInSeconds) {
    return Math.floor(diffSeconds / oneMinuteInSeconds) == 1
      ? `${Math.floor(diffSeconds / oneMinuteInSeconds)} minute`
      : `${Math.floor(diffSeconds / oneMinuteInSeconds)} minutes`;
  } else {
    return `${Math.floor(diffSeconds)} seconds`;
  }
};
