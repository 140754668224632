import { useState } from "react";
import Modal from "../Modal";
import FormInput from "../Form/FormInputInterface";
import GenericForm from "../Form/GenericForm";
import { Button } from "../Button/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { addContactWithInfo } from "../../core/api";
import AddContactsFromFileModal from "./AddContactsFromFileModa";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function AddContactModal({ isOpen, onClose }: Props) {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [companyUrl, setCompanyUrl] = useState<string>("");
  const [linkedInUrl, setLinkedInUrl] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();

  const dropdownItems = [
    {
      type: "input",
      title: "First Name",
      label: "First Name",
      name: "firstName",
      onChange: (val: string) => {
        setFirstName(val);
      },
      className: generateFieldClassName()
    } as FormInput,
    {
      type: "input",
      title: "Last Name",
      label: "Last Name",
      name: "lastName",
      onChange: (val: string) => {
        setLastName(val);
      },
      className: generateFieldClassName()
    } as FormInput,
    {
      type: "input",
      title: "Email",
      label: "Email",
      name: "email",
      onChange: (val: string) => {
        setEmail(val);
      },
      className: generateFieldClassName()
    } as FormInput,
    {
      type: "input",
      title: "Company Name",
      label: "Company Name",
      name: "companyName",
      onChange: (val: string) => {
        setCompanyName(val);
      },
      className: generateFieldClassName()
    } as FormInput,
    {
      type: "input",
      title: "Job Title",
      label: "Job Title",
      name: "jobTitle",
      onChange: (val: string) => {
        setJobTitle(val);
      },
      className: generateFieldClassName()
    } as FormInput,
    {
      type: "input",
      title: "Company URL",
      label: "Company URL",
      name: "companyUrl",
      onChange: (val: string) => {
        setCompanyUrl(val);
      },
      className: generateFieldClassName()
    } as FormInput,
    {
      type: "input",
      title: "LinkedIn URL",
      label: "Contact LinkedIn URL",
      name: "linkedInUrl",
      onChange: (val: string) => {
        setLinkedInUrl(val);
      },
      className: generateFieldClassName()
    } as FormInput
  ];

  function generateFieldClassName(): string | undefined {
    return "mb-2 rounded-lg border w-3/4 border-gray-300 p-2 h-8 align-middle flex items-center text-sm pb-1 text-[#6e6e6e] pl-2 resize-none overscroll-y-none whitespace-nowrap no-scrollbar overflow-y-none focus:outline-none focus:placeholder-opacity-0 h-10";
  }

  async function onHandleSubmit() {
    if (!firstName || !lastName || !email || !companyName || !companyUrl || !jobTitle) {
      setError("Please fill out all fields");
      return;
    }
    setError("");
    const body = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      linkedin_url: linkedInUrl,
      company: companyName,
      company_domain: companyUrl,
      position: jobTitle
    };

    await addContactWithInfo(body, await getAccessTokenSilently())
      .then(onClose)
      .catch((e) => {
        console.log(JSON.stringify(e));
        setError(e.message);
      });
  }

  return (
    <Modal isOpened={isOpen} closeModal={onClose} className="items-center">
      <h1 className="text-xl w-full text-center font-semibold text-[#6e6e6e] ">Create Contact</h1>
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <GenericForm
        fields={dropdownItems}
        className={"justify-center flex w-full content-center pb-16 w-600px"}
        button={
          <div className="flex-col flex columns-1 w-[245px]">
            <Button className="mt-8" onClick={() => onHandleSubmit()}>
              Submit
            </Button>
            <Button className="mt-2" onClick={() => setShowUploadModal(true)}>
              Upload CSV
            </Button>
          </div>
        }></GenericForm>
      <Modal isOpened={showUploadModal} closeModal={() => setShowUploadModal(false)}>
        <AddContactsFromFileModal
          isModalOpened={false}
          setIsModalOpened={() => {
            setShowUploadModal(!showUploadModal);
            onClose();
          }}></AddContactsFromFileModal>
      </Modal>
    </Modal>
  );
}
