import classNames from "classnames";
import { PropsWithChildren, useState } from "react";
import { Transition } from "react-transition-group";
import { useDebounce } from "../hooks/useDebounce";
import "./Tooltip.scss";

const DELAY = 250;

interface Props {
  delay?: number;
  content: string | JSX.Element;
  className?: string;
  onClick?: (e: React.MouseEvent, isOpened: boolean, setIsOpened: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

export default function Tooltip({ children, delay = DELAY, content, className = "", onClick }: PropsWithChildren<Props>) {
  const [isOpened, setIsOpened] = useState(false);

  const debouncedIsOpened = useDebounce(isOpened, delay);

  const isShown = isOpened ? debouncedIsOpened : isOpened;

  return (
    <div
      className={`Tooltip inline-block relative ${className}`}
      onMouseOver={
        onClick
          ? undefined
          : () => {
              setIsOpened(true);
            }
      }
      onMouseOut={
        onClick
          ? undefined
          : () => {
              setIsOpened(false);
            }
      }
      onClick={
        onClick
          ? (e) => {
              e.stopPropagation();
              onClick(e, isOpened, setIsOpened);
              setTimeout(() => {
                setIsOpened(false);
              }, 2000);
            }
          : undefined
      }>
      {children}
      <Transition
        in={isShown}
        timeout={{
          exit: 250,
          enter: onClick ? undefined : delay
        }}
        unmountOnExit>
        <div
          className={classNames(
            "absolute Tooltip__content px-2 py-[6px] text-[10px] leading-[12px] whitespace-nowrap bg-white text-gray font-medium z-20 rounded-[5px]",
            {
              fadeIn: debouncedIsOpened,
              fadeOut: !isOpened
            }
          )}>
          {content}
        </div>
      </Transition>
    </div>
  );
}
