import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";
import { ReactComponent as ChevronLeft } from "../icons/chevron-left.svg";

interface Props {
  onClickBack?: () => void;
  btn?: JSX.Element;
  title?: string;
  top?: JSX.Element;
  className?: string;
}

export default function PageWrapper({
  children,
  btn,
  onClickBack,
  title,
  top,
  className,
  ...rest
}: PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>) {
  return (
    <div className={`pt-5 px-[40px] pb-20 flex flex-col grow`} {...rest}>
      <div className="container-lg grow flex flex-col relative">
        <div className="-z-50">{top}</div>
        <div
          className={`${classNames(" flex items-center justify-between", {
            "text-[25px] leading-[32px]": !onClickBack,
            "text-lg leading-[22px] mb-3": onClickBack
          })} ${className}`}>
          <div className={`flex items-center ${onClickBack ? "hover:bg-gray-500 rounded-md hover:cursor-pointer pr-2" : ""}`} onClick={onClickBack}>
            {onClickBack && (
              <button className="IconBtn mr-px" data-testid="back-btn">
                <ChevronLeft />
              </button>
            )}
            {title && <span className=" font-medium">{title}</span>}
          </div>
          {btn}
        </div>
        {children}
      </div>
    </div>
  );
}
