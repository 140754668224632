//eslint disabling due to weird bug where it complains on a non-existent variable
/* eslint-disable no-unused-vars */
import "./CommentComponent.scss";
import { ReactComponent as SubmitButton } from "../../icons/submit_comment_button.svg";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { postCommentForCompany, postCommentForContact } from "../../core/api";
import { User } from "../../core/types";
import { sortArrayOnCreated } from "../../core/helpers";
import { CommentList } from "./CommentList";
import { useDropdown } from "../Dropdown/useDropdown";
import { Comment } from "../../core/types";

interface Props {
  className?: string;
  targetUuid: string;
  user: User;
  type: "company" | "contact";
  maxDisplay?: number;
  comments: Comment[];
  style?: "expanded" | "minimized";
}

export function CommentComponent({ targetUuid, user, type, maxDisplay, comments, style = "expanded", className }: Props) {
  const [commentInputState, setCommentInputState] = useState<string>("");
  const [commentListState, setCommentListState] = useState<Comment[]>(sortArrayOnCreated(comments, "ascending"));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const { dropdownRef, menuRef, onClickTrigger, isOpened } = useDropdown();

  const handleKeyDown: (evt: any) => void = (evt: KeyboardEvent) => {
    if (["Enter"].includes(evt.key)) {
      evt.preventDefault();
      handleSubmit();
    }
  };

  const handleChange = (evt: any) => {
    setCommentInputState(evt.target.value);
  };

  async function handleSubmit() {
    if (isLoading) {
      return;
    }
    if (commentInputState) {
      setIsLoading(true);
      const message = commentInputState;
      setCommentInputState("");
      try {
        if (type === "company") {
          const postedComment = await postCommentForCompany(targetUuid, message, await getAccessTokenSilently());
          setCommentListState([postedComment, ...commentListState]);
        } else if (type === "contact") {
          const postedComment = await postCommentForContact(targetUuid, message, await getAccessTokenSilently());
          setCommentListState([postedComment, ...commentListState]);
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  return style === "expanded" ? (
    <div className={className}>
      <hr className="Comment--divider"></hr>
      <CommentList maxDisplay={maxDisplay} comments={commentListState} userUuid={user.uuid} setCommentList={setCommentListState}></CommentList>
      <div className="flex items-center">
        <div className="Comment--user-icon ">{user.email.charAt(0).toUpperCase()}</div>
        <input
          className="Comment--input text-sm"
          placeholder={"Add a comment..."}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          value={commentInputState}></input>
        <SubmitButton className="Comment--button" onClick={handleSubmit}></SubmitButton>
      </div>
      <hr className="Comment--divider"></hr>
    </div>
  ) : style === "minimized" ? (
    <div
      ref={dropdownRef}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      key={`${targetUuid}-TagDropdown`}
      className={` mb-[5px] flex max-w-full text-sm rounded-lg  ${className}`}>
      <div className="flex-col max-w-full" onClick={onClickTrigger}>
        <div className="flex items-center max-w-full ">
          <div className={`dropdown-toggle max-w-full my-[2px] h-5`}>
            <div className="text-gray flex max-w-full truncate ">
              <div className="flex  rounded-[0.2rem] hover:cursor-pointer h-6 items-center px-1 pb-[1px] text-center my-[1px] align-middle bg-gray-300">
                {commentListState.length ? <p className="mx-1">{commentListState[0].message}</p> : <p className="text-gray">Add comment</p>}
              </div>
            </div>
          </div>
        </div>
        {isOpened && (
          <div
            ref={menuRef}
            className="dropdown-menu max-h-[250px] min-w-[275px] max-w-[350px] mt-2 overflow-x-hidden no-scrollbar pb-3 -translate-x-1/4  ">
            <CommentList
              className="pt-2"
              style={style}
              comments={commentListState}
              maxDisplay={500}
              userUuid={user.uuid}
              setCommentList={setCommentListState}
            />
            <div className="text-gray flex flex-col h-6 overflow-x-scroll no-scrollbar mt-2">
              <hr className="h-px mb-0 bg-gray-300 border-0 dark:bg-gray-700 "></hr>
              <div className="flex items-center">
                <div className="Comment--user-icon w-[25px] ml-[9px] items-center">{user.email.charAt(0).toUpperCase()}</div>
                <input //Consider adding a loading animation or similar
                  className="Comment--input text-sm rounded-l-md "
                  autoComplete="off"
                  onSelect={(e) => {
                    e.preventDefault();
                  }}
                  onClick={(e) => e.stopPropagation()}
                  autoFocus={isOpened}
                  placeholder={"Add a comment..."}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  value={commentInputState}></input>
                <SubmitButton
                  className="Comment--button  h-5 w-6 mt-[5px] pr-1  bg-[#f5f5f5] rounded-r-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubmit();
                  }}></SubmitButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
