import { CompanyDomain } from "../../core/types";
import { RoutesEnum } from "../../core/enums";
import { useNavigate } from "react-router-dom";
import Tooltip from "../Tooltip";

interface Props {
  data: CompanyDomain[];
  onClickButton?: (data: CompanyDomain) => void;
  className: string;
}

export default function IconListButtons({ data, className, onClickButton }: Props) {
  return <div className={`flex flex-row ${className}`}>{data.map((c) => parseDomain(c))}</div>;

  function parseDomain(company: CompanyDomain) {
    if (!company || !company.domain || !company.logo_url) {
      return;
    }
    const navigate = useNavigate();
    const url = company.logo_url;
    return (
      <div className="h-6 w-6 mx-1 cursor-pointer" key={company.domain}>
        <Tooltip content={company.name}>
          <img
            className="h-6 w-6 rounded hover:shadow-allround"
            src={url}
            onClick={
              onClickButton
                ? () => onClickButton(company)
                : () => navigate(`${RoutesEnum.CompanyInfo.replace(":companyDomain", company.domain)}`, { state: { from: "scoops" } })
            }></img>
        </Tooltip>
      </div>
    );
  }
}
