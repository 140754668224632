import { ReactComponent as Envelope } from "../../icons/social/email_found.svg";
import { ReactComponent as Briefcase } from "../../icons/briefcase_purple.svg";
import { ReactComponent as Save } from "../../icons/save.svg";
import { ReactComponent as Pin } from "../../icons/map_pin.svg";
import { Contact } from "../../core/types";
import Tooltip from "../Tooltip";
import Card from "../Card";
import SocialMediaButtons from "../SocialMediaButtons";
import { ReactComponent as SkeletonUser } from "../../icons/defaultprofile.svg";
import "./ProfileCard.scss";
import GetEmailComponent from "./GetEmailComponent";

interface Props {
  data?: Contact;
  className?: string;
  onClickSave?: () => void;
}

export default function ProfileCard({ data, onClickSave, className }: Props) {
  function getSkeletonElements() {
    return (
      <div className="flex flex-1 min-w-[500px]">
        <div className=" self-center mr-5">
          <SkeletonUser className=" ProfileCard--skeleton-user flex shrink "></SkeletonUser>
        </div>
        <div className="mx-5 flex-1">
          <div className=" my-4">
            <div className={`ProfileCard--skeleton-name ProfileCard--animation`}></div>
          </div>
          {[
            { icon: <Briefcase className="ProfileCard--logo w-5 h-5" /> },
            { icon: <Pin /> },
            { icon: <Envelope className="ProfileCard--logo w-5 h-5" /> }
          ].map(({ icon }, i) => (
            <div className="flex items-center mb-2 w-full" key={i}>
              <div className="ProfileCard--logo ">{icon}</div>
              <div className={`ProfileCard--skeleton-info ProfileCard--animation`}></div>
            </div>
          ))}
          <div className={`ProfileCard--skeleton-info ProfileCard--animation`}></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Card className={`${className} px-6 pt-6 pb-[19px] rounded-[10px] flex shadow-none `}>
        {onClickSave && (
          <div className="absolute top-[9px] left-[17px]">
            <Tooltip content="Save contact">
              <button className="ContactDetails__save hover:opacity-50 transition-opacity">
                <Save />
              </button>
            </Tooltip>
          </div>
        )}
        {data && data.company_domain ? getContactElement(data) : getSkeletonElements()}
      </Card>
    </>
  );

  function getContactElement(contact: Contact) {
    const {
      uuid,
      first_name,
      last_name,
      linkedin_url,
      twitter_url,
      facebook_url,
      company,
      position,
      image_url,
      email,
      country,
      state,
      city,
      email_status
    } = contact;
    const location = state && city ? `${city}, ${state}` : country && city ? `${city}, ${country}` : country ? `${country}` : "";
    return (
      <div className="flex">
        <div className=" self-center mr-5">
          <img
            src={image_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = process.env.PUBLIC_URL + "/images/default_profile_image.png";
            }}
            className="max-w-[200px] max-h-[200px] min-w-[100px] min-h-[100px] rounded-full self-center content-center align-middle"
          />
        </div>
        <div className="mx-5">
          <div className="text-2xl leading-none font-extrabold my-4">
            <div>{`${first_name} ${last_name}`}</div>
            <div></div>
          </div>
          {[
            { value: `${company}, ${position}`, icon: <Briefcase stroke="purple" className="w-5 h-5" /> },
            { value: location, icon: <Pin /> }
          ].map(({ icon, value }, i) => (
            <div className="flex items-center mb-2 w-full" key={i}>
              <div className="flex items-center justify-center mr-[9px] shrink-0">{icon}</div>
              <div className="overflow-hidden text-ellipsis whitespace-nowrap flex items-center grow ">
                <a className={`overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-[14px]  font-medium grow`}>{value}</a>
              </div>
            </div>
          ))}
          <GetEmailComponent className={"mb-1"} email={email} isSaved={true} emailStatus={email_status || null} contactUuid={uuid} />
          <SocialMediaButtons data={{ linkedin_url, facebook_url, twitter_url }}></SocialMediaButtons>
        </div>
      </div>
    );
  }
}
