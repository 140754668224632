import { useMemo, useState } from "react";

const SIZE = 6;

export default function usePagination<T>(data: T[], pageSize: number = SIZE) {
  const [currentPage, setCurrentPage] = useState(1);
  const pages = useMemo(() => Math.ceil(data.length / pageSize), [data]);
  const currentPageData = data.slice((currentPage - 1) * pageSize, pageSize * currentPage);

  return { currentPage, setCurrentPage, pages, currentPageData, pageSize };
}

export function useCustomPagination<T>(data: T[][]) {
  const [currentPage, setCurrentPage] = useState(1);
  const pages = useMemo(() => data.length, [data]);
  const pageSize = data[currentPage - 1] ? data[currentPage - 1].length : 0;
  const currentPageData = data[currentPage - 1];
  return { currentPage, setCurrentPage, pages, currentPageData, pageSize };
}
