import classNames from "classnames";
import { PropsWithChildren, useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import { lockScroll } from "../core/helpers";
import { ReactComponent as Cross } from "../icons/cross.svg";
import "./Modal.scss";

interface Props {
  isOpened: boolean;
  type?: "fixed" | "absolute";
  closeModal: () => void;
  empty?: boolean;
  className?: string;
}

export default function Modal({ closeModal, isOpened, children, type = "fixed", empty = false, className = "", ...rest }: PropsWithChildren<Props>) {
  const ref = useRef(null);

  useEffect(() => {
    lockScroll(isOpened);
  }, [isOpened]);

  return (
    <Transition in={isOpened} timeout={{ enter: 0, exit: 250 }} unmountOnExit nodeRef={ref}>
      <div
        {...rest}
        className={classNames("w-full h-full Modal pb-30 px-9 top-0 left-0 flex flex-col overflow-y-auto", type, className, {
          fadeIn: isOpened,
          fadeOut: !isOpened
        })}
        onMouseDown={closeModal}>
        <div ref={ref} className="container flex flex-col items-center justify-center grow">
          {empty ? (
            <div
              className="flex flex-col grow w-full"
              onMouseDown={(e) => {
                e.stopPropagation();
              }}>
              {children}
            </div>
          ) : (
            <div
              className="bg-white pb-8 pt-4 px-3 rounded-[10px] max-w-[346px] Modal__inner w-full"
              onMouseDown={(e) => {
                e.stopPropagation();
              }}>
              <div className="flex justify-end">
                <button className="flex items-center justify-center hover:rotate-180 transition-transform" onClick={closeModal}>
                  <Cross />
                </button>
              </div>
              {children}
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
}
