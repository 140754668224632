import { InfiniteData, useInfiniteQuery, useQueries, useQueryClient } from "@tanstack/react-query";
import ArticleCard from "./ArticleCard";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getArticleEventTags,
  getArticles,
  getCompanyOfInterest,
  getCurrentUserForNewsFlow,
  getJobListings,
  getOrganizationMembers,
  saveArticleForUser
} from "../../core/api";
import { normalizeDateFE } from "../../core/helpers";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Article, CompanyDomain, CompanyOfInterest, JobListing, User } from "../../core/types";
import Loader from "../Loader";
import { NewsSortContext } from "../../hooks/NewsSortingContext";
import CompanyOfInterestCard from "../Company/CompanyOfInterestCard";
import JobListingCard from "../JobListing/JobListingCard";
import GenericDropdown from "../Dropdown/GenericDropdown";
import { DropdownItem } from "../Dropdown/Dropdown";
import CompanyInfoCompiled from "../Company/CompanyInfoCompiled";
import { ReactComponent as ChevronLeft } from "../../icons/chevron-left.svg";
import { useScrollPosition } from "../../hooks/ScrollPositionContext";

interface Props {
  className?: string;
}

type PaginatedScoops = {
  scoops: (Article | CompanyOfInterest | JobListing)[];
  nextCursor: number;
};

export function ArticleList({ className }: Props) {
  const sortContext = useContext(NewsSortContext);
  const { getAccessTokenSilently } = useAuth0();
  const [articleTagFilters, setArticleTagFilters] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [showJobListings, setShowJobListings] = useState<boolean>(false);
  const [showArticles, setShowArticles] = useState<boolean>(false);
  const [orgMembers, setOrgMembers] = useState<User[]>([]);
  const [flowItems, setFlowItems] = useState<(Article | CompanyOfInterest | JobListing)[]>([]);
  const [userSavedScoops, setUserSavedScoops] = useState<(Article | CompanyOfInterest | JobListing)[]>([]);
  const [ownedByFilter, setOwnedByFilter] = useState<string[]>([]);
  const [companyDomain, setCompanyDomain] = useState<string>("");
  const { saveScrollPosition, getSavedScrollPosition } = useScrollPosition();
  const isFirstMount = useRef(true); // Ref to track the first mount

  //Refs for infinite scroll
  const bottomBoundaryRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const [currentPage, setCurrentPage] = useState<number>();

  const fetchScoops = async ({ pageParam = 0 }) => {
    const articles = await getArticles(pageParam, await getAccessTokenSilently()).then((articles) =>
      articles.map((a) => {
        return { ...a, type: "article" } as Article;
      })
    );
    const jobListings = await getJobListings(pageParam, await getAccessTokenSilently()).then((jobListings) =>
      jobListings.map((j) => {
        return { ...j, type: "joblisting", date: j.created_at.split("T")[0] } as JobListing;
      })
    );
    const suggestions = await getCompanyOfInterest(pageParam, await getAccessTokenSilently()).then((suggestions) =>
      suggestions.map((c) => {
        return { ...c, type: "company_of_interest", date: c.created_at.split("T")[0] } as CompanyOfInterest;
      })
    );
    const allScoops = [...articles, ...jobListings, ...suggestions];
    setCurrentPage(pageParam);
    return { scoops: sortOnDate(allScoops), nextCursor: pageParam + 1 };
  };

  const { fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: ["scoops"],
    queryFn: fetchScoops,
    getNextPageParam: (lastPage) => lastPage.nextCursor
  });

  const queryClient = useQueryClient();

  const [user, articleEventTags] = useQueries({
    queries: [
      {
        queryKey: ["UserWithArticles"],
        queryFn: async () =>
          await getCurrentUserForNewsFlow(await getAccessTokenSilently()).then((u) => {
            const savedArticles =
              u.saved_articles?.map((a) => {
                return { ...a, type: "article" } as Article;
              }) || [];
            const savedCoi =
              u.saved_company_of_interest?.map((c) => {
                return { ...c, type: "company_of_interest", date: c.created_at.split("T")[0] } as CompanyOfInterest;
              }) || [];
            setUserSavedScoops([...savedArticles, ...savedCoi]);
            return u;
          })
      },
      {
        queryKey: ["ArticleTags"],
        queryFn: async () => await getArticleEventTags(await getAccessTokenSilently())
      },
      {
        queryKey: ["OrgMembers"],
        queryFn: async () =>
          getOrganizationMembers(await getAccessTokenSilently()).then((res) => {
            setOrgMembers(res);
            return res;
          })
      }
    ]
  });

  //for saving scroll state whenever a user goes back from opening a company
  useLayoutEffect(() => {
    if (!isFirstMount.current) {
      const savedPosition = getSavedScrollPosition();
      if (savedPosition) {
        window.scrollTo(0, savedPosition);
      }
    } else {
      isFirstMount.current = false; // Update the ref after the first mount
    }
  }, [companyDomain]);

  //To get max-width for articles
  const [maxWidth, setMaxWidth] = useState<number>(0);
  useEffect(() => {
    const calculateMaxWidth = () => {
      const screenWidth = window.innerWidth;
      const maxContentWidth = screenWidth * 0.8; // 80% of screen width
      setMaxWidth(maxContentWidth);
    };

    calculateMaxWidth();
    window.addEventListener("resize", calculateMaxWidth);

    return () => {
      window.removeEventListener("resize", calculateMaxWidth);
    };
  }, []);

  useEffect(() => {
    let reducedList: (Article | CompanyOfInterest | JobListing)[] = [];
    if (sortContext.sort === "all") {
      const scoops = queryClient
        .getQueryData<InfiniteData<PaginatedScoops>>(["scoops"])
        ?.pages.map((page: PaginatedScoops) => {
          if (!page.scoops || !page.scoops.length) return page.scoops;
          return page.scoops.map((scoop) => scoop);
        })
        .flat();
      if (!scoops) return;
      let scoopsToDisplay: (Article | CompanyOfInterest | JobListing)[] = [];
      for (const scoop of scoops) {
        if (ownedByFilter.length) {
          if (scoop.owner && ownedByFilter.includes(scoop.owner?.uuid)) {
            const scoopToAdd = getScoopBasedOnDisplayPrefs(scoop);
            if (scoopToAdd) {
              scoopsToDisplay = [...scoopsToDisplay, scoopToAdd];
            }
          }
        } else {
          const scoopToAdd = getScoopBasedOnDisplayPrefs(scoop);
          if (scoopToAdd) {
            scoopsToDisplay = [...scoopsToDisplay, scoopToAdd];
          }
        }
      }
      reducedList = [...reducedList, ...scoopsToDisplay];
      setFlowItems(sortOnDate(reducedList || []));
    } else {
      setFlowItems(sortOnDate(userSavedScoops));
    }
  }, [articleTagFilters, showJobListings, showSuggestions, showArticles, sortContext, ownedByFilter, currentPage, userSavedScoops]);

  //Used for infinite scroll
  const observerCallback: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  //Used for infinite scroll
  useEffect(() => {
    observer.current = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    });
    if (bottomBoundaryRef.current) {
      observer.current.observe(bottomBoundaryRef.current);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [bottomBoundaryRef, observerCallback]);

  function getScoopBasedOnDisplayPrefs(scoop: Article | CompanyOfInterest | JobListing) {
    //case if no specific filter is chosen
    if (!showArticles && !articleTagFilters.length && !showJobListings && !showSuggestions) {
      return scoop;
    }
    switch (scoop.type) {
      case "article": {
        if (articleTagFilters.length) {
          const relTags = scoop.article_tag_names;
          if (!relTags) break;
          for (const tag of relTags) {
            if (articleTagFilters.includes(tag)) {
              return scoop;
            }
          }
        } else if (showArticles) {
          return scoop;
        }
        break;
      }
      case "company_of_interest": {
        if (showSuggestions) return scoop;
        break;
      }
      case "joblisting": {
        if (showJobListings) return scoop;
        break;
      }
    }
  }
  function sortOnDate(scoops: (Article | CompanyOfInterest | JobListing)[]): (Article | CompanyOfInterest | JobListing)[] {
    // Check if the date exists and is valid before filtering
    const scoopsFeed = scoops.filter((a) => a.date && !isNaN(normalizeDateFE(a.date).valueOf()));

    return scoopsFeed.sort((a, b) => {
      const dateA = normalizeDateFE(a.date).valueOf();
      const dateB = normalizeDateFE(b.date).valueOf();

      if (dateB - dateA === 0) {
        const createdAtA = normalizeDateFE(a.created_at).valueOf();
        const createdAtB = normalizeDateFE(b.created_at).valueOf();
        return createdAtA - createdAtB;
      }

      return dateB - dateA;
    });
  }

  async function onClickSave(uuid: string, isSaving: any, setIsSaving: any): Promise<void> {
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    await saveArticleForUser(uuid, await getAccessTokenSilently());
    await user.refetch();
    setIsSaving(false);
  }

  function getArticleCategoriesItems(): DropdownItem[] {
    if (!articleEventTags.data) {
      return [];
    }
    return articleEventTags.data.filter((t: string) => t !== "Irrelevant").map((label: string) => getArticleTagFilterDropdownItem(label));
  }

  function getArticleTagFilterDropdownItem(label: string): DropdownItem {
    return {
      label: label,
      checkboxOnChange: (e) => {
        if (e) {
          //had to make e nullable for dropdownInputComponent
          e.stopPropagation();
        }
        articleTagFilters.includes(label)
          ? setArticleTagFilters((articleTagFilters) => articleTagFilters.filter((category) => category !== label))
          : setArticleTagFilters((articleTagFilters) => [...articleTagFilters, label]);
      }
    } as DropdownItem;
  }

  function getOwnerFilterOptions(members: User[]): DropdownItem[] {
    return members.map((m) => getOwnedByFilterDropdownItem(m.email, m.uuid));
  }

  function getOwnedByFilterDropdownItem(email: string, userUuid: string): DropdownItem {
    return {
      label: email,
      checkboxOnChange: (e) => {
        if (e) {
          //had to make e nullable for dropdownInputComponent
          e.stopPropagation();
        }
        ownedByFilter.includes(userUuid)
          ? setOwnedByFilter((ownedByFilter) => ownedByFilter.filter((ownerUuid) => ownerUuid !== userUuid))
          : setOwnedByFilter((ownedByFilter) => [...ownedByFilter, userUuid]);
      }
    } as DropdownItem;
  }

  const updateOwnerQueryData = async (ownerUuid: string, targetUuid: string) => {
    await queryClient.cancelQueries({ queryKey: ["scoops"] });

    const previousData = queryClient.getQueryData<InfiniteData<PaginatedScoops>>(["scoops"]);
    if (previousData) {
      queryClient.setQueryData(["scoops"], (data: InfiniteData<PaginatedScoops> | undefined) => {
        if (!data) return data;

        return {
          ...data,
          pages: data.pages.map((page) => {
            if (!page.scoops || !page.scoops.length) return page;

            return {
              ...page,
              scoops: page.scoops.map((scoop) => {
                return scoop.uuid === targetUuid
                  ? {
                      ...scoop,
                      owner: orgMembers.find((member) => member.uuid === ownerUuid) || undefined
                    }
                  : scoop;
              })
            };
          })
        };
      });
    }
  };

  function setCompany(domain: CompanyDomain) {
    saveScrollPosition(window.scrollY);
    setCompanyDomain(domain.domain);
  }

  function onClickBack() {
    setCompanyDomain("");
  }

  return status === "loading" ? (
    <p>Loading...</p>
  ) : (
    <div className={`${className}`}>
      {companyDomain ? (
        <div>
          <div className={`flex items-center hover:bg-gray-500 rounded-md w-fit hover:cursor-pointer pr-2`} onClick={onClickBack}>
            <button className="IconBtn mr-px" data-testid="back-btn">
              <ChevronLeft />
            </button>
            <span className=" font-medium">{"Back to scoops"}</span>
          </div>
          <CompanyInfoCompiled companyDomain={companyDomain}></CompanyInfoCompiled>
        </div>
      ) : flowItems ? (
        <div>
          <div className="flex w-full hover:cursor-pointer">
            {!user.data?.organization?.isEnterprise ? (
              <GenericDropdown
                items={getArticleCategoriesItems()}
                name={"article filter"}
                isMulti={true}
                selectAllOption={true}
                placeholder={"News & Press Releases"}
                className="w-fit min-w-30"></GenericDropdown>
            ) : (
              <div className={`border-[0.2px] ${showArticles ? "border-[#877CFF] text-[#877CFF]" : "border-gray-100 text-gray"} rounded-lg   ml-2`}>
                <div className="mx-2 flex items-center pt-0.5 hover:cursor-pointer" onClick={() => setShowArticles(!showArticles)}>
                  <p className="">News & Press Releases</p>
                </div>
              </div>
            )}
            <div className={`border-[0.2px] ${showSuggestions ? "border-[#877CFF] text-[#877CFF]" : "border-gray-100 text-gray"} rounded-lg   ml-2`}>
              <div className="mx-2 flex items-center pt-0.5 hover:cursor-pointer" onClick={() => setShowSuggestions(!showSuggestions)}>
                <p className="">Recommended Companies</p>
              </div>
            </div>
            {!user.data?.organization?.isEnterprise && (
              <div
                className={`border-[0.2px] ${showJobListings ? "border-[#877CFF] text-[#877CFF]" : "border-gray-100 text-gray"} rounded-lg  ml-2`}
                onClick={() => setShowJobListings(!showJobListings)}>
                <div className="mx-2 flex items-center pt-0.5 hover:cursor-pointer">
                  <p className="">Job Listings</p>
                </div>
              </div>
            )}
            <GenericDropdown
              items={getOwnerFilterOptions(orgMembers)}
              name={"article filter"}
              isMulti={true}
              selectAllOption={true}
              placeholder={"Owner"}
              className="w-fit mx-2 min-w-30 "></GenericDropdown>
          </div>
          <ol className={`pt-4`} style={{ maxWidth: `${maxWidth}px` }}>
            {getScoopCards(flowItems)}
          </ol>
          {sortContext.sort === "all" && <div ref={bottomBoundaryRef}>{isFetching && isFetchingNextPage ? "Fetching more scoops..." : null}</div>}
        </div>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );

  function getScoopCards(items: (Article | CompanyOfInterest | JobListing)[]) {
    return items.map((scoop, index) => {
      if (scoop.type === "company_of_interest") {
        return (
          <CompanyOfInterestCard
            key={scoop.uuid + index}
            data={scoop as CompanyOfInterest}
            className="mb-5"
            orgMembers={orgMembers}
            onClickCompanyIcon={(domain: CompanyDomain) => setCompany(domain)}
            onOwnershipChange={updateOwnerQueryData}></CompanyOfInterestCard>
        );
      }
      if (scoop.type === "article") {
        return (
          <ArticleCard
            onClickSave={onClickSave}
            data={scoop as Article}
            key={scoop.uuid + index}
            orgMembers={orgMembers}
            onOwnershipChange={updateOwnerQueryData}
            onClickCompanyIcon={(domain: CompanyDomain) => setCompany(domain)}
            className="mb-5"></ArticleCard>
        );
      }
      if (scoop.type === "joblisting") {
        return (
          <JobListingCard
            key={scoop.uuid + index}
            data={scoop as JobListing}
            className="mb-5"
            orgMembers={orgMembers}
            onClickCompanyIcon={(domain: CompanyDomain) => setCompany(domain)}
            onOwnershipChange={updateOwnerQueryData}></JobListingCard>
        );
      }
    });
  }
}
