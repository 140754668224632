import { Company } from "../../core/types";

interface Props {
  className: string;
  company: Company;
}

export default function InfoCard({ className, company }: Props) {
  return (
    <div className={`bg-white py-4 pl-4 pr-3 leading-[18px] divide-x divide-warmGray-400 font-medium rounded-[10px] ${className}`}>
      <div className="mx-4 font-normal text-[14px]">
        <div className="font-semibold mb-2 text-[15px]"> Summary:</div>
        <p className="">{company.summary || "We could not find a description for this company."}</p>
      </div>
      <div className="flex  justify-center my-4">
        <ol className="text-[14px] flex whitespace-nowrap font-semibold flex-col mb-4">
          <li className="my-2 mx-6">Industry</li>
          <li className="my-2 mx-6">Founding Year</li>
          <li className="my-2 mx-6">Employees</li>
          <li className="my-2 mx-6">Annual Revenue</li>
        </ol>
        <div>
          <ol className="text-[14px] flex whitespace-nowrap flex-col mb-4">
            <li className="my-2  mx-6">{company.industry ? company.industry.charAt(0).toUpperCase() + company.industry.slice(1) : "Not found"}</li>
            <li className="my-2 mx-6">{company.founding_year ? company.founding_year : "Not found"}</li>
            <li className="my-2 mx-6">{company.employee_count ? company.employee_count : "Not found"}</li>
            <li className="my-2 mx-6">{`${company.annual_revenue ? company.annual_revenue : "Not found"}`}</li>
          </ol>
        </div>
      </div>
    </div>
  );
}
