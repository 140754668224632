import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import Table, { Column } from "../../components/Table";
import { Company } from "../../core/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { capitalizeAllWords, convertMillisToTime, getTimeAgo, normalizeDateFE } from "../../core/helpers";
import { getJobListingsPaginated } from "../../core/api";
import "./JobListings.scss";
import Card from "../../components/Card";
import EmptyPageMessage from "../../components/EmptyPageMessage";
import { ReactComponent as JobListingIcon } from "../../icons/sidebar/job_listings_sidebar.svg";
import GenericDropdown, { getDropDownItems } from "../../components/Dropdown/GenericDropdown";
import CalendarFilterComponent from "../../components/Filter/CalendarFilterComponent";

interface JobListingTableItem {
  company: Company;
  job_title: string;
  job_function: string;
  date_posted: string;
  job_linkedin_url: string;
  industry: string;
  location: string;
  total_jobs: number;
}

export const JobListings: React.FC = () => {
  const [jobListings, setJobListings] = useState<JobListingTableItem[]>([]);
  const [toDisplay, setToDisplay] = useState<JobListingTableItem[]>([]);
  const [industryToDisplay, setIndustryToDisplay] = useState<string[]>([]);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { getAccessTokenSilently } = useAuth0();

  //Refs for infinite scroll
  const bottomBoundaryRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const fetchScoops = async (): Promise<{ listings: JobListingTableItem[] | null; nextCursor: number | null }> => {
    const response = await getJobListingsPaginated(currentPage, await getAccessTokenSilently());
    if (response === null || response === undefined || response === "") {
      // When no more data is available, return null for visits and nextCursor
      return { listings: null, nextCursor: null };
    }
    setJobListings([...jobListings, ...response]);
    setCurrentPage(currentPage + 1);
    return { listings: response, nextCursor: currentPage + 1 };
  };

  const { fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ["JobListings"],
    queryFn: fetchScoops,
    getNextPageParam: (lastPage) => (lastPage.nextCursor !== null ? lastPage.nextCursor : undefined)
  });

  //Used for infinite scroll
  const observerCallback: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  //Used for infinite scroll
  useEffect(() => {
    observer.current = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    });
    if (bottomBoundaryRef.current) {
      observer.current.observe(bottomBoundaryRef.current);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [bottomBoundaryRef, observerCallback]);

  const onSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const search = event.target.value;
    if (!search) {
      setToDisplay(jobListings);
    } else {
      setToDisplay(jobListings.filter((j) => j.company.name.toLowerCase().includes(search.toLowerCase())));
    }
  };

  const columns = ["company", "job_title", "job_function", "date_posted", "job_linkedin_url", "industry", "location", "total_jobs"];

  const generateColumn = (header: string): Column<any> => {
    if (header === "company") {
      return {
        size: "minmax(180px, 1fr)",
        label: "Company",
        render: (v) => (
          <div className="text-[11px] leading-[13px] flex ml-[5px] items-center h-full" data-testid={`Name-${v.uuid}`}>
            <img
              src={v.company.logo_url}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = process.env.PUBLIC_URL + "/images/default_profile_image.png";
              }}
              className=" w-7 h-7 rounded-md"
            />
            <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline ml-[6px] Companies__name">{v.company.name}</span>
          </div>
        ),
        btn: (
          <div>
            <input
              className="w-[110px]  border-gray-100 rounded-md pl-1 py-1 mt-[1px] focus:outline-none text-gray border-[0.5px] ml-1"
              onChange={onSearch}
              placeholder="Search"></input>
          </div>
        )
      };
    }
    if (header === "date_posted") {
      return {
        size: "minmax(150px, 1fr)",
        label: "Date Posted",
        render: (v) => {
          return (
            <span className="overflow-hidden text-ellipsis whitespace-nowrap text-[11px] leading-[13px] ">
              {normalizeDateFE(v[header]).toISOString().split("T")[0]}
            </span>
          );
        }
      };
    }
    if (header === "job_linkedin_url") {
      return {
        size: "minmax(150px, 1fr)",
        label: "LinkedIn",
        render: (v) => {
          return (
            <a className="overflow-hidden text-ellipsis whitespace-nowrap hover:text-purple text-[11px] leading-[13px] " href={v[header]}>
              {v[header]}
            </a>
          );
        }
      };
    }
    return {
      size: "minmax(150px, 1fr)",
      label: header === "timeSpent" ? "Time Spent" : typeof header === "string" ? capitalizeAllWords(header.replaceAll("_", " ")) : header,
      render: (v) => {
        return (
          <span className="overflow-hidden text-ellipsis whitespace-nowrap text-[11px] leading-[13px] ">
            {header === "timeSpent"
              ? convertMillisToTime(v[header])
              : header === "visited"
              ? getTimeAgo(v[header])
              : typeof v[header] === "string"
              ? capitalizeAllWords(v[header].replaceAll("_", " "))
              : v[header]}
          </span>
        );
      }
    };
  };

  const getIndustryItems = () => {
    const industryLabels = jobListings
      .map((v) => v.industry)
      .filter((j) => j !== undefined)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((i) => capitalizeAllWords(i.replaceAll("_", " ")));
    return getDropDownItems(industryLabels, (i: string) => {
      industryToDisplay?.includes(i)
        ? setIndustryToDisplay(industryToDisplay.filter((p) => p !== i))
        : setIndustryToDisplay([...industryToDisplay, i]);
    });
  };

  useEffect(() => {
    if (!industryToDisplay.length && !toDate && !fromDate) {
      setToDisplay(jobListings);
      return;
    }
    let toDisplay: JobListingTableItem[] = [...jobListings];
    if (industryToDisplay.length) {
      toDisplay = toDisplay
        .filter((joblisting) => joblisting.industry)
        .filter((j) => industryToDisplay.includes(capitalizeAllWords(j.industry.replaceAll("_", " "))));
    }
    if (fromDate) {
      toDisplay = toDisplay.filter((j) => removeDateTimezone(new Date(j.date_posted.split("T")[0])) >= fromDate);
    }
    if (toDate) {
      toDisplay = toDisplay.filter((j) => removeDateTimezone(new Date(j.date_posted.split("T")[0])) <= toDate);
    }
    setToDisplay(toDisplay.flat());
  }, [industryToDisplay, jobListings, currentPage, fromDate, toDate]);

  function removeDateTimezone(date: Date) {
    const dateWithTimeZone = new Date(date);
    return new Date(dateWithTimeZone.getTime() - dateWithTimeZone.getTimezoneOffset() * 60 * 1000);
  }

  return (
    <div>
      <div className=" select-none">
        <div className="flex my-4 ml-10">
          <div>
            <CalendarFilterComponent
              onFromDateChange={setFromDate}
              onToDateChange={setToDate}
              className={"pl-2 h-[30px]"}
              eventName={"Posted"}></CalendarFilterComponent>
          </div>
          <div className="ml-4">
            <GenericDropdown
              items={getIndustryItems()}
              name={"industry-filter"}
              className=""
              placeholder={"Industry"}
              isMulti={true}></GenericDropdown>
          </div>
        </div>
      </div>
      <Card className="mx-10 ">
        {!toDisplay || toDisplay.length === 0 ? (
          <EmptyPageMessage icon={JobListingIcon} message={"Job listings will appear here"} className=" mt-[200px]"></EmptyPageMessage>
        ) : (
          <Table className="Table--JobListings" columns={columns.map((c) => generateColumn(c))} rows={toDisplay}></Table>
        )}
        <div ref={bottomBoundaryRef}></div>
      </Card>
    </div>
  );
};
