import axios from "axios";

export const API_URL = getUrl();

function getUrl() {
  return process.env.REACT_APP_BACKEND_HOST;
}
// process.env.REACT_APP_TARGET_ENV === "production"
//   ? "https://cozy-bear-production.up.railway.app"
//   : process.env.REACT_APP_TARGET_ENV === "staging"
//   ? "https://cozy-bear-staging.up.railway.app"
//   : "http://localhost/api";
export default axios.create({
  baseURL: API_URL
});
