import { Article, CompanyOfInterest, JobListing, Scoop } from "../../core/types";
import { ReactComponent as OpenInNewWindow } from "../../icons/open_in_new_window_icon.svg";

interface Props {
  articles: Scoop[];
  className?: string;
}

export default function RelatedScoopsComponent({ articles: scoops, className }: Props) {
  return (
    <div>
      <h2 className=" my-3 mx-3 font-semibold">{getScoopType(scoops)}</h2>
      <div className={`bg-white leading-[14px] font-medium rounded-[10px] ${className}`}>
        <ol>{scoops.map((a) => getScoopComponent(a, scoops.length))}</ol>
      </div>
    </div>
  );
}

function getScoopType(scoops: Scoop[]) {
  const scoop = scoops[0];
  switch (scoop.type) {
    case "article":
      return "Articles";
    case "joblisting":
      return "Job Listings";
    case "company_of_interest":
      return "Suggested Companies";
    default:
      throw new Error(`unexpected scoop encountered: ${scoop.type}`);
  }
}

function getSourceUrl(scoop: Scoop) {
  if (scoop.type === "article") {
    return (scoop as Article).source_url;
  } else if (scoop.type === "joblisting") {
    return (scoop as JobListing).url;
  } else if (scoop.type === "company_of_interest") {
    return (scoop as CompanyOfInterest).url;
  }
}

function getSourceName(scoop: Scoop) {
  if (scoop.type === "article") {
    return (scoop as Article).source_name;
  } else if (scoop.type === "joblisting") {
    return "LinkedIn";
  } else if (scoop.type === "company_of_interest") {
    return (scoop as CompanyOfInterest).company.name;
  }
}

function getScoopComponent(scoop: Scoop, articlesCount: number) {
  return (
    <div className="font-semibold text-base" key={getSourceUrl(scoop)}>
      {scoop.title}
      <ul className="flex flex-row py-2">
        <li className="flex text-xs font-normal text-[#D9D9D9]">
          <a target={"_blank"} rel="noreferrer" href={getSourceUrl(scoop)} className="text-purple flex mr-2">
            <OpenInNewWindow className="mt-0.5 w-3 h-3 mr-1"></OpenInNewWindow>
            {getSourceName(scoop)}
          </a>
          {"|"}
        </li>
        <li className=" text-[#6E6E6E] text-xs ml-2 font-normal">{scoop.date || scoop.created_at.split("T")[0]}</li>
      </ul>
      {articlesCount > 1 && <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>}
    </div>
  );
}
