import "./ContactDetails.scss";
import { User } from "../../core/types";
import { ReactComponent as OwnedByNone } from "../../icons/defaultprofile.svg";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Dropdown, { DropdownItem } from "../Dropdown/Dropdown";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { SetNewOwnerApiCall } from "../../core/api";
import { Button } from "../Button/Button";
import { isEmail } from "../../core/helpers";

interface Props {
  ownerEmail?: string;
  targetUuid: string;
  orgMembers: User[];
  setNewOwnerApiCall: SetNewOwnerApiCall;
  style?: "default" | "scoop" | "minimized";
  onChange?: (ownerUuid: string, targetUuid: string) => void;
  className?: string;
}

export default function OwnedByComponent({ ownerEmail, targetUuid, setNewOwnerApiCall, orgMembers, style = "default", onChange, className }: Props) {
  const UNASSIGNED_VALUE = "Unassigned";
  const [ownedBy, setOwnedBy] = useState<string>(ownerEmail || UNASSIGNED_VALUE);
  const [members, setMembers] = useState<DropdownItem[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setMembers(
      orgMembers.length
        ? toDropdownItems(
            orgMembers.filter(
              (u) => !["simon.samaletdin@test.com", "ojpoysti@gmail.com", "oliver@sharpreach.ai", "oliver@stegratest.se"].includes(u.email)
            )
          )
        : []
    );
  }, [orgMembers]);

  function toDropdownItems(users: User[]) {
    const ownerDropdownItems = users?.map((orgMember) => {
      return {
        onClick: async () => handleClick(orgMember.uuid, orgMember.email),
        label: orgMember.email
      } as DropdownItem;
    });
    ownerDropdownItems.push({
      onClick: async () => handleClick(UNASSIGNED_VALUE, UNASSIGNED_VALUE),
      label: UNASSIGNED_VALUE
    } as DropdownItem);

    return ownerDropdownItems;
  }

  async function handleClick(ownerUuid: string, ownerEmail: string) {
    if (!targetUuid) {
      return;
    }
    setNewOwnerApiCall(targetUuid, ownerUuid, await getAccessTokenSilently());
    setOwnedBy(ownerEmail !== UNASSIGNED_VALUE ? ownerEmail : UNASSIGNED_VALUE);
    if (onChange) {
      onChange(ownerUuid, targetUuid);
    }
  }

  return style === "default" && members.length ? (
    <div className={`flex align-middle items-center h-9 ${className}`}>
      <OwnedByNone className="w-6 h-6"></OwnedByNone>
      <strong className="ml-2 text-gray">Owner</strong>
      <div
        className={classNames(
          ` rounded-md pl-2 ml-2 flex ${ownedBy && ownedBy !== UNASSIGNED_VALUE ? "bg-green text-white" : "bg-gray-300 text-gray"} `
        )}>
        {ownedBy ? ownedBy : ownerEmail ? ownerEmail : UNASSIGNED_VALUE}
        <Dropdown
          className=" rounded-md "
          trigger={
            <div className=" h-full items-center flex">
              <Polygon className=" w-3 h-4 mx-1 " />
            </div>
          }
          items={members || []}></Dropdown>
      </div>
    </div>
  ) : style === "scoop" && members.length ? (
    <div>
      <Dropdown
        // className=" rounded-md "
        items={members}
        trigger={
          <Button className="flex font-bold" size="xs" styling={ownedBy !== UNASSIGNED_VALUE ? "outline-purple" : "outline"}>
            {ownedBy}
          </Button>
        }></Dropdown>
    </div>
  ) : style === "minimized" && members.length ? (
    <Dropdown
      className="h-6 rounded-md "
      trigger={<div className=""></div>}
      displayText={
        <div
          className={classNames(
            `  rounded-[0.2rem] px-2 h-6 flex overflow-hidden text-ellipsis whitespace-nowrap block-inline text-sm   w-full hover:bg-[#877cff2e] hover:rounded-sm items-center ${
              ownedBy && ownedBy !== UNASSIGNED_VALUE ? "bg-green text-white" : "bg-gray-300 text-gray"
            } `
          )}>
          {ownedBy && isEmail(ownedBy) ? ownedBy.split("@")[0] : UNASSIGNED_VALUE}
        </div>
      }
      items={members || []}></Dropdown>
  ) : (
    <></>
  );
}
