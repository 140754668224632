import './Loader.scss';

export default function Loader() {
  return (
    <div className='Loader fixed h-full w-full flex z-50'>
      <div className='Loader__item m-auto'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
