import { ReactComponent as Logo } from "../icons/logo.svg";
import { ReactComponent as Home } from "../icons/home.svg";
import { ReactComponent as Zap } from "../icons/zap.svg";
import { ReactComponent as Settings } from "../icons/settings.svg";
import { ReactComponent as WebsiteVisits } from "../icons/sidebar/website_visits_sidebar.svg";
import { ReactComponent as JobListings } from "../icons/sidebar/job_listings_sidebar.svg";
import { ReactComponent as Users } from "../icons/users.svg";
import { ReactComponent as Scoops } from "../icons/scoops_selected.svg";
import { ReactComponent as Company } from "../icons/briefcase.svg";
import "./Sidebar.scss";
import { RoutesEnum } from "../core/enums";
import { Link, NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { getCurrentUser } from "../core/api";
import { useQuery } from "@tanstack/react-query";

const LINKS = [
  {
    to: "/",
    label: "Home",
    icon: <Home />
  },
  {
    to: RoutesEnum.Scoops,
    label: "Scoops",
    icon: <Scoops />
  },
  {
    to: RoutesEnum.SavedCompanies,
    label: "Companies",
    icon: <Company />
  },
  {
    to: RoutesEnum.CompanyLookup,
    label: "Search",
    icon: <Zap />
  },
  {
    to: RoutesEnum.Contacts,
    label: "Contacts",
    icon: <Users />
  },
  {
    to: RoutesEnum.Settings,
    label: "Settings",
    icon: <Settings />
  }
];

export default function Sidebar() {
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const userDetails = useQuery(["User"], async () => getCurrentUser(await getAccessTokenSilently()));
  const { logout } = useAuth0();

  const handleClick = () => {
    logout({ returnTo: window.location.origin });
  };

  const additionalLinks =
    userDetails.data?.organization?.name === "Sharpreach" || process.env.REACT_APP_TARGET_ENV === "staging"
      ? [
          ...LINKS,
          {
            to: RoutesEnum.WebsiteVisits,
            label: "Website Visitors",
            icon: <WebsiteVisits />
          },
          {
            to: RoutesEnum.JobListings,
            label: "Job Listings",
            icon: <JobListings />
          }
        ]
      : LINKS;

  return (
    <div className="Sidebar flex-shrink-0" data-testid="Sidebar">
      <div className="sticky top-0 h-screen flex flex-col justify-between">
        <div>
          <Link to="/" className="h-[72px] flex">
            <Logo className="m-auto" />
          </Link>
          <div>
            {additionalLinks.map((l) => (
              <div key={l.label}>
                <NavLink
                  end
                  to={l.to}
                  className={classNames(
                    "Sidebar__link text-lg leading-[22px] font-medium no-underline self-center h-[42px] flex items-center text-gray hover:text-purple cursor-pointer",
                    {
                      active: location.pathname.startsWith(RoutesEnum.Contacts) && l.to === RoutesEnum.Contacts
                    }
                  )}
                  data-testid={`sidebar-link-${l.to.replace("/", "")}`}>
                  {l.icon}
                  {l.label}
                </NavLink>
              </div>
            ))}
          </div>
        </div>
        <div className="text-center pb-6">
          <a className="text-gray mb-[5px] hover:text-purple transition duration-300" data-testid="sidebar-email">
            {userDetails.data?.email}
          </a>
          <div>
            <button className="underline text-gray mb-3 hover:text-purple transition duration-300" onClick={handleClick}>
              Logout
            </button>
          </div>
          {/* <div className="inline-flex items-center">
            <div className="font-semibold text-[13px] leading-[16px] mr-[6px]" data-testid="sidebar-credits-amount">
              {userDetails.data?.total_credits + " credits"}
            </div>
            <Link
              to={RoutesEnum.Subscription}
              className="bg-green rounded-full py-[3px] px-[10px] text-[10px] leading-[13px] hover:shadow-md transition duration-300"
              data-testid="sidebar-upgrade-link">
              Upgrade my plan
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}
