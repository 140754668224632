import classNames from "classnames";
import Checkbox from "./Checkbox";

import "./Table.scss";

interface Props<T> {
  columns: Column<T>[];
  className?: string;
  rows: (T & { uuid?: string | number; domain?: string })[];
  select?: {
    selected: string | undefined;
    onSelect: (v: string) => void;
  };
  onClickRow?: (id: number | string) => () => void;
}

export interface Column<T> {
  render: (r: T, i: number) => JSX.Element;
  label: string;
  empty?: boolean;
  size?: string;
  btn?: JSX.Element;
}

export default function Table<T>({ columns, rows, className = "", select, onClickRow }: Props<T>) {
  const gridTemplateColumns = columns.map((c) => c.size || `minmax(64px, 1fr)`).join(" ");

  return (
    <div className={`Table rounded-[10px] ${className}`}>
      <div className="Table__container Scrollbar">
        <div
          className="Table__header grid"
          style={{
            gridTemplateColumns
          }}>
          {columns.map((c) => (
            <div key={c.label} className="Table__cell font-medium text-[14px] leading-[15px] items-center flex-row justify-start">
              {!c.empty && <div>{c.label}</div>}
              {select && (
                <div className="ml-[3px]">
                  <Checkbox
                    className="mr-10"
                    onChange={() => select.onSelect(c.label)}
                    checked={select.selected === c.label}
                    data-testid={`table-column-checkbox-${c.label}`}
                  />
                </div>
              )}
              {c.btn}
            </div>
          ))}
        </div>
        <div className=" Scrollbar">
          {rows.map((r, i) => (
            <div
              className={classNames("Table__row grid relative", {
                "cursor-pointer": onClickRow
                // "hover:bg-[#d9d9d966]": onClickRow
              })}
              key={r.uuid}
              style={{
                gridTemplateColumns
              }}
              // wonky logic to support companies as well as contacts
              onClick={onClickRow && r.domain ? onClickRow(r.domain) : onClickRow && r.uuid ? onClickRow(r.uuid) : undefined}>
              {columns.map((c) => (
                <div key={c.label} className="Table__cell">
                  {c.render(r, i)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
