import { MouseEventHandler, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export const useDropdown = () => {
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  const onClickTrigger: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setIsOpened(!isOpened);
  };

  useOnClickOutside(dropdownRef, () => {
    setIsOpened(false);
  });

  return {
    dropdownRef,
    menuRef,
    onClickTrigger,
    isOpened
  };
};
