import React, { createContext, useContext, useEffect } from "react";

interface ScrollPositionContextType {
  saveScrollPosition: (position: number) => void;
  getSavedScrollPosition: () => number | null;
}

const ScrollPositionContext = createContext<ScrollPositionContextType | undefined>(undefined);

export const useScrollPosition = (): ScrollPositionContextType => {
  const context = useContext(ScrollPositionContext);
  if (!context) {
    throw new Error("useScrollPosition must be used within a ScrollPositionProvider");
  }
  return context;
};

export const ScrollPositionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const saveScrollPosition = (position: number) => {
    sessionStorage.setItem("scrollPosition", position.toString());
  };

  const getSavedScrollPosition = (): number | null => {
    const savedPosition = sessionStorage.getItem("scrollPosition");
    return savedPosition ? parseInt(savedPosition, 10) : null;
  };

  useEffect(() => {
    const savedPosition = getSavedScrollPosition();
    if (savedPosition !== null) {
      window.scrollTo(0, savedPosition);
    }
  }, []);

  const value: ScrollPositionContextType = {
    saveScrollPosition,
    getSavedScrollPosition
  };

  return <ScrollPositionContext.Provider value={value}>{children}</ScrollPositionContext.Provider>;
};
