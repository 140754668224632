interface Props {
  className?: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  message: string;
}

export default function EmptyPageMessage({ className, icon: Icon, message }: Props) {
  return (
    <div className={` ${className} justify-center flex content-center relative `}>
      <div className="flex-col mt-3 absolute top-[30%] ">
        <div className="flex justify-center items-center">
          <div className="rounded-full bg-white w-16 h-16 justify-center flex shadow-lg ">
            <Icon className=" self-center  "></Icon>
          </div>
        </div>
        <div className=" text-center mt-2 w-72 font-medium text-gray">{message}</div>
      </div>
    </div>
  );
}
