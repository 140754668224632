import { Mention } from "../../core/types";
import { ReactComponent as LinkedinButton } from "../../icons/social/linkedin_icon.svg";
import { ReactComponent as EmailIcon } from "../../icons/social/email_found.svg";
import { ReactComponent as AddButton } from "../../icons/plus-circle.svg";
import { saveContactFromMention } from "../../core/api";
import { useAuth0 } from "@auth0/auth0-react";
import Tooltip from "../Tooltip";
import copy from "copy-to-clipboard";
import mixpanel from "mixpanel-browser";

interface Props {
  className?: string;
  mentions: Mention[];
}

export function MentionsList({ className, mentions }: Props) {
  const { getAccessTokenSilently } = useAuth0();

  const onClickSave = async (mention: Mention) => {
    await saveContactFromMention(mention, await getAccessTokenSilently());
  };

  return (
    <div className={`${className} text-black `}>
      Key Contacts
      <ol className="pt-2 text-sm flex">
        {mentions.map((p) => {
          return (
            <li
              className="relative overflow-visible h-full flex pr-1 mr-1 h-15 py-2  min-w-[300px] rounded-md  w-fit"
              key={p.linkedin_url}
              onClick={() => onClickSave(p)}>
              <Tooltip
                className="h-full absolute top-[-4px] left-[-4px] "
                content={"Contact saved"}
                onClick={async (e, isOpened, setIsOpened) => {
                  if (isOpened) {
                    return;
                  }
                  setIsOpened(true);
                  mixpanel.track("save_action", { entity: "contact", from: "key_contact" });
                  await onClickSave(p);
                }}>
                <AddButton className="absolute pr-1 top-0 w-8 rounded-full hover:cursor-pointer hover:fill-[#88e9b2]" fill={"#d9d9d9"}></AddButton>
              </Tooltip>
              <img
                src={`${p.photo_url}`}
                className=" rounded-full border-0 w-10 h-10 ml-2"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = process.env.PUBLIC_URL + "/images/default_profile_image.png";
                }}></img>
              <div className="ml-3">
                <p className=" cursor-default flex">
                  <strong className="max-w-[200px] truncate" title={`${p.title}`}>{`${p.first_name} ${p.last_name} - ${p.title}`}</strong>
                  <a target={"_blank"} href={p.linkedin_url} rel="noreferrer">
                    <LinkedinButton className="w-4 h-4 pt-1 ml-1"></LinkedinButton>
                  </a>
                </p>
                <div>
                  <div className="flex">
                    <EmailIcon className="w-[14px] h-[14px] mr-1 mt-1"></EmailIcon>
                    <Tooltip
                      onClick={(e, isOpened, setIsOpened) => {
                        e.stopPropagation();
                        setIsOpened(true);
                        copy(p.email);
                      }}
                      className="hover:text-purple hover:cursor-pointer flex "
                      content={"Email copied"}>
                      <p className="max-w-[190px] truncate" title={`Copy email`}>{`${p.email}`}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
}
