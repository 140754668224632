import Sidebar from "./components/Sidebar";
import { Routes, Route, RouteProps, Navigate, useLocation } from "react-router-dom";
import { ReactComponent as Save } from "./icons/scoops_saved.svg";
import { ReactComponent as Selected } from "./icons/scoops_selected.svg";
import Home from "./pages/Home";
import { RoutesEnum } from "./core/enums";
import BatchGenerate from "./pages/BatchGenerate/BatchGenerate";
import Contacts from "./pages/Contacts/Contacts";
import Settings from "./pages/Settings";
import Subscription from "./pages/Subscription";
import SingleContact from "./pages/SingleContact";
import Group from "./pages/Group";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./components/Loader";
import { useEffect, useState } from "react";
import NewsFlow from "./pages/NewsFlow/NewsFlow";
import CompanyInformationPage from "./pages/Company/CompanyInfo";
import CompanyLookup from "./pages/Company/CompanyLookup";
import SavedCompanies from "./pages/Company/SavedCompanies";
import { NewsSortContext } from "./hooks/NewsSortingContext";
import "./App.scss";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import WebsiteVisits from "./pages/WebsiteVisits/WebsiteVisits";
import { JobListings } from "./pages/JobListings/JobListings";

const routes: (RouteProps & {
  redirectTo?: string;
})[] = [
  {
    element: <Home />,
    path: "/"
  },
  {
    element: <BatchGenerate />,
    path: RoutesEnum.BatchGenerate
  },
  {
    element: <CompanyLookup />,
    path: RoutesEnum.CompanyLookup
  },
  {
    element: <Contacts />,
    path: RoutesEnum.Contacts
  },
  {
    element: <Settings />,
    path: RoutesEnum.Settings
  },
  {
    element: <Subscription />,
    path: RoutesEnum.Subscription
  },
  {
    element: <SingleContact />,
    path: RoutesEnum.SingleContact
  },
  {
    element: <Group />,
    path: RoutesEnum.Group
  },
  {
    element: <CompanyInformationPage />,
    path: RoutesEnum.CompanyInfo
  },
  {
    element: <NewsFlow />,
    path: RoutesEnum.Scoops
  },
  {
    element: <SavedCompanies />,
    path: RoutesEnum.SavedCompanies
  },
  {
    element: <WebsiteVisits />,
    path: RoutesEnum.WebsiteVisits
  },
  {
    element: <JobListings />,
    path: RoutesEnum.JobListings
  },
  {
    path: "*",
    redirectTo: "/"
  }
];

function App() {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();
  const [isError, setIsError] = useState(false);
  const [sort, setSort] = useState<"all" | "saved">("all");
  const location = useLocation();
  const isShowSorting = location?.pathname === RoutesEnum.Scoops;

  // Track page view on component mount and when location changes
  useEffect(() => {
    mixpanel.track("Page View", { page: location.pathname });
  }, [location.pathname]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      (async () => {
        try {
          setIsError(false);
          await loginWithRedirect();
        } catch (error) {
          setIsError(true);
        }
      })();
    }
  }, [isLoading, isAuthenticated]);

  if (isError) return <div>Something went wrong, please try again...</div>;
  if (isLoading || (!isLoading && !isAuthenticated)) return <Loader />;

  return (
    <div className="flex min-h-screen">
      {location?.pathname !== RoutesEnum.ResetPassword && <Sidebar />}
      <div className="grow flex flex-col">
        <div className="bg-white h-[68px] sticky top-0 z-10">{isShowSorting && displaySortSettings()}</div>
        <NewsSortContext.Provider value={{ sort: sort }}>
          <Routes>
            {routes.map(({ redirectTo, path, ...rest }) => {
              if (redirectTo) {
                return <Route path={path} element={<Navigate to={redirectTo} replace />} key={redirectTo} />;
              }
              return <Route {...rest} key={path} path={path} />;
            })}
          </Routes>
        </NewsSortContext.Provider>
      </div>
    </div>
  );

  //For scoops page, we wanted UI on page wrapper
  function displaySortSettings() {
    return (
      <div className="justify-center flex align-middle mt-[15px] ">
        <a
          className={classNames(`App__link  ${sort === "all" ? "bg-[#dedede] text-black" : "text-gray-100"}`, {
            active: sort === "all"
          })}
          onClick={() => setSort("all")}>
          <Selected></Selected>
          {"Scoops"}
        </a>
        <a
          className={classNames(`App__link  ${sort === "saved" ? "bg-[#dedede] text-black" : "text-gray-100"}`, {
            active: sort === "saved"
          })}
          onClick={() => setSort("saved")}>
          <Save className="w-5 h-5  mt-[4px]"></Save>
          {"Saved"}
        </a>
      </div>
    );
  }
}

export default App;
