import { useEffect, useRef, useState } from "react";
import { Article, CompanyDomain, User } from "../../core/types";
import { Button } from "../Button/Button";
import MiniLoader from "../MiniLoader";
import IconListButtons from "./IconListButtons";
import { isSubscribedToArticle, setArticleOwner } from "../../core/api";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as Checkmark } from "../../icons/checkmark_circle.svg";
import { MentionsList } from "./MentionsList";
import OwnedByComponent from "../ContactDetails/OwnedByComponent";
interface Props {
  data: Article;
  className?: string;
  orgMembers: User[];
  onOwnershipChange?: (ownerUuid: string, targetUuid: string) => void;
  onClickSave?: (uuid: string, isSaving: any, setIsSaving: any) => Promise<void>;
  onClickCompanyIcon?: (companyInfo: CompanyDomain) => void;
}

export default function ArticleCard({ onClickSave, data, className = "", orgMembers, onOwnershipChange, onClickCompanyIcon }: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [assignedUser] = useState<User | null>(data.owner || null);

  async function isSubscribed() {
    return isSubscribedToArticle(data.uuid, await getAccessTokenSilently()).then((res) => setIsSaved(res));
  }
  isSubscribed(); //is this right? refactor

  //move this to article list?
  const titleRef = useRef<HTMLParagraphElement>(null);
  const [maxWidth, setMaxWidth] = useState<string>("none");
  useEffect(() => {
    const calculateMaxWidth = () => {
      if (titleRef.current && titleRef.current.parentNode instanceof HTMLElement) {
        const availableWidth = titleRef.current.parentNode?.clientWidth || 0;
        setMaxWidth(`${availableWidth - 180}px`);
      }
    };

    calculateMaxWidth();
    window.addEventListener("resize", calculateMaxWidth);

    return () => {
      window.removeEventListener("resize", calculateMaxWidth);
    };
  }, []);

  return (
    <div
      className={`bg-white py-2 pl-4 pr-3 flex text-[15px] w-full leading-[18px] font-medium rounded-[10px] align-top ${className}`}
      data-testid={`article-${data.uuid}`}>
      <div className="pr-2 text-lg w-full">
        <div className="flex items-center pt-2 mt-0">
          <p ref={titleRef} className="truncate flex-shrink" style={{ maxWidth }}>
            {data.title}
          </p>
          <div className="flex ml-auto items-center">
            <div className="mr-2">
              <OwnedByComponent
                orgMembers={orgMembers}
                style="scoop"
                onChange={onOwnershipChange}
                className=""
                setNewOwnerApiCall={setArticleOwner}
                ownerEmail={assignedUser?.email}
                targetUuid={data.uuid}></OwnedByComponent>
            </div>
            <div>
              {onClickSave && (
                <Button className=" h-7 " styling="save" onClick={async () => await onClickSave(data.uuid, isSaving, setIsSaving)}>
                  {isSaving ? (
                    <MiniLoader></MiniLoader>
                  ) : isSaved! ? (
                    <>
                      <Checkmark className="w-3 h-3 my-2.5 mr-1"></Checkmark>
                      {"Saved"}
                    </>
                  ) : (
                    " + Save "
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="mx-[4px] items-start align-top pt-2 pr-2 text-sm text-gray">
          <div className="flex">
            <div className={` bg-indigo-400 mr-2 px-1 text-white rounded w-fit`}>{data.date}</div>
            {data.article_tag_names?.map((t) => {
              return (
                <div key={`${data.uuid}-${t}`} className={` bg-gray-100 mr-2 px-1 text-white rounded w-fit`}>
                  {t}
                </div>
              );
            })}
          </div>
          <div className="mt-4 mr-0 overflow-hidden text-ellipsis ">{data.body}</div>
          <div className="text-black mt-2">
            Source:{" "}
            <a href={data.source_url} target={"_blank"} rel="noreferrer" className="text-indigo-400">
              {data.source_name}
            </a>
          </div>
          <div className="text-black mt-2">Companies</div>
          <IconListButtons onClickButton={onClickCompanyIcon} data={data.companies} className="my-2"></IconListButtons>
          {data.mentions && data.mentions.length > 0 && <MentionsList mentions={data.mentions} className={"mb-2 mt-3"}></MentionsList>}
        </div>
      </div>
    </div>
  );
}
