import { Button } from "../components/Button/Button";
import Card from "../components/Card";
import PageWrapper from "../wrappers/PageWrapper";
import { ReactComponent as Tip } from "../icons/tip.svg";
import { formatNumber } from "../core/helpers";
import { useQueries } from "@tanstack/react-query";
import { getCurrentPlan, getStripeCheckoutRedirectLink, getStripePlans, upgradeSubscription } from "../core/api";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../components/Loader";
import { useState } from "react";

export interface StripePlan {
  id: string;
  product_id: string;
  amount: number;
  credits: number;
  name: string;
  description: string;
  checkout_url?: string;
  lifetime_access?: boolean;
}

const planDescription = [
  {
    product_id: "prod_NS2RnKaCTyAMmR",
    name: "Lifetime Access",
    description: "Sharp Reach Limited Offer.",
    points: ["200 credits per month", "1 credit = 1 icebreaker", "CSV upload", "Chrome extension", "Unlimited credit rollover"],
    payment_description: " one time payment"
  },
  {
    product_id: "prod_MFH49POG4fRkjb",
    name: "Essential",
    description: "Great for occasional prospecting.",
    points: ["300 credits per month", "1 credit = 1 icebreaker OR 1 email lookup", "CSV upload", "Chrome extension", "Unlimited credit rollover"],
    payment_description: "/month"
  },
  {
    product_id: "prod_MFH4srzMcdYq9r",
    name: "Standard",
    description: "For frequent outbound campaigns.",
    points: ["750 credits per month", "1 credit = 1 icebreaker OR 1 email lookup", "CSV upload", "Chrome extension", "Unlimited credit rollover"],
    payment_description: "/month"
  },
  {
    product_id: "prod_MFH4BA4XRBO76v",
    name: "Pro",
    description: "For teams looking to scale up their outbound efforts.",
    points: ["2000 credits per month", "1 credit = 1 icebreaker OR 1 email lookup", "CSV upload", "Chrome extension", "Unlimited credit rollover"],
    payment_description: "/month"
  }
];

export default function Subscription() {
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [currentPlanId, setCurrentPlanId] = useState("");
  const [plans, currentPlan] = useQueries({
    queries: [
      {
        queryKey: ["StripePlans"],
        queryFn: async () => await getStripePlans(await getAccessTokenSilently()),
        initialData: undefined
      },
      {
        queryKey: ["CurrentPlan"],
        queryFn: async () =>
          await getCurrentPlan(await getAccessTokenSilently()).then((res) => {
            setCurrentPlanId(res.id);
            return res;
          }),
        initialData: undefined
      }
    ]
  });

  const openCheckoutPortal = async (id: string) => {
    if (id) {
      await getStripeCheckoutRedirectLink(id, await getAccessTokenSilently()).then((checkoutLink) => {
        window.open(checkoutLink, "_blank");
      });
    }
  };

  const upgradePlan = async (id: string) => {
    setIsLoading(true);
    await upgradeSubscription(id, await getAccessTokenSilently()).then(() => {
      setCurrentPlanId(id);
      setIsLoading(false);
    });
  };

  return (
    <PageWrapper title="Subscription" data-testid="Subscription">
      <Card className="pb-14">
        {(isLoading || !currentPlan.data) && <Loader></Loader>}
        <div className="text-xl leading-[24px] pt-3 pb-3.5 pl-12 pr-4 border-b border-gray-100 mb-8 Title font-bold">Choose Your Plan</div>
        <div className="pl-9 pr-11">
          <div className="flex mx-[-24px]">
            {plans.data
              ?.sort((a, b) => a.amount - b.amount)
              .map((responseItem) => {
                const isCurrent = currentPlanId === responseItem.id || (currentPlan.data?.lifetime_access && responseItem.name === "Lifetime Access");
                const btnLabel = isCurrent ? "Current Plan" : "Select Plan";
                const hardcodedPlanItem = planDescription.find((p) => p.name == responseItem.name);
                return (
                  <div className="grow px-6" key={responseItem.name}>
                    <Card className="max-w-[338px] px-6 pb-6 pt-5 min-h-[498px] flex flex-col justify-between">
                      <>
                        <div>
                          <div className="text-center">
                            <div className="mb-[10px] text-[27px] font-bold leading-[33px] Title">{hardcodedPlanItem?.name}</div>
                            <div className="text-lg leading-[22px] mb-[13px]">{hardcodedPlanItem?.description}</div>
                            <Button
                              data-testid={hardcodedPlanItem?.name}
                              disabled={isCurrent}
                              className="mb-4 w-full block"
                              onClick={(e) => {
                                e.preventDefault;
                                currentPlan.data?.id ? upgradePlan(responseItem.id) : openCheckoutPortal(responseItem.id);
                              }}>
                              {btnLabel}
                            </Button>
                          </div>
                          {planDescription
                            .find((d) => d.name === responseItem.name)
                            ?.points.map((point) => (
                              <div key={responseItem.name + point} className="flex mb-3">
                                <Tip className="shrink-0" />
                                <div className="ml-2 pt-0.5 text-[15px] leading-[18px]">{point}</div>
                              </div>
                            ))}
                        </div>
                        <div
                          className="text-center text-[27px] font-bold leading-[33px] Title text-purple"
                          data-testid={`price-${responseItem.name}`}>
                          {formatNumber(responseItem.amount, {
                            currency: "usd",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0
                          })}
                          {planDescription.find((d) => d.name === responseItem.name)?.payment_description}
                        </div>
                      </>
                    </Card>
                  </div>
                );
              })}
          </div>
        </div>
      </Card>
    </PageWrapper>
  );
}
